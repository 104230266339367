import { action, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';

import { TPostSettings } from 'types/post';
import { DEFAULT_POST_SETTINGS } from 'consts';
import { TUser } from 'types/user';

class UploadPhotosStore {
  @observable isTaggingUser: boolean = false;
  @observable isTaggingProduct: boolean = false;
  @observable photos: Array<{
    id: number;
    file: File;
    users: Array<{
      position: { top: number; left: number };
      user: TUser;
    }>;
    products: Array<{
      position: { top: number; left: number };
      product: { id: string; name: string; url: string };
    }>;
    bottomLink?: { name: string; url: string };
  }> = [];
  @observable currentIndex: number = -1;
  @observable caption: string = '';
  @observable settings: TPostSettings = DEFAULT_POST_SETTINGS;

  constructor() {
    makeObservable(this);
  }

  @action setCaption(caption: string) {
    this.caption = caption;
  }

  @action changeSettings(settings: TPostSettings) {
    this.settings = settings;
  }

  @action choosePhotos(files: Array<{ id: number; file: File }>) {
    files.forEach((file) => {
      this.photos.push({
        ...file,
        users: [],
        products: [],
      });
    });
    this.currentIndex = 0;
  }

  @action clear() {
    this.photos = [];
    this.currentIndex = -1;
    this.caption = '';
    this.settings = DEFAULT_POST_SETTINGS;
  }

  @action swipe(index: number) {
    this.currentIndex = index;
  }

  @action tagUser(top: number, left: number, user: TUser) {
    const currentPhoto = this.photos[this.currentIndex];
    const newArr = [...this.photos];
    newArr[this.currentIndex] = {
      ...currentPhoto,
      users: [
        ...currentPhoto.users,
        {
          user,
          position: { top, left },
        },
      ],
    };
    this.photos = newArr;
  }

  @action untagUser(userId: number) {
    const tags: Array<any> = [];
    this.photos[this.currentIndex].users.forEach((tag) => {
      if (tag.user.id !== userId) {
        tags.push(tag);
      }
    });
    this.photos[this.currentIndex].users = tags;
  }

  @action toggleTaggingUser(tagging: boolean) {
    this.isTaggingUser = tagging;
  }

  @action tagProduct(
    top: number,
    left: number,
    product: { name: string; url: string }
  ) {
    const currentPhoto = this.photos[this.currentIndex];
    this.photos[this.currentIndex] = {
      ...currentPhoto,
      products: [
        ...currentPhoto.products,
        {
          product: {
            id: v4(),
            ...product,
          },
          position: {
            top,
            left,
          },
        },
      ],
    };
  }

  @action untagProduct(productId: string) {
    const tags: Array<any> = [];
    this.photos[this.currentIndex].products.forEach((tag) => {
      if (tag.product.id !== productId) {
        tags.push(tag);
      }
    });
    this.photos[this.currentIndex].products = tags;
  }

  @action toggleTaggingProduct(tagging: boolean) {
    this.isTaggingProduct = tagging;
  }

  @action setBottomLink(name: string, url: string) {
    const photos = this.photos.map((photo) => ({
      ...photo,
      bottomLink: { name, url },
    }));
    this.photos = photos;
  }
}

export default UploadPhotosStore;
