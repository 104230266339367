import { useState } from 'react';

import Header from 'components/header';
import Layout from 'components/layout';
import Switch from 'components/switch';
import { TPostSettings } from 'types/post';
import { API_URL } from 'config';
import { WEBVIEW_MESSAGE } from 'consts';

import {
  isOnWebview,
  registHandleNativeMessage,
  sendMessageToNative,
} from 'services/webviewService';

import styles from './styles.module.scss';

interface IProps {
  initialValue: TPostSettings;
  isTwitterLinked: boolean;
  onGoBack: (value: TPostSettings) => void;
  onLinkTwitterWindowOpen: (openedWindow: any) => void;
  handleSnsWebView: (channel: string, data: any) => void;
}

const PostAdvancedSettings = ({
  initialValue,
  isTwitterLinked,
  onGoBack,
  onLinkTwitterWindowOpen,
  handleSnsWebView
}: IProps) => {
  const origin = window.location.origin;
  const getUrl = (sns: 'line' | 'facebook' | 'twitter') => {
    return `${API_URL}login_sns/${sns}?authenticated_url=${origin}/feed&register_url=${origin}/registration`;
  };

  const [value, setValue] = useState<TPostSettings>(initialValue);

  const handleLinkSns = (
    provider: 'line' | 'facebook' | 'twitter'
  ) => {
    if (!isOnWebview()) {
      const openedWindow = window.open(getUrl('twitter'));
      onLinkTwitterWindowOpen(openedWindow);
      return;
    }

    let handleKey = '';

    if (provider === 'twitter') {
      handleKey = WEBVIEW_MESSAGE.LOGIN_SNS_TWITTER;
    }

    registHandleNativeMessage(handleKey, handleSnsWebView);
    sendMessageToNative(handleKey, null);
  };

  return (
    <Layout>
      <Header
        title="詳細設定"
        bordered
        back
        onBackClick={() => onGoBack(value)}
      />
      <div className={styles.container}>
        <div>
          <div className={styles.title}>「いいね！」数</div>
          <div className="d-flex flex-row justify-content-between my-2">
            <div className={styles.switchContent}>
              この投稿の「いいね！」数の表示選択
            </div>
            <Switch
              checked={value.show_number_like}
              onChange={(checked) =>
                setValue({ ...value, show_number_like: checked })
              }
            />
          </div>
          <div>この設定は、後で投稿の「…」メニューから変更できます。</div>
        </div>
        <div>
          <div className={styles.title}>コメント</div>
          <div className="d-flex flex-row justify-content-between my-2">
            <div className={styles.switchContent}>
              この投稿のコメントの可否選択
            </div>
            <Switch
              checked={value.show_comment}
              onChange={(checked) =>
                setValue({ ...value, show_comment: checked })
              }
            />
          </div>
          <div>投稿の「…」メニューからこの設定を変更できます。</div>
        </div>
        <div>
          <div className={styles.title}>Twitter同時投稿</div>
          <div className="d-flex flex-row justify-content-between my-2">
            <div className={styles.switchContent}>
              「Twitter同時投稿」をONにしていた場合、連携したTwitterアカウントでも投稿されます。
            </div>
            <Switch
              checked={value.share_twitter}
              onChange={(checked) =>
                setValue({ ...value, share_twitter: checked })
              }
            />
          </div>
          {!isTwitterLinked && (
            <button
              className="twitter"
              onClick={() => handleLinkSns('twitter')}
            >
              Twitterアカウントを連携する
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default PostAdvancedSettings;
