import {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
} from 'react-query';
import {
  addDoc,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  where,
} from 'firebase/firestore';
import moment from 'moment';

import { queryClient } from 'utils/query-client';
import { TMessage } from 'types/direct';
import {
  db,
  getConversationDocumentRef,
  getMessagesCollectionRef,
} from './firebase';

import { createMessage } from 'services/user';

let messageListUnsubscribe: any = null;

export const sendMessage: MutationFunction<any, any> = async ({
  conversationId,
  message,
}) => {
  const collectionRef = getMessagesCollectionRef(conversationId);

  // Add message to api server
  const messageData: any = {
    user_id: message.friendId
  };

  if (message.type == 'text') {
    messageData.type = 1;
    messageData.content = message.text;
    messageData.files = [];
  } else if (message.type == 'image') {
    messageData.type = 2;
    messageData.content = '';
    messageData.files = message.images.map((image: any) => image.id);
  }
  createMessage(messageData.user_id, messageData.type, messageData.content, messageData.files);

  return await addDoc(collectionRef, message);
};

export const getMessageList: QueryFunction<TMessage[]> = async (key) => {
  const conversationId = key.queryKey[1] as string;
  const myId = key.queryKey[2];

  const docRef = getConversationDocumentRef(conversationId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log('doc snap exist');
    const conversation = docSnap.data();
    const deletedBy = conversation.deletedBy || [];
    const deletedFrom = conversation.deletedFrom || [];
    const deletedIndex = deletedBy.indexOf(myId);
    let deletedTimestamp = 0;
    if (deletedIndex > -1) {
      deletedTimestamp = deletedFrom[deletedIndex];
    }

    let date = moment.now();
    if (key.pageParam) {
      date = key.pageParam;
    }

    const snapshot = await getDocs(
      query(
        getMessagesCollectionRef(conversationId),
        where('createdAt', '<', date),
        where('createdAt', '>', deletedTimestamp),
        orderBy('createdAt', 'desc'),
        limit(20)
      )
    );
    const messages: Array<TMessage> = [];
    snapshot.docs.forEach((doc) => {
      const data = doc.data() as TMessage;
      messages.push({ ...data, id: doc.id });
    });
    return messages;
  }
  return [];
};

export const hasMessageBefore = async (
  myId: number,
  conversation: any,
  date?: number
) => {
  if (conversation) {
    if (!date) return false;

    const deletedBy = conversation.deletedBy || [];
    const deletedFrom = conversation.deletedFrom || [];
    console.log('Deleted by from : ', deletedBy, deletedFrom);
    const deletedIndex = deletedBy.indexOf(myId);
    console.log('DElted index: ', deletedIndex);
    let deletedTimestamp = 0;
    if (deletedIndex > -1) {
      deletedTimestamp = deletedFrom[deletedIndex];
    }

    const data = await getDocs(
      query(
        getMessagesCollectionRef(conversation.id),
        orderBy('createdAt', 'desc'),
        where('createdAt', '<', date),
        where('createdAt', '>', deletedTimestamp),
        limit(1)
      )
    );

    return !!data.docs.length;
  }
  return false;
};

export const attachMessageListener = (key: QueryKey): (() => void) | null => {
  const conversationId: any = key[1];
  if (conversationId) {
    if (messageListUnsubscribe) messageListUnsubscribe();
    messageListUnsubscribe = onSnapshot(
      query(
        getMessagesCollectionRef(conversationId),
        orderBy('createdAt', 'desc'),
        where('createdAt', '>', moment.now())
      ),
      async (snap) => {
        const changes = snap.docChanges();
        for (const change of changes) {
          if (change.type === 'added') {
            const data = change.doc.data() as TMessage;
            const message = { ...data, id: change.doc.id };
            addMessageToQueryCache(key, message);
          }
        }
      }
    );
    return messageListUnsubscribe;
  }
  return null;
};

export const detachMessageListListener = () => {
  messageListUnsubscribe && messageListUnsubscribe();
};

const addMessageToQueryCache = (key: QueryKey, message: TMessage) => {
  const cache = queryClient.getQueryData<InfiniteData<TMessage[]>>(key);
  const messages = cache?.pages.flat() || [];
  messages.unshift(message);

  const newData: TMessage[][] = [];
  for (let i = 0; i < messages.length; i += 20) {
    const currentPage = messages.slice(i, i + 20);
    newData.push(currentPage);
  }

  queryClient.setQueryData<InfiniteData<TMessage[]>>(key, (data) => {
    return {
      pageParams: data?.pageParams || [],
      pages: newData,
    };
  });
};

export const updateLastMessage = async (
  conversationId: string,
  newMessageRef: DocumentReference
) => {
  await runTransaction(db, async (transaction) => {
    const conversationDocRef = getConversationDocumentRef(conversationId);
    const conversationDoc = await getDoc(conversationDocRef);
    const messageDoc = await getDoc(newMessageRef);
    if (conversationDoc.exists() && messageDoc.exists()) {
      const newUnreadCount = conversationDoc.data().unreadCount + 1;
      transaction.update(conversationDocRef, {
        unreadCount: newUnreadCount,
        lastSentTimestamp: messageDoc.data().createdAt,
        lastMessage: messageDoc.data(),
      });
    } else {
      throw new Error('Document does not exist');
    }
  });
};
