import { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import LoadingSpinner from 'components/inner-loading-spinner';
import useStores from 'hooks/useStores';
import SessionStore from 'stores/session';
import MyMessage from '../my-message';
import OtherMessage from '../other-message';
import { TConversation } from 'types/direct';

interface IProps {
  messages: Array<any>;
  loadMore: () => void;
  hasMore: boolean;
  conversation: TConversation;
  heightRef?: any;
}

const MessageList = ({
  messages,
  loadMore,
  hasMore,
  conversation,
  heightRef,
}: IProps) => {
  const sessionStore: SessionStore = useStores().sessionStore;

  const ref = useRef<any>(null);

  useEffect(() => {
    console.log('Messages change: ', messages);
    const element = document.getElementById('message-list');
    // if (element) {
    //   console.log(
    //     'Element scroll height: ',
    //     element.scrollHeight,
    //     element.scrollTop
    //   );
    //   // element.scrollTop = element.scrollHeight;
    //   // element.scrollTop = 0.5;
    //   // if (document.scrollingElement) {
    //   //   document.scrollingElement.scrollTop = element.scrollHeight;
    //   // }
    //   ref.current?.scrollIntoView({ behavior: 'smooth' });
    //   console.log('After scroll: ', element.scrollTop);
    // }
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div
      id="message-list"
      style={{
        flex: 1,
        height: `calc(${heightRef.style.height} - 7rem - 21px)`,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
        backgroundColor: '#eceff0',
      }}
    >
      <div ref={ref}></div>
      <InfiniteScroll
        scrollableTarget="message-list"
        dataLength={messages.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<LoadingSpinner visible />}
        inverse={true}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        {messages.map((message) =>
          message.senderId === sessionStore.profile.id ? (
            <MyMessage key={message.id} message={message} />
          ) : (
            <OtherMessage
              key={message.id}
              message={message}
              avatar={conversation.user.avatar?.small}
            />
          )
        )}
      </InfiniteScroll>
    </div>
  );
};

export default MessageList;
