import { useEffect, useRef } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMutation } from 'react-query';

import Layout from 'components/layout';
import Header from 'components/header';
import UserIcon from 'components/user-icon';
import ProductIcon from 'components/product-icon';
import UserTag from 'components/user-tag';
import ProductTag from 'components/product-tag';
import UploadPhotosStore from 'stores/upload-photos';
import useStores from 'hooks/useStores';
import { createPost } from 'services/post';
import { PostType } from 'consts';

import styles from './styles.module.scss';

const UploadPhotos = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();

  const uploadStore: UploadPhotosStore = useStores().uploadPhotosStore;

  const imageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (uploadStore.photos.length <= 0) {
      navigate(-1);
    }
  }, []);

  const mutation = useMutation(
    ({ type, content, files, settings }: any) =>
      createPost(type, content, files, settings),
    {
      onSuccess: () => {
        uploadStore.clear();
        navigate(-1);
      },
    }
  );

  const upload = () => {
    mutation.mutate({
      type: PostType.Image,
      content: uploadStore.caption,
      files: uploadStore.photos.map((photo) => ({
        id: photo.id,
        people_tags: photo.users.map((userTag) => ({
          id: userTag.user.id,
          position_x: userTag.position.left,
          position_y: userTag.position.top,
        })),
        product_tags: photo.products
          .map((productTag) => ({
            type: 1,
            title: productTag.product.name,
            link: productTag.product.url,
            position_x: productTag.position.left,
            position_y: productTag.position.top,
          }))
          .concat(
            photo.bottomLink &&
              photo.bottomLink.name.length > 0 &&
              photo.bottomLink.url.length > 0
              ? [
                  {
                    title: photo.bottomLink.name,
                    link: photo.bottomLink.url,
                    type: 2,
                    position_x: 0,
                    position_y: 0,
                  },
                ]
              : []
          ),
      })),
      settings: uploadStore.settings,
    });
  };
  return (
    <Layout>
      <Header
        title="新規投稿"
        back
        bordered
        onBackClick={() => {
          if (location.pathname.endsWith('/upload-photos')) {
            uploadStore.clear();
          }
          navigate(-1);
        }}
        rightComponent={
          <button
            className={styles.share}
            onClick={() => {
              location.pathname.endsWith('/upload-photos')
                ? upload()
                : navigate(-1);
            }}
          >
            {location.pathname.endsWith('/upload-photos') ? 'シェア' : '完了'}
          </button>
        }
      />
      <div ref={imageRef}>
        <Swiper
          initialSlide={uploadStore.currentIndex}
          onSlideChange={(swiper) => {
            uploadStore.swipe(swiper.activeIndex);
          }}
          style={{
            width: '100%',
            aspectRatio: '4/5',
          }}
        >
          {uploadStore.photos.map((photo, index) => (
            <SwiperSlide key={index}>
              <div className={styles.imgContainer}>
                <img
                  className="upload-image"
                  src={URL.createObjectURL(photo.file)}
                  alt=""
                />
                {uploadStore.isTaggingUser && (
                  <>
                    {photo.users.map((tag, index) => (
                      <UserTag
                        key={tag.user.nick_name}
                        nickname={tag.user.nick_name}
                        x={tag.position.left}
                        y={tag.position.top}
                        onClick={() => {}}
                        topOffset={
                          imageRef.current?.getBoundingClientRect().top
                        }
                      />
                    ))}
                    {photo.users.length > 0 && (
                      <UserIcon style={{ left: 10, bottom: 10 }} />
                    )}
                  </>
                )}
                {uploadStore.isTaggingProduct && (
                  <>
                    {photo.products.map((tag) => (
                      <ProductTag
                        key={tag.product.id}
                        id={tag.product.id}
                        name={tag.product.name}
                        x={tag.position.left}
                        y={tag.position.top}
                        onClick={() => {}}
                        onRemove={() =>
                          uploadStore.untagProduct(tag.product.id)
                        }
                        topOffset={
                          imageRef.current?.getBoundingClientRect().top
                        }
                        removable
                      />
                    ))}
                    {photo.products.length > 0 && (
                      <ProductIcon style={{ left: 10, bottom: 10 }} />
                    )}
                  </>
                )}
              </div>
            </SwiperSlide>
          ))}
          {uploadStore.photos.length > 1 && (
            <div className={styles.imgIndex}>
              {uploadStore.currentIndex + 1}/{uploadStore.photos.length}
            </div>
          )}
        </Swiper>
      </div>

      <Outlet />
    </Layout>
  );
});

export default UploadPhotos;
