import { LIMIT } from 'consts';
import { get, post } from 'services';

export const updateNotificationSetting = (status: boolean) => {
  return post('api/user/setting/update', { notification: status });
};

export const getNotifications = (page: number) => {
  return get('api/notification/search', {
    limit: LIMIT,
    page,
  });
};

export const getNotificationDetail = (id: number) => {
  return get('api/notification/detail', {
    id,
  });
};
