import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import PullToRefresh from 'react-simple-pull-to-refresh';

import Images from 'assets/images';
import Layout from 'components/layout';
import LoadingSpinner from 'components/inner-loading-spinner';
import { searchCreators } from 'services/user';
import { getNextPageParam, transformData } from 'utils/pagination';
import { TUser } from 'types/user';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';

import styles from './styles.module.scss';
import { isOnWebview } from 'services/webviewService';

const CreatorList = () => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;

  const [keyword, setKeyword] = useState('');

  const { data, fetchNextPage, hasNextPage, isLoading, isRefetching, refetch } =
    useInfiniteQuery(
      ['creators', keyword],
      ({ pageParam }) => searchCreators(pageParam, keyword),
      {
        select(data) {
          return transformData(data, 'users');
        },
        getNextPageParam,
      }
    );

  useEffect(() => {
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  return (
    <Layout bottomNavBar>
      <div className={styles.container}>
        <div className={classNames('d-flex flex-row', styles.topContainer)} style={{ paddingTop: uiStore.appStatusBarHeight + 10 }}>
          <div>
            <input
              placeholder="検索"
              value={keyword}
              onChange={(event) => setKeyword(event.target.value)}
            />
          </div>
          <button onClick={() => navigate('/discover')}>
            クリエイター一覧
          </button>
        </div>
        <PullToRefresh
          isPullable={isOnWebview()}
          pullingContent={""}
          onRefresh={refetch}>
          <InfiniteScroll
            className={styles.mainContainer}
            dataLength={data?.pages.flat().length || 0}
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            loader={null}
          >
            {data?.pages.flat().map((user: TUser) => (
              <div key={user.id} onClick={() => navigate(`/${user.id}`)}>
                <img
                  src={user.avatar?.medium || Images.imgDefaultAvatar}
                  alt=""
                />
                <div></div>
                <div>
                  <div>{user.name}</div>
                  <div>{user.nick_name}</div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </PullToRefresh>
        <LoadingSpinner visible={isRefetching} />
      </div>
    </Layout>
  );
};

export default CreatorList;
