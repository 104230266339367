import { LIMIT } from 'consts';
import { getegid } from 'process';
import { get, post } from './index';

export const getProductsByCategory = (page: number, category: number) => {
  let body: any = {
    limit: LIMIT,
    page,
  };
  if (!!category) {
    body.category = category;
  }
  return get('api/product/search', body);
};

export const getProductsByKeyword = (pageParam: number, keyword: string) => {
  return get('api/product/search', {
    limit: LIMIT,
    page: pageParam,
    keyword: keyword,
  });
};

export const getProductDetails = (id: number) => {
  return get('api/product/detail', {
    id,
  });
};

export const saveProduct = (id: number) => {
  return post('api/product/save', {
    product_id: id,
    type: 1,
  });
};

export const unsaveProduct = (id: number) => {
  return post('api/product/save', {
    product_id: id,
    type: 2,
  });
};

export const clickAffiliateLink = (token: string) => {
  return post('api/product/click', { token });
};

export const getFeaturedProducts = () => {
  return get('api/product/search', {
    limit: 3,
    featured: 1,
  });
};
