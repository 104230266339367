import axios from 'axios';

import { API_URL } from 'config';
import SessionStore from 'stores/session';
import stores from 'stores';
import { history } from 'utils/history';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const instance = axios.create({
  headers: defaultHeaders,
  withCredentials: true,
});

instance.defaults.baseURL = API_URL;

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const sessionStore: SessionStore = stores.sessionStore;
    if (!error.response) {
      // Offline
      sessionStore.setProfile(null);
      throw new Error('E_NoInternet');
    } else if (error.response.status === 419) {
      sessionStore.setProfile(null);
      console.log('Error: invalid token');
      history.replace('/login-methods');
    } else if (error.response.status === 401) {
      sessionStore.setProfile(null);
      throw new Error('E_LoginRequired');
    }
    throw error.response.data.message;
  }
);

export const get = async (path: string, params: any) => {
  const res = await instance.get(`/${path}`, { params });
  return res.data;
};

export const post = async (path: string, params: any) => {
  const res = await instance.post(`/${path}`, params);
  return res.data;
};

export const postFormData = async (path: string, formData: FormData) => {
  const res = await instance.post(`/${path}`, formData, {
    headers: { ...defaultHeaders, 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

export const put = async (path: string, params: any) => {
  const res = await instance.put(`/${path}`, params);
  return res.data;
};

export const del = async (path: string) => {
  const res = await instance.delete(`/${path}`);
  return res.data;
};
