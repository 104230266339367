import { useEffect, useRef, useState } from 'react';

import Images from 'assets/images';

interface IProps {
  id?: string;
  name: string;
  onClick: () => void;
  onRemove?: () => void;
  x: number;
  y: number;
  leftOffset?: number;
  rightOffset?: number;
  topOffset?: number;
  removable?: boolean;
}

const ProductTag = ({
  id,
  name,
  onClick,
  onRemove,
  x,
  y,
  leftOffset = 0,
  rightOffset = window.innerWidth,
  topOffset,
  removable = false,
}: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      if (topOffset && rect.top < topOffset) {
        setReverse(true);
      } else {
        if (rect.right > rightOffset) {
          ref.current.style.transform = `translateX(calc(${
            rightOffset - rect.right
          }px - 50% + 2.5px))`;
        } else if (rect.left < leftOffset) {
          ref.current.style.transform = `translateX(calc(${
            leftOffset - rect.left
          }px - 50% + 2.5px))`;
        } else {
          ref.current.style.transform = `translateX(calc(- 50% + 2.5px))`;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (reverse) {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.right > rightOffset) {
          ref.current.style.transform = `translateX(calc(${
            rightOffset - rect.right
          }px - 50% + 2.5px))`;
        } else if (rect.left < leftOffset) {
          ref.current.style.transform = `translateX(calc(${
            leftOffset - rect.left
          }px - 50% + 2.5px))`;
        } else {
          ref.current.style.transform = `translateX(calc(- 50% + 2.5px))`;
        }
      }
    }
  }, [reverse]);

  const toggleUntagButton = () => {
    const element = document.getElementById(`product-${id}`);
    if (element) {
      if (element.classList.contains('d-none')) {
        element.classList.remove('d-none');
      } else element.classList.add('d-none');
    }
  };

  return !reverse ? (
    <div
      style={{
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
        transform: 'translateY(-2.05rem)',
      }}
      onClick={onClick}
    >
      <div
        className="image-tag"
        ref={ref}
        style={{
          display: 'flex',
          alignItems: 'center',
          transform: 'translateX(calc(-50% + 2.5px))',
        }}
        onClick={() => toggleUntagButton()}
      >
        {name}
        {removable && (
          <img
            className="d-none"
            style={{
              marginLeft: '0.5rem',
              display: 'inline-block',
              width: '0.75rem',
              height: '0.75rem',
            }}
            id={`product-${id}`}
            src={Images.icClose}
            alt=""
            onClick={onRemove}
          />
        )}
      </div>
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderTop: '5px solid #000000b3',
        }}
      />
    </div>
  ) : (
    <div
      style={{
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
      }}
      onClick={onClick}
    >
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderBottom: '5px solid #000000b3',
        }}
      />
      <div
        className="image-tag"
        ref={ref}
        style={{
          display: 'flex',
          alignItems: 'center',
          transform: 'translateX(calc(-50% + 2.5px))',
        }}
        onClick={() => toggleUntagButton()}
      >
        {name}
        {removable && (
          <img
            className="d-none"
            style={{
              marginLeft: '0.5rem',
              display: 'inline-block',
              width: '0.75rem',
              height: '0.75rem',
            }}
            id={`product-${id}`}
            src={Images.icClose}
            alt=""
            onClick={onRemove}
          />
        )}
      </div>
    </div>
  );
};

export default ProductTag;
