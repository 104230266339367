import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useQuery } from 'react-query';

import Images from 'assets/images';
import { searchUsers } from 'services/user';
import UploadPhotosStore from 'stores/upload-photos';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import { TUser } from 'types/user';

import styles from './styles.module.scss';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onSelect: (user: any) => void;
}

const UserList = ({ visible, onCancel, onSelect }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;
  const photosStore: UploadPhotosStore = useStores().uploadPhotosStore;

  const [keyword, setKeyword] = useState('');

  const ids = photosStore.photos[photosStore.currentIndex].users.map(
    (user) => user.user.id
  );

  const { data, isLoading } = useQuery<{ users: Array<TUser> }, Error>(
    ['users-to-tag', keyword],
    () => searchUsers(1, keyword)
  );

  useEffect(() => {
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  return (
    <div
      className={classNames(styles.container, {
        'd-none': !visible,
      })}
      style={{paddingTop: uiStore.appStatusBarHeight }}
    >
      <div className={styles.searchBar}>
        <div>
          <img src={Images.icSearch} alt="" />
          <input
            placeholder="人物を検索"
            onChange={(event) => setKeyword(event.target.value)}
          />
        </div>
        <button onClick={onCancel}>キャンセル</button>
      </div>
      {data &&
        data.users.map((user) =>
          ids.includes(user.id) ? null : (
            <div
              key={user.id}
              className={styles.item}
              onClick={() => onSelect(user)}
            >
              <img src={user.avatar?.small || Images.imgDefaultAvatar} alt="" />
              <div className={styles.right}>
                <div className={styles.username}>{user.nick_name}</div>
                <div className={styles.name}>{user.name}</div>
              </div>
            </div>
          )
        )}
      {data && data.users.length === 0 && (
        <div className={styles.empty}>一致するユーザーがいません</div>
      )}
    </div>
  );
};

export default UserList;
