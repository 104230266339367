import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TProduct } from 'types/product';
import LoadingSpinner from 'components/inner-loading-spinner';

import styles from './styles.module.scss';

interface IProps {
  products: Array<TProduct>;
  loadMore: () => any;
  hasMore: boolean;
  isFetching: boolean;
}

const ProductList = ({ products, loadMore, hasMore, isFetching }: IProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {products.length === 0 && (
        <div className={styles.empty}>
          <p>まだ商品がありません</p>
        </div>
      )}
      {products.length > 0 && (
        <InfiniteScroll
          dataLength={products.length}
          next={loadMore}
          hasMore={hasMore}
          loader={null}
          className={styles.productGrid}
        >
          {products.map((product) => (
            <div
              key={product.id}
              onClick={() => navigate(`/product/${product.id}`)}
            >
              <img src={product.images[0].medium} alt="" loading="lazy" />
            </div>
          ))}
        </InfiniteScroll>
      )}
      <LoadingSpinner visible={isFetching} />
    </div>
  );
};

export default ProductList;
