import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useInfiniteQuery } from 'react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Pagination } from 'swiper';

import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import { getVideoPosts } from 'services/post';
import { getNextPageParam, transformData } from 'utils/pagination';
import BottomNavBar from 'components/bottom-nav-bar';
import VideoPostItem from 'components/video-post-item';
import Div100vh from 'components/div-100vh';
import { TPost } from 'types/post';

import { isOnWebview } from 'services/webviewService';

import styles from './styles.module.scss';

const Videos = observer(() => {
  const uiStore: UIStore = useStores().uiStore;

  useEffect(() => {
    return () => {
      uiStore.hideActionSheet();
      uiStore.hideAlertBox();
      uiStore.hideBottomSheet();
      uiStore.setIsReadingVideoCaption(false);
    };
  }, [uiStore]);

  const { data, fetchNextPage, hasNextPage, isLoading, refetch } = useInfiniteQuery(
    'videos',
    ({ pageParam }) => {
      uiStore.setIsReadingVideoCaption(true);
      return getVideoPosts(pageParam);
    },
    {
      select(data) {
        uiStore.setIsReadingVideoCaption(false);
        return transformData(data);
      },
      getNextPageParam,
    }
  );

  useEffect(() => {
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  return (

    <Div100vh className={styles.container}>
      <h2 className={styles.screenTitle} style={{ paddingTop: uiStore.appStatusBarHeight }}>movie</h2>

      <Swiper
        direction="vertical"
        modules={[Pagination]}
        resistanceRatio={0}
        style={{ width: '100%', flex: 1 }}
        onSlideChange={(swiper) => {
          if (swiper.isEnd && hasNextPage) fetchNextPage();
        }}
        allowTouchMove={!uiStore.isReadingVideoCaption}
        enabled={!uiStore.isReadingVideoCaption}
        allowSlideNext={!uiStore.isReadingVideoCaption}
        allowSlidePrev={!uiStore.isReadingVideoCaption}
      >
        {data?.pages.flat().map((post: TPost, index: number) => (
          <SwiperSlide key={post.id} style={{ height: '100%', width: '100%' }}>
            <PullToRefresh
              isPullable={isOnWebview() && index == 0}
              pullingContent={""}
              onRefresh={refetch}
            >
              <VideoPostItem
                key={post.id}
                post={post}
                invalidateQueryKey={['videos']}
              />
            </PullToRefresh>
          </SwiperSlide>
        ))}
      </Swiper>

      <BottomNavBar />
    </Div100vh>
  );
});

export default Videos;
