import { QueryClient } from 'react-query';
import stores from 'stores';

const uiStore = stores.uiStore;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      select(data: any) {
        return data.data;
      },
      onError: (error: any) => {
        uiStore.showAlertBox({
          title: 'エラー',
          content: error.toString(),
          buttons: [
            {
              title: '閉じる',
              onPress: () => uiStore.hideAlertBox(),
            },
          ],
        });
      },
    },
    mutations: {
      onError: (error: any) => {
        uiStore.showAlertBox({
          title: 'エラー',
          content: error.toString(),
          buttons: [
            {
              title: '閉じる',
              onPress: () => uiStore.hideAlertBox(),
            },
          ],
        });
      },
    },
  },
});
