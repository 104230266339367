import { action, makeObservable, observable } from 'mobx';

class SessionStore {
  @observable profile: any = null;

  constructor() {
    makeObservable(this);
  }

  @action setProfile(profile: any) {
    this.profile = profile;
  }
}

export default SessionStore;
