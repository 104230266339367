import Images from 'assets/images';
import useStores from 'hooks/useStores';
import SessionStore from 'stores/session';
import { TMessage } from 'types/direct';
import { formatMessageTime } from 'utils/time';

import styles from './styles.module.scss';

interface IProps {
  message: TMessage;
}

const MyMessage = ({ message }: IProps) => {
  const sessionStore: SessionStore = useStores().sessionStore;

  const { date, time } = formatMessageTime(message.createdAt);

  return (
    <div className="d-flex flex-row justify-content-end">
      <div className={styles.container}>
        <div className={styles.topContainer}>
          {message.type === 'text' && (
            <div className={styles.message}>{message.text}</div>
          )}
          {message.type === 'image' && (
            <div className={styles.imgContainer}>
              {message.images?.map((image) => (
                <img key={image.id} src={image.origin} alt="" />
              ))}
            </div>
          )}
          <img
            src={sessionStore.profile.avatar?.small || Images.imgDefaultAvatar}
            alt=""
            className={styles.avatar}
          />
        </div>
        <div className={styles.time}>{date ? `${date} ${time}` : time}</div>
      </div>
    </div>
  );
};

export default MyMessage;
