import { useRef } from 'react';
import TextArea from 'react-textarea-autosize';

import Images from 'assets/images';
import useDirect from 'hooks/useDirect';

import styles from './styles.module.scss';

interface IProps {
  conversationId: string;
  friendId: number;
}

const InputField = ({ conversationId, friendId }: IProps) => {
  const inputRef = useRef<any>(null);

  const { setPhotos, setMessage, sendTextMessage, message } =
    useDirect(conversationId, friendId);

  return (
    <div className={styles.container}>
      <img
        src={Images.icLibrary}
        alt=""
        onClick={() => inputRef.current.click()}
      />
      <div className={styles.right}>
        <TextArea
          minRows={1}
          maxRows={3}
          placeholder="コメントを入力..."
          value={message?.text || ''}
          onChange={(event) =>
            setMessage({ ...message, type: 'text', text: event.target.value })
          }
        />
        <div className={styles.send} onClick={sendTextMessage}>
          <img src={Images.icSend} alt="" />
        </div>
      </div>
      <input
        ref={inputRef}
        className="d-none"
        type="file"
        accept="image/*"
        multiple
        onChange={(event) => {
          if (event.target.files && event.target.files?.length > 0) {
            setPhotos(Array.from(event.target.files));
          }
        }}
      />
    </div>
  );
};

export default InputField;
