import { LIMIT, PAGE } from 'consts';
import { get, post } from 'services';
import { TPostSettings } from 'types/post';

export const createPost = (
  type: number,
  content: string,
  files: Array<any>,
  setting: TPostSettings
) => {
  return post('api/post/create', {
    type,
    content,
    files,
    setting,
  });
};

export const searchPosts = (page: number = PAGE, keyword: string = '') => {
  return get('api/post/search', {
    limit: LIMIT,
    page,
    keyword,
  });
};

export const searchRelevantPosts = (postId: number) => {
  return get('api/post/search', {
    limit: LIMIT,
    marker_id: postId,
  });
};

export const getImagePosts = (page: number = PAGE) => {
  return get('api/post/search', {
    limit: LIMIT,
    page,
    type: 1,
    following: 1,
  });
};

export const getVideoPosts = (page: number = PAGE, limit: number = LIMIT) => {
  return get('api/post/search', {
    limit: limit,
    page,
    type: 2,
  });
};

export const getVideoPostsHome = (page: number = PAGE) => {
  return get('api/post/search', {
    limit: LIMIT,
    page,
    type: 2,
    following: 1,
  });
};

export const getImagePostsByUser = (myId: number, page: number = PAGE) => {
  return get('api/post/search', {
    limit: LIMIT,
    page,
    type: 1,
    user_id: myId,
  });
};

export const getVideoPostsByUser = (myId: number, page: number = PAGE) => {
  return get('api/post/search', {
    limit: LIMIT,
    page,
    type: 2,
    user_id: myId,
  });
};

export const getTagPostsByUser = (myId: number, page: number = PAGE) => {
  return get('api/post/search', {
    limit: LIMIT,
    page,
    tagged: 1,
    user_id: myId,
  });
};

export const getPostDetail = (id: number) => {
  return get('api/post/detail', { post_id: id });
};

export const updatePost = (
  id: number,
  data: {
    content?: string;
    setting?: { show_number_like?: boolean; show_comment?: boolean };
    files?: Array<any>;
  }
) => {
  return post('api/post/update', {
    post_id: id,
    ...data,
  });
};

export const deletePost = (id: number) => {
  return post('api/post/delete', {
    post_id: id,
  });
};

export const like = (postId: number) => {
  return post('api/post/reaction/create', {
    post_id: postId,
    type: 1,
  });
};

export const unlike = (postId: number) => {
  return post('api/post/reaction/create', {
    post_id: postId,
    type: 2,
  });
};

export const getLikeList = (postId: number, page: number = PAGE) => {
  return get('api/post/reaction/search', {
    post_id: postId,
    page,
  });
};

export const getCommentList = (
  page: number,
  postId: number,
  parentId?: number
) => {
  const params = {
    limit: LIMIT,
    page,
    post_id: postId,
  };
  return get(
    'api/post/comment/search',
    parentId ? { ...params, comment_parent_id: parentId } : params
  );
};

export const comment = (
  postId: number,
  comment: string,
  users: Array<any> = [],
  parentCommentId?: number
) => {
  return post('api/post/comment/create', {
    post_id: postId,
    content: comment,
    tag_user_ids: users,
    comment_parent_id: parentCommentId,
  });
};

export const deleteComment = (commentId: number) => {
  return post('api/post/comment/delete', { comment_id: commentId });
};

export const getSavedPosts = (type: 1 | 2 | 3) => {
  return get('api/post/search', {
    type,
    saved_post: 1,
  });
};

export const save = (postId: number) => {
  return post('api/post/save', { post_id: postId, type: 1 });
};

export const unsave = (postId: number) => {
  return post('api/post/save', { post_id: postId, type: 2 });
};

export const hide = (postId: number) => {
  return post('api/post/hide', { post_id: postId, type: 1 });
};

export const unhide = (postId: number) => {
  return post('api/post/hide', { post_id: postId, type: 2 });
};

export const reportPost = (postId: number, reason: string) => {
  return post('api/post/report', {
    post_id: postId,
    reason,
  });
};

export const postAddComment = (
  postId: number,
  content: string,
  commentParentId: number,
  taggedUsers: string[]
) => {
  let payload: Partial<{
    post_id: number;
    content: string;
    comment_parent_id: number;
    tag_nick_names: string[];
  }> = {
    post_id: postId,
    content: content,
  };
  if (commentParentId !== 0) payload.comment_parent_id = commentParentId;
  if (taggedUsers.length > 0) payload.tag_nick_names = taggedUsers;

  return post('api/post/comment/create', payload);
};
