import { UseQueryResult } from 'react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FeaturedProduct } from 'types/product';
import styles from './styles.module.scss';
import './styles.scss';
import { Navigation } from 'swiper';
import FeaturedProductItem from '../featured-product';

interface IProps {
  queryData: UseQueryResult<any, unknown>;
}

const FeaturedProducts = (props: IProps) => {
  return (
    <>
      {props.queryData.isLoading && ''}
      {props.queryData.isError && ''}
      {props.queryData.data?.data.products.length > 0 && (
        <div className="featured-products-container">
          <Swiper
            className={styles.swiper}
            pagination={{ type: 'fraction' }}
            navigation={true}
            modules={[Navigation]}
            speed={300}
            slidesPerView={1}
          >
            {props.queryData.data?.data.products.map(
              (product: FeaturedProduct) => (
                <SwiperSlide className={styles.swiperSlide} key={product.id}>
                  <FeaturedProductItem product={product} />
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default FeaturedProducts;
