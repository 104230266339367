import { useNavigate } from 'react-router-dom';
import { UseMutationResult } from 'react-query';

import { Activity } from 'types/activity';
import icons from 'assets/images';

import styles from './styles.module.scss';
import { dateConverter } from 'utils/time';

import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';

import { createConversation, getConversation } from 'services/firebase';

interface IProps {
  activity: Activity;
  followMutation: UseMutationResult<any, unknown, number, unknown>;
  unfollowMutation: UseMutationResult<any, unknown, number, unknown>;
  currentUserId: number;
}

const ActivityItem = ({
  activity,
  followMutation,
  unfollowMutation,
  currentUserId,
}: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;

  const goToTargetHandler = (_event: any) => {
    switch (activity.type) {
      case 1:
      case 2:
      case 3:
      case 5:
      case 6:
        navigate(`/posts/${activity.target.object.id}`);
        break;
      case 4:
        navigate(`/${activity.user.id}`);
        break;
      case 7:
        goToChat(activity.user.id)
        break;
      case 8:
        navigate(`/settings/email/${activity.target.object.id}`)
        break;
      default:
        break;
    }
  };

  const goToChat = async (friendId: number) => {
    if (currentUserId) {
      try {
        uiStore.showLoading();
        const conversation = await getConversation(currentUserId, friendId);
        if (!conversation) {
          const conversationId = await createConversation(currentUserId, friendId);
          uiStore.hideLoading();
          navigate(`/direct/${conversationId}`);
        } else {
          uiStore.hideLoading();
          navigate(`/direct/${conversation.id}`);
        }
      } catch (err) {
        uiStore.hideLoading();
        console.log('Error get/create conversation: ', err);
      }
    }
  };

  const ActivityContent = () => {
    return (
      <div className={styles.activityContent} onClick={goToTargetHandler}>
        <p>
          <span>{activity.user.nick_name}</span>
          {/* tag comment*/}
          {activity.type === 1 &&
            'さんがコメントであなたをメンションしました。'}

          {/* like */}
          {activity.type === 2 && 'さんはあなたの投稿にいいねしました。'}

          {/* comment */}
          {activity.type === 3 && 'さんはあなたの投稿にコメントしました。'}

          {/* follow */}
          {activity.type === 4 && 'さんはあなたをフォローしました。'}

          {/* tag post */}
          {activity.type === 5 && 'さんが投稿にあなたをタグ付けしました。'}

          {/* my following create new post */}
          {activity.type === 6 && 'さんが投稿されました。'}

          {/* new message */}
          {activity.type === 7 && 'さんよりメッセージを受信しております。'}

          {/* new ENTAMA message */}
          {activity.type === 8 && '「ENTAMA」運営事務局よりお知らせ。'}

          {/* creator approved */}
          {activity.type === 9 && 'クリエイター申請が可決されました。'}

          {/* creator rejected */}
          {activity.type === 10 && 'クリエイター申請が否決されました。'}

          <span className={styles.date}>
            {dateConverter(activity.created_at)}
          </span>
        </p>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div
          className={styles.avatar}
          onClick={() => navigate(`/${activity.user.id}`)}
        >
          <img
            src={`${activity.user.avatar?.small || icons.imgDefaultAvatar}`}
            alt=""
          />
        </div>
        <ActivityContent />
      </div>
      {[1, 2, 3, 5, 6].includes(activity.type) ? (
        <div className={styles.target} onClick={goToTargetHandler}>
          <img
            src={
              activity.target.object.files[0]?.small || icons.imgDefaultAvatar
            }
            alt=""
            loading="lazy"
          />
        </div>
      ) : (activity.type == 4 ? (
        <button
          className={activity.user.is_followed ? 'followingBtn' : 'followBtn'}
          onClick={() =>
            activity.user.is_followed
              ? unfollowMutation.mutate(activity.user.id)
              : followMutation.mutate(activity.user.id)
          }
        >
          {activity.user.is_followed ? 'フォロー中' : 'フォローする'}
        </button>
      ) : <div></div>)}
    </div>
  );
};

export default ActivityItem;
