import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TComment } from 'types/post';
import Images from 'assets/images';

import styles from './styles.module.scss';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import { dateConverter } from 'utils/time';

interface IProps {
  reply: TComment;
  commentId: number;
  onReplyHandler: (username: string, commentParentId: number) => void;
  onAvatarOrUserNameClickHandler: (event: any, userId: number) => void;
  onDeleteCommentHandler: (commentId: number, commentParentId?: number) => void;
  currentUserNickName: string;
}

const ReplyItem = ({
  reply,
  commentId,
  onReplyHandler,
  onAvatarOrUserNameClickHandler,
  onDeleteCommentHandler,
  currentUserNickName,
}: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;

  const replyContent = reply.content.split(/(\s+)/);
  let isHolding = false;
  let timeout: any;

  const getIdByUsername = (section: string) => {
    const username = section.slice(1);
    const user = reply.tag_users.find((user) => user.nick_name === username);
    return user ? user.user_id : null;
  };

  const onTouchStartHandler = (event: any) => {
    event.stopPropagation();

    isHolding = true;
    timeout = setTimeout(() => {
      if (isHolding && reply.can_remove) {
        uiStore.showAlertBox({
          title: 'このコメントを削除しますか？',
          buttons: [
            {
              title: '削除', // delete
              isRed: true,
              onPress: () => onDeleteCommentHandler(reply.id, commentId),
            },
            {
              title: 'キャンセル',
            },
          ],
        });
      }
    }, 1000);
  };

  const onTouchEndHandler = (event: any) => {
    event.stopPropagation();
    isHolding = false;
    clearTimeout(timeout);
  };

  return (
    <div
      className={styles.container}
      onTouchStart={onTouchStartHandler}
      onTouchEnd={onTouchEndHandler}
    >
      <div className={styles.avatar}>
        <img
          src={reply.user.avatar?.small || Images.imgDefaultAvatar}
          alt=""
          loading="lazy"
          onClick={(event) => {
            event.stopPropagation();
            navigate(`/${reply.user.id}`);
          }}
        />
      </div>
      <div className={styles.content}>
        <span
          className={styles.userName}
          onClick={(event) => {
            event.stopPropagation();
            navigate(`/${reply.user.id}`);
          }}
        >
          {reply.user.nick_name}
        </span>
        <div className={styles.comment}>
          {replyContent.map((section: string, index) => {
            const userId = getIdByUsername(section);
            return (
              <React.Fragment key={index}>
                {/^@[a-zA-Z0-9_]+$/.test(section) ? (
                  <span
                    className={styles.tag}
                    onClick={(event) => {
                      event.stopPropagation();
                      userId && navigate(`/${userId}`);
                    }}
                  >
                    {section}
                  </span>
                ) : (
                  <span>{section}</span>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles.metaData}>
          <span aria-label="date">{dateConverter(reply.created_at)}</span>
          <span
            aria-label="reply"
            onClick={() => onReplyHandler(reply.user.nick_name, commentId)}
          >
            返信する
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReplyItem;
