import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import Layout from 'components/layout';
import Header from 'components/header';
import Images from 'assets/images';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';
import { changeAvatar, updateProfile } from 'services/user';
import { getMyProfile } from 'services/auth';
import { uploadFiles } from 'services/file';
import { EMAIL_REGEX, NICKNAME_REGEX, PHOTO_MAX_SIZE } from 'consts';

import styles from './styles.module.scss';

const AccountSettings = observer(() => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const { avatar, name, nick_name, biography, email, id } =
    sessionStore.profile;

  const inputRef = useRef<any>(null);

  const [newAvatar, setNewAvatar] = useState<File | null | 0>(null);
  const [newName, setNewName] = useState(name);
  const [newNickname, setNewNickname] = useState(nick_name);
  const [newEmail, setNewEmail] = useState(email);
  const [newBio, setNewBio] = useState(biography);
  const [hasChanged, setHasChanged] = useState(false);

  const profileQuery = useQuery(['get-my-profile'], getMyProfile, {
    enabled: false,
    onSuccess: (data) => {
      sessionStore.setProfile(data);
      setNewAvatar(null);
    },
  });

  const updateProfileMutation = useMutation(
    ({
      email,
      name,
      nickname,
      bio,
    }: {
      email: string;
      name: string;
      nickname: string;
      bio: string;
    }) => updateProfile(email, name, nickname, bio),
    {
      onSuccess: (data) => {
        profileQuery.refetch();
        if (newAvatar === null) navigate(`/${id}`);
      },
    }
  );

  const updateAvatarMutation = useMutation(
    (fileId: number) => changeAvatar(fileId),
    {
      onSuccess: () => {
        profileQuery.refetch();
        navigate(`/${id}`);
      },
    }
  );

  const uploadFileMutation = useMutation((file: File) => uploadFiles([file]), {
    onSuccess: (data) => {
      const fileId = data.data[0].id;
      updateAvatarMutation.mutate(fileId);
    },
  });

  useEffect(() => {
    if (profileQuery.isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [profileQuery.isLoading]);

  useEffect(() => {
    if (
      newName !== name ||
      newNickname !== nick_name ||
      newEmail !== email ||
      newBio !== biography
    ) {
      setHasChanged(true);
    } else setHasChanged(false);
  }, [
    newName,
    newNickname,
    newEmail,
    newBio,
    name,
    nick_name,
    email,
    biography,
  ]);

  const goToPrevPageHandler = () => {
    if (newAvatar != null || hasChanged) {
      uiStore.showAlertBox({
        title: '変更を破棄しますか？',
        content: 'このまま戻ると変更が破棄されます。',
        buttons: [
          {
            title: '変更を破棄',
            isRed: true,
            onPress: () => {
              navigate(-1);
            },
          },
          {
            title: '編集を続ける',
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const saveAccountSettings = (event: any) => {
    event.preventDefault();
    let error = null;

    if (hasChanged) {
      if (newEmail.trim().length === 0) {
        error = 'empty email';
        uiStore.showAlertBox({
          title: 'メールを入力してください。',
          buttons: [{ title: '閉じる' }],
        });
      } else if (!EMAIL_REGEX.test(newEmail)) {
        error = 'Invalid email';
        uiStore.showAlertBox({
          title: '無効なメールです。',
          buttons: [{ title: '閉じる' }],
        });
      } else if (newNickname.trim().length === 0) {
        error = 'empty nick_name';
        uiStore.showAlertBox({
          title: 'ユーザーIDを入力してください',
          buttons: [{ title: '閉じる' }],
        });
      } else if (!NICKNAME_REGEX.test(newNickname)) {
        error = 'Invalid nickname';
        uiStore.showAlertBox({
          title: '無効なユーザーIDです。',
          buttons: [{ title: '閉じる' }],
        });
      } else if (newName.trim().length > 30) {
        error = 'Invalid name';
        uiStore.showAlertBox({
          title: '30文字以内で入力してください。',
          buttons: [{ title: '閉じる' }],
        });
      } else if (newBio.length > 100) {
        error = 'invalid bio';
        uiStore.showAlertBox({
          title: '100文字以内で入力してください。',
          buttons: [{ title: '閉じる' }],
        });
      }
    }

    if (error == null) {
      if (hasChanged)
        updateProfileMutation.mutate({
          email: newEmail,
          name: newName,
          nickname: newNickname,
          bio: newBio,
        });
      if (newAvatar != null) {
        if (newAvatar === 0) updateAvatarMutation.mutate(0);
        else uploadFileMutation.mutate(newAvatar);
      }
    }
  };

  return (
    <Layout bottomNavBar>
      <Header
        title="アカウント"
        bordered
        back
        onBackClick={goToPrevPageHandler}
      />

      <form className={styles.container}>
        <div
          className={`d-flex flex-column align-items-center ${styles.avatarContainer}`}
        >
          <img
            src={
              newAvatar != null
                ? newAvatar === 0
                  ? Images.imgDefaultAvatar
                  : URL.createObjectURL(newAvatar)
                : avatar?.small || Images.imgDefaultAvatar
            }
            className={styles.avatar}
            alt=""
          />
          <button
            className={styles.changeAvatarBtn}
            onClick={(event) => {
              event.preventDefault();
              uiStore.showActionSheet([
                {
                  title: '現在の写真を削除',
                  onPress: () => setNewAvatar(0),
                },
                {
                  title: 'ライブラリから選択',
                  onPress: () => {
                    inputRef.current.click();
                  },
                },
              ]);
            }}
          >
            プロフィール写真を変更
          </button>
          <input
            className="d-none"
            type="file"
            ref={inputRef}
            accept="image/*"
            onChange={(event) => {
              if (event.target.files && event.target.files?.length > 0) {
                const file = event.target.files[0];
                if (file.size > PHOTO_MAX_SIZE) {
                  uiStore.showAlertBox({
                    title: '画像は10MBまでのものを登録してください。',
                    buttons: [
                      {
                        title: 'Close',
                      },
                    ],
                  });
                } else setNewAvatar(event.target.files[0]);
              }
            }}
          />
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.field}>
            <label htmlFor="email" className={styles.label}>
              メールアドレス
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                className={styles.value}
                name="email"
                required={true}
                value={newEmail}
                onChange={(event) => setNewEmail(event.target.value)}
              />
            </div>
          </div>

          <div className={styles.field}>
            <label htmlFor="nickname" className={styles.label}>
              ユーザーID
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                className={styles.value}
                name="nickname"
                required={true}
                value={newNickname}
                onChange={(event) => setNewNickname(event.target.value)}
              />
            </div>
          </div>

          <div className={styles.field}>
            <label htmlFor="name" className={styles.label}>
              名前
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                className={styles.value}
                name="name"
                value={newName}
                onChange={(event) => setNewName(event.target.value)}
              />
            </div>
          </div>

          <div className={styles.field}>
            <div
              className={styles.label}
              style={{
                alignSelf: 'flex-start',
              }}
            >
              自己紹介
            </div>
            <div
              className={styles.inputContainer}
              style={{
                padding: 0,
              }}
            >
              <textarea
                className={styles.value}
                value={newBio}
                onChange={(event) => setNewBio(event.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="w-100 text-center my-5">
          メールアドレスは
          <br />
          公開プロフィールでは表示されません。
        </div>

        <div className={classNames('d-flex flex-column', styles.btnContainer)}>
          <button
            type="submit"
            className={styles.btn}
            disabled={!hasChanged && newAvatar == null}
            onClick={saveAccountSettings}
          >
            編集完了
          </button>
        </div>
      </form>
    </Layout>
  );
});

export default AccountSettings;
