import Layout from 'components/layout';
import Header from 'components/header';

import '../styles.scss';

const AboutUs = () => {
  return (
    <Layout bottomNavBar>
      <Header title="運営会社" back bordered />
      <div className="static-page">
        社名 株式会社アデッソ
        <br />
        所在地 東京都渋谷区代々木4-5-7 参宮橋ビル302号
        <br />
        代表者 田代直人
        <br />
        連絡先 お問い合わせページよりお願いいたします
      </div>
    </Layout>
  );
};

export default AboutUs;
