import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { motion, useAnimation } from 'framer-motion';

import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';

const BottomSheet = observer(() => {
  const controls = useAnimation();
  const uiStore: UIStore = useStores().uiStore;

  // damping & stiffness will override duration.
  const variants = {
    visible: {
      y: '0%',
      transition: {
        delay: 0.1,
        duration: 0.8,
        bounce: 0.25,
        ease: 'easeInOut',
        type: 'spring',
        // damping: 50,
        // stiffness: 600,
      },
    },
    hidden: {
      y: '100%',
      transition: {
        duration: 0.7,
        bounce: 0.25,
        ease: 'easeInOut',
        type: 'spring',
        // damping: 50,
        // stiffness: 600,
      },
    },
  };

  const closeModalHandler = (_event: any) => {
    controls.start('hidden');
    setTimeout(() => {
      uiStore.hideBottomSheet();
    }, 300);
  };

  const motionClickHandler = (event: any) => {
    // prevent event bubbling.
    event.stopPropagation();
  };

  const contentClickHandler = (event: any) => {
    event.stopPropagation();
  };

  const onDragEnd = (_event: any, info: any) => {
    const shouldClose =
      info.velocity.y > 100 || (info.velocity.y >= 0 && info.point.y > 300);
    if (shouldClose) {
      controls.start('hidden');
      setTimeout(() => {
        uiStore.hideBottomSheet();
      }, 300);
    } else {
      controls.start('visible');
    }
  };

  useEffect(() => {
    controls.start('visible');
  });

  return (
    <>
      {uiStore.bottomSheet.visible && (
        <div className={styles.modal} onClick={closeModalHandler}>
          <motion.div
            id="motion-div"
            drag="y"
            dragConstraints={{ top: 0 }}
            dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
            variants={variants}
            initial={'hidden'}
            className={styles.container}
            onDragEnd={onDragEnd}
            animate={controls}
            dragElastic={0.2}
            onClick={motionClickHandler}
          >
            <div className={styles.close}></div>
            <div className={styles.content} onClick={contentClickHandler}>
              {uiStore.bottomSheet.content}
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
});

export default BottomSheet;
