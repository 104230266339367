import { useEffect, useRef } from 'react';
import { useLocation, Outlet, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMutation, useQuery } from 'react-query';

import Layout from 'components/layout';
import Header from 'components/header';
import UserIcon from 'components/user-icon';
import ProductIcon from 'components/product-icon';
import useStores from 'hooks/useStores';
import { getPostDetail, updatePost } from 'services/post';

import styles from './styles.module.scss';
import EditPostStore from 'stores/edit-post';
import { PostDetail, TImage } from 'types/file';
import UserTag from 'components/user-tag';
import ProductTag from 'components/product-tag';

const EditPost = observer(() => {
  const { id: postId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const editPostStore: EditPostStore = useStores().editPostStore;

  const postDetail = useQuery<PostDetail>(
    ['post-detail', postId],
    () => getPostDetail(+postId!),
    {
      enabled: false,
      onSuccess: (data) => {
        console.log(data);
        editPostStore.setContent(data.content);
        editPostStore.setFiles(data.files);
      },
      onError: (_err) => {
        navigate('/feed');
      },
    }
  );

  const updatePostMutation = useMutation(
    (payload: { id: number; data: any }) =>
      updatePost(payload.id, payload.data),
    {
      onSuccess: (_data) => {
        navigate(-1);
        editPostStore.clearStore();
      },
    }
  );

  const update = () => {
    const filesAfterEdit: any[] = [];

    editPostStore.files.forEach((file) => {
      // clean up user tags
      const newPeopleTags = file.people_tags.map((tag) => ({
        id: tag.id,
        position_x: tag.position_x,
        position_y: tag.position_y,
      }));

      // add bottom link to each file'product tag list
      const newProductTags = JSON.parse(JSON.stringify(file.product_tags));
      const cloneBottomLink = JSON.parse(
        JSON.stringify(editPostStore.bottomLink)
      );
      if(cloneBottomLink.title && cloneBottomLink.title.length > 0) {
        newProductTags.push(cloneBottomLink);
      }

      filesAfterEdit.push({
        id: file.id,
        product_tags: newProductTags,
        people_tags: newPeopleTags,
      });
    });

    updatePostMutation.mutate({
      id: +postId!,
      data: {
        content: editPostStore.content,
        files: filesAfterEdit,
      },
    });
  };

  // getBoundingClientRect() from this
  const imageContainerRef = useRef<HTMLDivElement | null>(null);

  // whenever the user switches between images
  // delete the current position
  const onSwiperChange = (swiper: any) => {
    editPostStore.setCurrentPosition();
    setTimeout(() => {
      editPostStore.setActiveIndex(swiper.activeIndex);
    }, 300);
  };

  const rightButton = (
    <button
      className={styles.share}
      onClick={() => {
        location.pathname.endsWith('/edit') ? update() : navigate(-1);
      }}
    >
      {location.pathname.endsWith('/edit') ? 'シェア' : '完了'}
    </button>
  );

  const showUserTags = (file: TImage, index: number) => {
    return (
      <>
        {editPostStore.isReTaggingUser &&
          editPostStore.activeImageIndex === index && (
            <div onClick={(event: any) => event.stopPropagation()}>
              {file.people_tags.map((tag) => (
                <UserTag
                  key={tag.nick_name}
                  nickname={tag.nick_name}
                  x={tag.position_x}
                  y={tag.position_y}
                  onClick={() => {}}
                  topOffset={
                    imageContainerRef.current?.getBoundingClientRect().top
                  }
                />
              ))}
              {file.people_tags.length && (
                <UserIcon style={{ left: 10, bottom: 10 }} />
              )}
            </div>
          )}
      </>
    );
  };

  const showProductTags = (file: TImage, index: number) => {
    return (
      <>
        {editPostStore.isReTaggingProduct &&
          editPostStore.activeImageIndex === index && (
            <>
              {file.product_tags.map(
                (tag) =>
                  tag.type !== 2 && (
                    <div onClick={(event: any) => event.stopPropagation()}>
                      <ProductTag
                        id={tag.position_x + tag.position_y + tag.title}
                        key={tag.position_x + tag.position_y + tag.title}
                        name={tag.title}
                        x={tag.position_x}
                        y={tag.position_y}
                        onClick={() => {}}
                        topOffset={
                          imageContainerRef.current?.getBoundingClientRect().top
                        }
                        removable
                        onRemove={() => {
                          editPostStore.unTagProduct(
                            tag.position_x,
                            tag.position_y
                          );
                        }}
                      />
                    </div>
                  )
              )}
              {file.product_tags.length && (
                <ProductIcon style={{ left: 10, bottom: 10 }} />
              )}
            </>
          )}
      </>
    );
  };

  const getClickPosition = (event: any) => {
    const containerWidth =
      imageContainerRef.current?.getBoundingClientRect().width!;
    const containerHeight =
      imageContainerRef.current?.getBoundingClientRect().height!;
    const x = (event.nativeEvent.offsetX / containerWidth) * 100;
    const y = (event.nativeEvent.offsetY / containerHeight) * 100;

    if (editPostStore.isReTaggingUser || editPostStore.isReTaggingProduct) {
      editPostStore.setCurrentPosition(x, y);
    }
  };

  const onBackClick = () => {
    if (location.pathname.endsWith('/edit')) editPostStore.clearStore();
    navigate(-1);
  };

  // while editing, the use might leave the current route structure
  // to go to /add-bottom-link which is a totally different route.
  // So we can't clean up store when this component unmounts which will trigger
  // another API call & the store will be reset. The 2 scenarios that we clean up
  // the store are if the user hits the back button while on the main /edit route &
  // after updating successfully.
  useEffect(() => {
    if (!editPostStore.isEditing) {
      editPostStore.setIsEditing(true);
      postDetail.refetch();
    }

    const rect = imageContainerRef.current?.getBoundingClientRect();
    editPostStore.setImageContainerRect(rect!);
  }, []);

  return (
    <Layout>
      <Header
        title="新規投稿"
        back
        bordered
        onBackClick={onBackClick}
        rightComponent={rightButton}
      />

      <div
        ref={imageContainerRef}
        onClick={(event: any) => getClickPosition(event)}
      >
        <Swiper
          onSlideChange={(swiper) => onSwiperChange(swiper)}
          // this will fix a bug where all tags appear on slide 1
          // when coming back from add-bottom-link
          initialSlide={editPostStore.activeImageIndex}
          className={styles.swiperContainer}
        >
          {editPostStore.files.map((file, index) => (
            <SwiperSlide key={index}>
              <div className={styles.imgContainer}>
                <img src={file.origin} alt="" />
                {showUserTags(file, index)}
                {showProductTags(file, index)}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <Outlet />
    </Layout>
  );
});

export default EditPost;
