import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

interface IProps {
  title: { img: string; text: string };
  images: string[];
  content: string;
  start?: boolean;
}

const Guide = (props: IProps) => {
  const navigate = useNavigate();

  let transform;
  if (window.innerWidth <= 320) {
    if (props.start) {
      transform = 'translate(-1.5rem,-100%)';
    } else {
      transform = 'translate(-1.5rem, -50%)';
    }
  } else {
    transform = 'translate(-1.5rem, 0)';
  }

  const descriptionStyle = {
    margin: '1em 1.5em',
    transform: transform,
    padding: `0 1em`,
    width: '80%',
  };

  return (
    <div
      className={styles.container}
      style={{
        margin: `${window.innerWidth <= 320 ? '0 0 0 0' : '2em 0 0 0'}`,
      }}
    >
      <div className={styles.title}>
        <img src={props.title.img} alt="" />
        <p>{props.title.text}</p>
      </div>

      <div className={styles.imageContainer}>
        {props.images.map((image, index) => {
          return <img src={image} alt="" key={index} loading="lazy" />;
        })}
      </div>
      <p className={styles.description} style={descriptionStyle}>
        {props.content}
      </p>
      {props.start ? (
        <button onClick={() => navigate('/start')}>START</button>
      ) : (
        ''
      )}
    </div>
  );
};

export default Guide;
