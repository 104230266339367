import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import TextInputField from 'components/text-input-field';
import AuthScreenTemplate from 'modules/auth/components/template';
import { requestPasswordReset } from 'services/auth';
import { EMAIL_REGEX } from 'consts';

import styles from './styles.module.scss';

const ResetPasswordEnterMail = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(true);

  const requestMutation = useMutation(() => requestPasswordReset(email), {
    onSuccess: (data) => {
      navigate('/reset-password/code', {
        state: { id: data.data.id, email },
      });
    },
  });

  useEffect(() => {
    const isValid = EMAIL_REGEX.test(email);
    setValid(isValid);
  }, [email]);

  return (
    <AuthScreenTemplate
      title="パスワード再設定"
      subtitle="登録済みのメールアドレスを入力してください"
    >
      <TextInputField
        className="mt-5"
        label="メールアドレス"
        required
        placeholder="メールアドレスを入力してください"
        value={email}
        onChange={(text) => setEmail(text)}
        side="left"
      />
      <div className="d-flex justify-content-center align-items-center">
        <button
          className={styles.submit}
          disabled={email.trim().length === 0 || !valid}
          onClick={() => requestMutation.mutate()}
        >
          認証コードを送信
        </button>
      </div>
    </AuthScreenTemplate>
  );
};

export default ResetPasswordEnterMail;
