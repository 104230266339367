export const ENV = process.env.REACT_APP_ENV || 'local';

export const API_URL =
  process.env.REACT_APP_API_URL || 'http://api.affiliate-dev.ga/';

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || '';
export const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '';
export const FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID || '';
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '';
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '';
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID || '';
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '';

export const AFFILIATE_URL =
  process.env.REACT_AFFILIATE_URL || 'https://afad.entama.link/';
