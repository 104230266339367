import { action, makeObservable, observable } from 'mobx';
import { TImage } from 'types/file';

interface ILink {
  title: string;
  link: string;
  type: number;
  position_x: number;
  position_y: number;
}

interface IRect {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  x: number;
  y: number;
}

class EditPostStore {
  constructor() {
    makeObservable(this);
  }

  @observable isEditing: boolean = false;
  @observable content: string = '';
  @observable bottomLink: ILink = {
    title: '',
    link: '',
    type: 2,
    position_x: 0,
    position_y: 0,
  };
  @observable files: Array<TImage> = [];
  @observable activeImageIndex: number = 0;
  @observable isReTaggingUser: boolean = false;
  @observable isReTaggingProduct: boolean = false;

  @action
  setContent(content: string) {
    this.content = content;
  }
  @observable imageContainerRect: Partial<IRect> = {};
  @observable currentPosition: { x: number; y: number } | null = null;

  // this is the list of the current users be tagging in the post
  // we need this in order to untag the user tag from images
  // because the UserTag component doesn't have a 'removable' property
  // link ProductTag component. We only work with this list when editing.
  @observable
  userTagList: Array<{
    index: number;
    list: Array<{
      name: string;
      nick_name: string;
      avatar: any;
      id: number;
    }>;
  }> = [];

  @action
  clearStore() {
    this.activeImageIndex = 0;
    this.isReTaggingUser = false;
    this.isReTaggingProduct = false;
    this.content = '';
    this.files = [];
    this.bottomLink = {
      title: '',
      link: '',
      type: 2,
      position_x: 0,
      position_y: 0,
    };
    this.currentPosition = null;
    this.imageContainerRect = {};
    this.userTagList = [];
    this.isEditing = false;
  }

  // also set bottomLink & userTagList
  @action
  setFiles(files: Array<TImage>) {
    this.files = files;

    const hasBottomLink = files[0].product_tags.some((tag) => {
      if (tag.type === 2) {
        this.bottomLink = tag;
        return true;
      }
      return false;
    });

    // each file has a product_tags property in it
    // each product_tags property has a bottomLink tag at the end for some reasons
    // so we need to remove all of them.
    // however, we will add bottomLink back to the end of each product_tags
    // when we are done.
    if (hasBottomLink) {
      this.files.forEach((file) => {
        file.product_tags.pop();
      });
    }

    files.forEach((file, index) => {
      this.userTagList.push({ index, list: file.people_tags });
    });
  }

  @action
  setActiveIndex(index: number) {
    this.activeImageIndex = index;
  }

  @action
  setImageContainerRect(rect: IRect) {
    this.imageContainerRect = rect;
  }

  @action
  setIsTaggingUser(state: boolean) {
    this.isReTaggingUser = state;
  }

  @action
  setIsReTaggingProduct(state: boolean) {
    this.isReTaggingProduct = state;
  }

  @action tagUser(
    x: number,
    y: number,
    id: number,
    name: string,
    nick_name: string
  ) {
    this.files[this.activeImageIndex].people_tags.push({
      position_x: x,
      position_y: y,
      id,
      name,
      nick_name,
    });
    this.setCurrentPosition();
  }

  @action
  setCurrentPosition(x?: number, y?: number) {
    if (!!x && !!y) this.currentPosition = { x, y };
    else this.currentPosition = null;
  }

  @action setProductTag(title: string, link: string) {
    this.files[this.activeImageIndex].product_tags.push({
      title,
      link,
      type: 1,
      position_x: this.currentPosition?.x,
      position_y: this.currentPosition?.y,
    });
  }

  @action unTagUser(userId: number) {
    let currentList = this.userTagList[this.activeImageIndex].list;
    currentList = currentList.filter((tag) => tag.id !== userId);
    this.userTagList[this.activeImageIndex].list = currentList;

    let currentFileUserTagList = this.files[this.activeImageIndex].people_tags;
    currentFileUserTagList = currentFileUserTagList.filter(
      (tag) => tag.id !== userId
    );
    this.files[this.activeImageIndex].people_tags = currentFileUserTagList;
  }

  @action unTagProduct(x: number, y: number) {
    let tagList = this.files[this.activeImageIndex].product_tags;
    tagList = tagList.filter(
      (tag) => tag.position_x !== x && tag.position_y !== y
    );
    this.files[this.activeImageIndex].product_tags = tagList;
  }

  @action
  addUserToTagList(user: {
    avatar: any;
    id: number;
    name: string;
    nick_name: string;
  }) {
    this.userTagList[this.activeImageIndex].list.push({
      name: user.name,
      nick_name: user.nick_name,
      id: user.id,
      avatar: user.avatar,
    });

    this.tagUser(
      this.currentPosition?.x!,
      this.currentPosition?.y!,
      user.id,
      user.name,
      user.nick_name
    );
  }

  @action
  setBottomLink(title: string, link: string) {
    this.bottomLink = { ...this.bottomLink, title, link };
  }

  @action
  setIsEditing(state: boolean) {
    this.isEditing = state;
  }
}

export default EditPostStore;
