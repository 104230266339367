import { UseInfiniteQueryResult } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IProps {
  query: UseInfiniteQueryResult<any, unknown>;
  isActive: boolean;
  renderItem: (item: any) => any;
}

const SavedTab = ({ query, isActive, renderItem }: IProps) => {
  return (
    <InfiniteScroll
      dataLength={query.data?.pages.flat().length || 0}
      next={query.fetchNextPage}
      hasMore={query.hasNextPage || false}
      loader={null}
    >
      <div
        className={classNames(styles.gridContainer, {
          'd-none': !isActive,
        })}
      >
        {query.data?.pages.flat().map((item) => renderItem(item))}
      </div>
    </InfiniteScroll>
  );
};

export default SavedTab;
