import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from 'react-query';
import Div100vh from 'react-div-100vh';

import Images from 'assets/images';
// import Div100vh from 'components/div-100vh';
import InputField from '../../components/input-field';
import MessageList from '../../components/message-list';
import {
  attachMessageListener,
  detachMessageListListener,
  getMessageList,
  hasMessageBefore,
} from 'services/direct';
import {
  clearUnreadCount,
  detachConversationListener,
  getConversationById,
} from 'services/firebase';
import SessionStore from 'stores/session';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';
import { getMyProfile } from 'services/auth';

import styles from './styles.module.scss';

const Chat = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const sessionStore: SessionStore = useStores().sessionStore;
  const myId = sessionStore.profile.id;
  const uiStore: UIStore = useStores().uiStore;

  const [hasNextPage, setHasNextPage] = useState(false);
  const [conversation, setConversation] = useState<any>(null);

  const heightRef = useRef(null);

  const queryKey = ['messages', id, sessionStore.profile.id];
  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery(queryKey, getMessageList, {
      select(data) {
        return data;
      },
      getNextPageParam: (lp) => {
        if (lp.length) {
          return lp?.[lp.length - 1].createdAt;
        }
      },
    });

  const profileQuery = useQuery(['get-my-profile'], getMyProfile);

  const flatData = data?.pages.flat() || [];
  const lastCreatedAt = flatData?.[flatData.length - 1]?.createdAt;

  const getConversationInfo = async () => {
    await getConversationById(
      id || '',
      sessionStore.profile.id,
      async (conversationInfo) => {
        if (conversationInfo) {
          setConversation(conversationInfo);
          if (
            conversationInfo.unreadCount > 0 &&
            conversationInfo.lastMessage.senderId !== sessionStore.profile.id
          ) {
            await clearUnreadCount(id || '');
          }
        } else navigate(-1);
      }
    );
  };

  useEffect(() => {
    try {
      uiStore.showLoading();
      getConversationInfo();
    } catch (err) {
      console.log('get conversation from firebase err: ', err);
    } finally {
      if (!isLoading) uiStore.hideLoading();
    }

    return () => {
      detachConversationListener();
      detachMessageListListener();
    };
  }, []);

  useEffect(() => {
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  useEffect(() => {
    const getNextPage = async () => {
      console.log('before. Has next page: ', myId, conversation, lastCreatedAt);
      const np = await hasMessageBefore(myId, conversation, lastCreatedAt);
      console.log('Has next page: ', np);
      setHasNextPage(np);
    };
    if (conversation && lastCreatedAt && id) {
      getNextPage();
    }
  }, [lastCreatedAt, id, conversation]);

  useEffect(() => {
    if (id) attachMessageListener(queryKey);

    return () => {
      detachMessageListListener();
    };
  }, [id]);

  useEffect(() => {
    let interval = setInterval(() => {
      profileQuery.refetch();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Div100vh className={styles.container} id="chat-container" ref={heightRef}>
      <div className={styles.header} style={{paddingTop: uiStore.appStatusBarHeight }}>
        <img src={Images.icBack} alt="" onClick={() => navigate(-1)} />
        <div
          className="d-flex flex-column align-items-center"
          onClick={() => navigate(`/${conversation.user.id}`)}
        >
          <img
            src={
              conversation?.user.avatar
                ? conversation?.user.avatar.small
                : Images.imgDefaultAvatar
            }
            alt=""
            className={styles.avatar}
          />
          {conversation?.user.name
            ? conversation?.user.name
            : conversation?.user.nick_name}
        </div>
      </div>
      {conversation ? (
        <MessageList
          messages={data?.pages.flat() || []}
          hasMore={hasNextPage || false}
          loadMore={fetchNextPage}
          conversation={conversation}
          heightRef={heightRef.current}
        />
      ) : (
        <div style={{ flex: 1 }}></div>
      )}
      <InputField conversationId={id!} friendId={conversation?.user.id}/>
    </Div100vh>
  );
};

export default Chat;
