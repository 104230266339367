import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import TextInputField from 'components/text-input-field';
import AuthScreenTemplate from '../../components/template';
import { checkOTP, requestPasswordReset } from 'services/auth';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';

const OTP_REGEX = /\b\d{6}\b/g;

const ResetPasswordEnterCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state
    ? (location.state as { id: number; email: string })
    : null;
  const uiStore: UIStore = useStores().uiStore;

  const [otp, setOtp] = useState('');
  const [valid, setValid] = useState(false);

  const requestMutation = useMutation(
    () => requestPasswordReset(state!.email),
    {
      onSuccess: () => {
        uiStore.showAlertBox({
          title: '認証コードは再送信しました。',
          buttons: [
            {
              title: '閉じる',
            },
          ],
        });
      },
    }
  );
  const checkOtpMutation = useMutation(() => checkOTP(state!.id, otp), {
    onSuccess: (data) =>
      navigate('/reset-password/new-password', {
        state: { secret: data.data.secret },
      }),
  });

  useEffect(() => {
    if (!state) navigate(-1);
  }, []);

  useEffect(() => {
    const isValid = OTP_REGEX.test(otp);
    setValid(isValid);
  }, [otp]);

  return (
    <AuthScreenTemplate
      title="パスワード再設定"
      subtitle={
        <p>
          メールアドレスの送信された認証 コード
          <br />
          を入力してください。
        </p>
      }
    >
      <TextInputField
        className="mt-5"
        label="認証コード"
        required
        placeholder="認証コードを入力してください"
        note="※コードは60分間使用可能となります。"
        value={otp}
        onChange={(text) => setOtp(text)}
        side="left"
      />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <button
          className={styles.submit}
          disabled={otp.trim().length === 0 || !valid}
          onClick={() => checkOtpMutation.mutate()}
        >
          OK
        </button>
        <button
          className={styles.smallButton}
          onClick={() => requestMutation.mutate()}
        >
          コードを再送信
        </button>
      </div>
    </AuthScreenTemplate>
  );
};

export default ResetPasswordEnterCode;
