import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Spinner } from 'react-bootstrap';
import { useInfiniteQuery } from 'react-query';

import Layout from 'components/layout';
import ImagePostItem from 'components/image-post-item';
import AppBar from 'components/app-bar';
import UploadOptionsButton from 'components/upload-options-button';
import Images from 'assets/images';
import { getImagePosts } from 'services/post';
import { TPost } from 'types/post';
import { transformData, getNextPageParam } from 'utils/pagination';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import LoadingSpinner from 'components/inner-loading-spinner';
import {
  attachUnreadCountListener,
  detachUnreadCountListener,
} from 'services/firebase';
import SessionStore from 'stores/session';
import useLoginRequired from 'hooks/useLoginRequired';
import { observer } from 'mobx-react';
import QuickComment from 'modules/home/components/quick-comment';

import { getNumberUnreadActivities } from 'services/user';
import { LocalStorageKey } from 'consts';
import VideoSlide from 'modules/home/components/video-slide';
import { isOnWebview } from 'services/webviewService';

const Home = observer(() => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const [unreadCount, setUnreadCount] = useState(0);

  const { showLoginPopup } = useLoginRequired();

  const { data, fetchNextPage, hasNextPage, isLoading, isRefetching, refetch } =
    useInfiniteQuery(
      ['image-posts', 'infinite'],
      ({ pageParam }) => getImagePosts(pageParam),
      {
        select(data) {
          return transformData(data);
        },
        getNextPageParam,
      }
    );

  const checkActivity = async () => {
    console.log('checkActivity');
    try {
      const data = await getNumberUnreadActivities();
      if (data.data.number_unread) {
        uiStore.setIsNewActivity(true);
      } else {
        uiStore.setIsNewActivity(false);
      }
    } catch (err: any) {
      console.log('error check activity: ', err, Object.keys(err));
    }
  };

  useEffect(() => {
    if (sessionStore.profile) {
      attachUnreadCountListener(sessionStore.profile.id, (count: any) =>
        setUnreadCount(count)
      );
    }
    checkActivity();
    return () => detachUnreadCountListener();
  }, []);

  useEffect(() => {
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  useEffect(() => {
    return () => uiStore.closeQuickComment();
  }, []);

  return (
    <Layout bottomNavBar>
      <div onTouchEnd={() => uiStore.closeQuickComment()}>
        <AppBar>
          <UploadOptionsButton style={{ marginRight: '1.2rem' }} />
          <div
            className="d-flex justify-content-center align-items-center position-relative"
            style={{ marginRight: '1.2rem' }}
          >
            <img
              src={Images.icHeart}
              style={{
                height: 18,
                width: 22,
                objectFit: 'contain',
                cursor: 'pointer',
              }}
              alt=""
              onClick={() =>
                sessionStore.profile
                  ? navigate('/activity-list')
                  : showLoginPopup()
              }
            />

            {uiStore.isNewActivity && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  position: 'absolute',
                  top: '-0.25rem',
                  right: '-0.5rem',
                  fontSize: '1rem',
                  height: '1rem',
                  width: '1rem',
                  paddingTop: '0.5rem',
                  borderRadius: '50%',
                  color: '#fff',
                  backgroundColor: '#D95454',
                  fontWeight: 'bold',
                }}
              >
                {"*"}
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center position-relative">
            <img
              src={Images.icChat}
              style={{
                height: 21,
                width: 22,
                objectFit: 'contain',
              }}
              alt=""
              onClick={() =>
                sessionStore.profile
                  ? navigate('/direct/inbox')
                  : showLoginPopup()
              }
            />
            {unreadCount > 0 && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  position: 'absolute',
                  top: '-0.25rem',
                  right: '-0.25rem',
                  fontSize: '0.625rem',
                  height: '1rem',
                  width: '1rem',
                  borderRadius: '50%',
                  color: '#fff',
                  backgroundColor: '#D95454',
                  fontWeight: 'bold',
                }}
              >
                {unreadCount > 9 ? '9+' : unreadCount}
              </div>
            )}
          </div>
        </AppBar>

        <PullToRefresh
          isPullable={isOnWebview()}
          pullingContent={""}
          onRefresh={refetch}>
          <InfiniteScroll
            dataLength={data?.pages.flat().length || 0}
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            loader={<Spinner animation="border" />}
          >
            {data?.pages.flat().map((post: TPost, index: number) => (
              <div key={post.id}>
                <ImagePostItem
                  item={post}
                  invalidateQueryKey={['image-posts', 'infinite']}
                />
                {index == 3 && <VideoSlide />}
              </div>
            ))}
          </InfiniteScroll>
        </PullToRefresh>
      </div>

      {uiStore.quickComment.isCommenting && <QuickComment />}

      <LoadingSpinner visible={isRefetching} />
    </Layout>
  );
});

export default Home;
