import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';

import Images from 'assets/images';
import PostImages from 'components/post-images';
import { showToast } from 'components/toastify';
import EmptyPost from './empty-post';
import useStores from 'hooks/useStores';
import usePost from 'hooks/usePost';
import useLoginRequired from 'hooks/useLoginRequired';
import usePostWatcher from 'hooks/use-post-watcher';
import { TPost } from 'types/post';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';
import { dateConverter } from 'utils/time';

import styles from './styles.module.scss';

interface IProps {
  item: TPost;
  invalidateQueryKey?: Array<string | number>;
}

const ImagePostItem = ({ item, invalidateQueryKey }: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const isMe = sessionStore.profile
    ? item.creator.id === sessionStore.profile.id
    : false;

  const [captionVisible, setCaptionVisible] = useState(false);
  const postRef = useRef<any>(null);

  const {
    hidden,
    likeMutation,
    unlikeMutation,
    saveMutation,
    unsaveMutation,
    hideMutation,
    unhideMutation,
    toggleLikesMutation,
    toggleCommentsMutation,
    deletePostMutation,
    report,
  } = usePost(item, invalidateQueryKey);
  const { showLoginPopup } = useLoginRequired();
  usePostWatcher({ target: postRef, postId: item.id });

  const onDelete = () => {
    uiStore.showAlertBox({
      title: '本当にこの投稿を削除しますか？',
      buttons: [
        {
          title: '削除',
          isRed: true,
          onPress: () => {
            uiStore.hideAlertBox();
            deletePostMutation.mutate();
          },
        },
        { title: 'キャンセル', onPress: () => uiStore.hideAlertBox() },
      ],
    });
  };

  const onHideLikes = () => {
    toggleLikesMutation.mutate({ status: !item.setting.show_number_like });
  };

  const onTurnOffComments = () => {
    toggleCommentsMutation.mutate({ status: !item.setting.show_comment });
  };

  const onEdit = () => {
    navigate(`/p/${item.id}/edit`);
  };

  const onCopyLink = () => {
    copy(`${window.location.origin}/posts/${item.id}`);
    showToast('リンクがクリップボードにコピーされました');
  };

  const onQuickCommentClick = (event: any) => {
    if (sessionStore.profile) {
      if (!uiStore.quickComment.isCommenting) {
        uiStore.showQuickComment(item.id);
      } else {
        uiStore.closeQuickComment();
      }
    } else {
      showLoginPopup();
    }
  };

  const renderSeeMore = () => (
    <span
      className={styles.readMore}
      onClick={() => setCaptionVisible(!captionVisible)}
    >
      {captionVisible ? '省略' : '続きを読む'}
    </span>
  );

  const renderCaption2 = () => {
    const segments = item.content.split('\n');
    if (item.content.length <= 100 && segments.length <= 5) {
      return (
        <div
          className={styles.content}
          onClick={() => navigate(`/posts/${item.id}`)}
        >
          <span>
            {segments.map((segment, index) => (
              <span key={index}>
                {segment}
                {index < segments.length - 1 && <br />}
              </span>
            ))}
          </span>
        </div>
      );
    } else {
      const visibleSegments = item.content
        .substring(0, 101)
        .split('\n')
        .slice(0, 5);
      return (
        <div className={styles.content}>
          <span onClick={() => navigate(`/posts/${item.id}`)}>
            {captionVisible
              ? item.content
              : visibleSegments.map((segment, index) => (
                  <span key={index}>
                    {segment}
                    {index < visibleSegments.length - 1 ? <br /> : '...'}
                  </span>
                ))}
          </span>
          {renderSeeMore()}
        </div>
      );
    }
  };
  return !hidden ? (
    <div className={styles.container} ref={postRef}>
      <PostImages item={item} invalidateQueryKey={invalidateQueryKey} />
      <div className={styles.mainContainer}>
        <div className="d-flex flex-row justify-content-between">
          <div>
            <img
              src={item.is_liked ? Images.icHeartActive : Images.icHeart}
              style={{
                width: 22,
              }}
              className={styles.icon}
              alt=""
              onClick={() => {
                if (sessionStore.profile)
                  item.is_liked
                    ? unlikeMutation.mutate()
                    : likeMutation.mutate();
                else showLoginPopup();
              }}
            />
            {item.setting.show_comment && (
              <img
                src={Images.icChat}
                style={{
                  width: 22,
                }}
                className={styles.icon}
                alt=""
                onClick={() => navigate(`/posts/${item.id}/comments`)}
              />
            )}
            {invalidateQueryKey && invalidateQueryKey[0] == 'image-posts' && (
              <img
                src={Images.icBook}
                style={{
                  width: 22,
                }}
                className={styles.icon}
                alt=""
                onClick={() => navigate(`/posts/${item.id}`)}
              />
            )}
          </div>
          <div>
            <img
              src={Images.icThreeDots}
              style={{
                width: 22,
              }}
              alt=""
              className={styles.icon}
              onClick={() => {
                uiStore.showActionSheet(
                  sessionStore.profile
                    ? isMe
                      ? [
                          {
                            title: '削除',
                            isRed: true,
                            onPress: onDelete,
                          },
                          {
                            title: item.setting.show_number_like
                              ? '「いいね！」数を非表示にする'
                              : '「いいね！」数を表示する',
                            onPress: onHideLikes,
                          },
                          {
                            title: item.setting.show_comment
                              ? 'コメントをオフにする'
                              : 'コメントをオンにする',
                            onPress: onTurnOffComments,
                          },
                          {
                            title: '編集',
                            onPress: onEdit,
                          },
                          {
                            title: 'リンクをコピー',
                            onPress: onCopyLink,
                          },
                        ]
                      : [
                          {
                            title: '報告する',
                            isRed: true,
                            onPress: report,
                          },
                          {
                            title: '非表示にする',
                            isRed: true,
                            onPress: () => {
                              hideMutation.mutate();
                            },
                          },
                          {
                            title: 'リンクをコピー',
                            onPress: onCopyLink,
                          },
                        ]
                    : [
                        {
                          title: 'リンクをコピー',
                          onPress: onCopyLink,
                        },
                      ]
                );
              }}
            />
            <img
              src={item.is_saved ? Images.icBookmarkActive : Images.icBookmark}
              style={{
                width: 22,
              }}
              className={styles.icon}
              alt=""
              onClick={() =>
                sessionStore.profile
                  ? item.is_saved
                    ? unsaveMutation.mutate()
                    : saveMutation.mutate()
                  : showLoginPopup()
              }
            />
          </div>
        </div>
        {item.setting.show_number_like && (
          <div className={styles.likeCount}>
            <button onClick={() => navigate(`/posts/${item.id}/likes`)}>
              {item.number_likes}いいね！
            </button>
          </div>
        )}

        {item.content.trim().length > 0 && renderCaption2()}

        {item.number_comments > 0 && item.setting.show_comment && (
          <div
            className={styles.blurred}
            onClick={() => navigate(`/posts/${item.id}/comments`)}
          >
            コメント{item.number_comments}件をすべて見る
          </div>
        )}

        <div
          className={classNames('d-flex flex-row', styles.blurred)}
          style={{
            justifyContent: `${
              window.location.pathname === '/feed' && item.setting.show_comment
                ? 'space-between'
                : 'end'
            }`,
          }}
        >
          {window.location.pathname === '/feed' &&
            item.setting.show_comment && (
              <div onClick={onQuickCommentClick}>コメントを追加</div>
            )}
          <div>{dateConverter(item.created_at)}</div>
        </div>
      </div>
    </div>
  ) : (
    <EmptyPost
      onUnhide={() => {
        unhideMutation.mutate();
      }}
    />
  );
};

export default ImagePostItem;
