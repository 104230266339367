import MentionItem from '../mention-item/index';
import styles from './styles.module.scss';
import Spinner from 'react-bootstrap/Spinner';

interface IProps {
  mentionUsersData: any;
  addUserToComment: (name: string) => void;
}

const MentionSuggestions = (props: IProps) => {
  const { data, isLoading, isError } = props.mentionUsersData;

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.spinner}>
          <Spinner animation="border" variant="danger" />
        </div>
      ) : isError ? (
        <p>error</p>
      ) : (
        data.users.map((user: any, index: number) => {
          return (
            <MentionItem
              key={index}
              user={user}
              addUserToComment={props.addUserToComment}
            />
          );
        })
      )}
    </div>
  );
};

export default MentionSuggestions;
