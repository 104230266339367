import styles from './styles.module.scss';

interface IProps {
  onSelect: (reason: string) => void;
}

const Report = ({ onSelect }: IProps) => {
  return (
    <div className={styles.reportContainer}>
      <div className={styles.title}>報告</div>
      <div className={styles.content}>
        <div className={styles.intro}>この投稿を報告する理由</div>
        <div className={styles.introText}>
          知的財産権の侵害に関するものを除いては、報告はすべて匿名で
          す。差し追った危険に直面する人がいる場合は、今すぐ地域の警察
          または消防機関に緊急通報してください。
        </div>
        {[
          'スパムである',
          'ヌードまたは性的行為',
          '単に気に入らない',
          '詐欺・欺瞞',
          'ヘイトスピーチまたは差別的なシンボル',
          '虚偽の情報',
          'いじめまたは嫌がらせ',
          '暴力または危険な団体',
          '知的財産権の侵害',
          '違法または規制対象商品の販売',
          '自殺または自傷行為',
          '摂食障害',
        ].map((reason, index) => (
          <button key={index} onClick={() => onSelect(reason)}>
            {reason}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Report;
