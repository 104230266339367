import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import TextInputField from 'components/text-input-field';
import AuthScreenTemplate from 'modules/auth/components/template';
import { resetPassword } from 'services/auth';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';

const ResetPasswordNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state ? (location.state as { secret: string }) : null;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const uiStore: UIStore = useStores().uiStore;

  const mutation = useMutation(
    () => resetPassword(state!.secret, password, confirmPassword),
    {
      onSuccess: () => {
        uiStore.showAlertBox({
          title: 'パスワードの変更が完了しました',
          content:
            'パスワードの再設定が完了しましたので新しいパスワードで再度ログインしてください。',
          buttons: [
            {
              title: 'ログイン画面へ',
              isRed: true,
              onPress: () => navigate('/login'),
            },
          ],
        });
      },
    }
  );

  useEffect(() => {
    if (!state) navigate(-1);
  }, []);

  return (
    <AuthScreenTemplate
      title="パスワード再設定"
      subtitle={
        <p>
          新しいパスワードを決定してください。パスワードは、数
          <br />
          字とアルファベットにて32文字以内にて入力ください。
        </p>
      }
    >
      <form
        onSubmit={(event: any) => {
          event.preventDefault();
          if (password !== confirmPassword) {
            uiStore.showAlertBox({
              title: 'パスワードが一致しませんでした。',
              buttons: [{ title: '閉じる' }],
            });
          } else mutation.mutate();
        }}
      >
        <TextInputField
          className="mt-5"
          label="新パスワード"
          required
          placeholder="新パスワードを決定してください"
          type="password"
          value={password}
          onChange={(text) => setPassword(text)}
          side="left"
          pattern="(?=.*[0-9])(?=.*[a-zA-Z]).{6,32}$"
        />
        <TextInputField
          className="mt-3"
          label="新パスワード（確認）"
          required
          placeholder="新パスワードを再度入力してください"
          type="password"
          value={confirmPassword}
          onChange={(text) => setConfirmPassword(text)}
          side="left"
          pattern="(?=.*[0-9])(?=.*[a-zA-Z]).{6,32}$"
        />
        <div className="d-flex justify-content-center align-items-center">
          <button
            type="submit"
            className={styles.submit}
            disabled={
              password.trim().length === 0 ||
              confirmPassword.trim().length === 0
            }
          >
            変更
          </button>
        </div>
      </form>
    </AuthScreenTemplate>
  );
};

export default ResetPasswordNewPassword;
