import Layout from 'components/layout';
import Header from 'components/header';

import '../styles.scss';

const Law = () => {
  return (
    <Layout bottomNavBar>
      <Header title="特定商取引に基づく表示" back bordered />
      <div className="static-page">
        販売事業者名 株式会社アデッソ
        <br />
        販売事業者所在地 東京都渋谷区代々木4-5-7 参宮橋ビル302号
        <br />
        代表者 田代直人
        <br />
        サイト名 芸能市場
        <br />
        連絡先 お問い合わせページからご連絡ください
        <br />
        販売価格帯 20,000～240,000円
        <br />
        商品代金以外に必要な費用 消費税
        <br />
        お支払い時期、方法 プランお申し込み時、クレジットカードにてお支払い
        <br />
        商品の引き渡し時期
        弊社にてクレジットカード決済完了の確認が出来次第、即日お引き渡し
        <br />
        返品の取扱条件
        <br />
        解約・退会の条件
        お客様のご都合によるキャンセルはお受けできませんので、あらかじめご了承ください。
        <br />
        ＜返金の取扱条件＞
        <br />
        弊社都合によりサービスをご利用いただけなかった場合には返金にご対応致します。
        <br />
        ＜解約・退会条件＞
        <br />
        ・長期パックの場合：長期パックの場合：ご利用期間終了月の前月25日までに解約（プランの利用中止）または退会のお申し出をいただいた場合、翌月以降のサービス提供及び利用料金の請求を停止いたします。（例：3月にお申し込みの場合、翌年2月25日までに解約または退会のお申し出で翌3月以降の請求を停止）自動決済日は毎月1日に行われます。なお、解約（プランの利用中止）のお申し出後も契約期間中はプランをご利用いただくことができます。
        <br />
        ・トライアルパックの場合：都度決済となりますお客様のご都合によるキャンセルはお受けできませんので、あらかじめご了承ください。
        <br />
        不良品の取り扱い条件
        弊社都合によりサービスをご利用いただけなかった場合には返金にご対応致します。サービスをご利用いただけなかった場合にはまず、メールにてサービス利用開始後14日以内にお問い合わせください。その後弊社にて、ご利用いただけない原因を精査し、弊社都合であると判断された場合には返金の対応を致します。
        <br />
        その他 本表示に定めのない事項については、利用規約をご参照ください。
      </div>
    </Layout>
  );
};

export default Law;
