import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Zoom } from 'swiper';

import UserTag from 'components/user-tag';
import ProductTag from 'components/product-tag';
import Images from 'assets/images';
import { TPost } from 'types/post';

import styles from './styles.module.scss';
import useStores from 'hooks/useStores';
import usePost from 'hooks/usePost';
import useLoginRequired from 'hooks/useLoginRequired';

import SessionStore from 'stores/session';

interface IProps {
  item: TPost;
  invalidateQueryKey?: Array<string | number>;
}

const PostImages = ({ item, invalidateQueryKey }: IProps) => {
  const navigate = useNavigate();
  const { showLoginPopup } = useLoginRequired();
  const sessionStore: SessionStore = useStores().sessionStore;

  const [index, setIndex] = useState(0);
  const [showTags, setShowTags] = useState(false);
  const { likeMutation } = usePost(item, invalidateQueryKey);
  const clickCount = useRef<number>(0);

  const onImageClick = (_event: any) => {
    clickCount.current += 1;
    if (clickCount.current <= 1) {
      setTimeout(() => {
        if (clickCount.current === 1) {
          setShowTags(!showTags);
        } else {
          if (sessionStore.profile) {
            likeMutation.mutate();
          } else {
            showLoginPopup();
          }
        }
        clickCount.current = 0;
      }, 200);
    }
  };

  const imageRef = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      <div className={styles.imgContainer} ref={imageRef}>
        <Swiper
          modules={[Zoom]}
          onSlideChange={(swiper) => {
            setIndex(swiper.activeIndex);
          }}
          zoom={{
            toggle: false,
          }}
        >
          {item.files.map((file, activeIndex) => {
            const hasProductTags =
              file?.product_tags.filter((tag) => tag.type === 1).length > 0;
            return (
              <SwiperSlide key={file.id}>
                <div className={styles.imgContainer + ' swiper-zoom-container'}>
                  <img
                    src={file.origin}
                    alt=""
                    className={styles.img}
                    onClick={onImageClick}
                    loading="lazy"
                  />
                  <div
                    className={classNames(
                      'd-flex flex-row position-absolute',
                      styles.iconContainer
                    )}
                  >
                    {file.people_tags.length > 0 && (
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={Images.icPerson}
                          className={styles.icon}
                          alt=""
                        />
                      </div>
                    )}
                    {hasProductTags && (
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={Images.icBag}
                          className={styles.icon}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                  {showTags &&
                    file.people_tags.length > 0 &&
                    file.people_tags.map((tag) => {
                      return (
                        <UserTag
                          key={tag.id}
                          nickname={tag.nick_name}
                          x={tag.position_x}
                          y={tag.position_y}
                          onClick={() => navigate(`/${tag.id}`)}
                          leftOffset={window.innerWidth * (activeIndex - index)}
                          rightOffset={
                            window.innerWidth * (activeIndex - index + 1)
                          }
                          topOffset={
                            imageRef.current?.getBoundingClientRect().top
                          }
                        />
                      );
                    })}
                  {showTags &&
                    file.product_tags.filter((tag) => tag.type === 1).length >
                      0 &&
                    file.product_tags
                      .filter((tag) => tag.type === 1)
                      .map((tag, productIndex) => (
                        <ProductTag
                          key={productIndex}
                          name={tag.title}
                          x={tag.position_x}
                          y={tag.position_y}
                          onClick={() => window.open(tag.link)}
                          leftOffset={window.innerWidth * (activeIndex - index)}
                          rightOffset={
                            window.innerWidth * (activeIndex - index + 1)
                          }
                          topOffset={
                            imageRef.current?.getBoundingClientRect().top
                          }
                        />
                      ))}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {item.files.length > 1 && (
          <div className={styles.imgNumber}>
            {index + 1}/{item.files.length}
          </div>
        )}
        <div
          className={classNames('d-flex flex-row', styles.userInfo)}
          onClick={() => navigate(`/${item.creator.id}`)}
        >
          <img
            src={item.creator.avatar?.small || Images.imgDefaultAvatar}
            alt=""
            className={styles.avatar}
          />
          <div>
            <div className={styles.name}>{item.creator.name}</div>
            <div className={styles.username}>{item.creator.nick_name}</div>
          </div>
        </div>
      </div>
      {item.files.length > 0 &&
        item.files[index].product_tags.some((tag) => tag.type === 2) && (
          <div
            className={styles.bottomLink}
            onClick={() =>
              window.open(
                item.files[index].product_tags.filter(
                  (tag) => tag.type === 2
                )[0].link
              )
            }
          >
            {
              item.files[index].product_tags.filter((tag) => tag.type === 2)[0]
                .title
            }
          </div>
        )}
    </div>
  );
};

export default PostImages;
