import { useState } from 'react';
import { useMutation } from 'react-query';

import { TPost } from 'types/post';
import {
  deletePost,
  hide,
  like,
  reportPost,
  save,
  unhide,
  unlike,
  unsave,
  updatePost,
} from 'services/post';
import useStores from './useStores';
import UIStore from 'stores/ui';
import { queryClient } from 'utils/query-client';
import Report from 'components/report-modal';
import ReportSuccess from 'components/report-success-modal';
import { showToast } from 'components/toastify';
import { useNavigate } from 'react-router-dom';

const usePost = (item: TPost, invalidateQueryKey?: any) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;

  const [hidden, setHidden] = useState(false);

  const onActionSucceed = () => {
    if (invalidateQueryKey) {
      queryClient.invalidateQueries(invalidateQueryKey);
    }
  };
  const likeMutation = useMutation(() => like(item.id), {
    onSuccess: onActionSucceed,
  });

  const unlikeMutation = useMutation(() => unlike(item.id), {
    onSuccess: onActionSucceed,
  });

  const saveMutation = useMutation(() => save(item.id), {
    onSuccess: () => {
      onActionSucceed();
      showToast('保存しました');
    },
  });

  const unsaveMutation = useMutation(() => unsave(item.id), {
    onSuccess: onActionSucceed,
  });

  const hideMutation = useMutation(() => hide(item.id), {
    onSuccess: () => setHidden(true),
  });

  const unhideMutation = useMutation(() => unhide(item.id), {
    onSuccess: () => setHidden(false),
  });

  const reportMutation = useMutation<any, unknown, { reason: string }, unknown>(
    ({ reason }) => reportPost(item.id, reason),
    {
      onSuccess: () => {
        uiStore.showBottomSheet(<ReportSuccess />);
      },
    }
  );

  const report = () => {
    uiStore.showBottomSheet(
      <Report
        onSelect={(reason) => {
          uiStore.hideBottomSheet();
          reportMutation.mutate({ reason });
        }}
      />
    );
  };

  const toggleLikesMutation = useMutation<
    any,
    unknown,
    { status: boolean },
    unknown
  >(
    ({ status }) =>
      updatePost(item.id, {
        setting: {
          show_number_like: status,
        },
      }),
    {
      onSuccess: onActionSucceed,
    }
  );

  const toggleCommentsMutation = useMutation<
    any,
    unknown,
    { status: boolean },
    unknown
  >(
    ({ status }) =>
      updatePost(item.id, {
        setting: {
          show_comment: status,
        },
      }),
    {
      onSuccess: onActionSucceed,
    }
  );

  const deletePostMutation = useMutation(() => deletePost(item.id), {
    onSuccess: (data) => {
      onActionSucceed();
      navigate(-1);
    },
  });

  return {
    hidden,
    likeMutation,
    unlikeMutation,
    saveMutation,
    unsaveMutation,
    hideMutation,
    unhideMutation,
    reportMutation,
    toggleLikesMutation,
    toggleCommentsMutation,
    deletePostMutation,
    report,
  };
};

export default usePost;
