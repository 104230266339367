import { observer } from 'mobx-react';

import TextInputField from 'components/text-input-field';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';
import { urlValidation } from 'utils/validation';
import EditVideoStore from 'stores/edit-video';

const EditVideoTags = observer(() => {
  const editVideoStore: EditVideoStore = useStores().editVideoStore;

  const renderItem = (index: number) => {
    console.log(editVideoStore.products[index]);
    const isTitleValid = editVideoStore.products[index].title?.length < 15;
    const isLinkValid = urlValidation(editVideoStore.products[index].link);

    return (
      <div className={styles.item} key={index}>
        <div className={styles.mark}>商品{index + 1}</div>
        <TextInputField
          label="タグ名"
          placeholder="タグ名を入力してください"
          value={editVideoStore.products[index].title}
          onChange={(text: string) => editVideoStore.setTitle(index, text)}
        />
        {!isTitleValid && (
          <p className={styles.error}>タグ名は15文字以内で入力してください。</p>
        )}

        <TextInputField
          label="商品URL"
          placeholder="商品ページのURLを入力してください"
          value={editVideoStore.products[index].link}
          onChange={(text: string) => editVideoStore.setLink(index, text)}
        />
        {!isLinkValid && <p className={styles.error}>無効なURLです。</p>}
      </div>
    );
  };

  return (
    <form className={styles.container}>
      <div className={styles.header}>商品URLの追加</div>
      {new Array(4).fill(0).map((_, index) => renderItem(index))}
    </form>
  );
});

export default EditVideoTags;
