import { useEffect, useLayoutEffect, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  Router,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import LoginMethods from 'modules/auth/screens/login-methods';
import Login from 'modules/auth/screens/login';
import RegistrationMethods from 'modules/auth/screens/registration-methods';
import Registration from 'modules/auth/screens/registration';
import ResetPasswordEnterMail from 'modules/auth/screens/reset-password/mail';
import ResetPasswordEnterCode from 'modules/auth/screens/reset-password/code';
import ResetPasswordNewPassword from 'modules/auth/screens/reset-password/new-password';

import Home from 'modules/home/screens/home';
import CommentList from 'modules/home/screens/comment-list';
import LikeList from 'modules/home/screens/like-list';
import ActivityList from 'modules/home/screens/activity-list';

import UploadPhotos from 'modules/upload-photos/screens/upload-photos';
import CreatePost from 'modules/upload-photos/screens/create-post';
import TagUsers from 'modules/upload-photos/screens/tag-users';
import TagProducts from 'modules/upload-photos/screens/tag-products';
import AddBottomLink from 'modules/upload-photos/screens/add-bottom-link';
import AdvancedSettings from 'modules/upload-photos/screens/advanced-settings';

import UploadVideo from 'modules/upload-video/screens/upload-video';
import CreateVideoPost from 'modules/upload-video/screens/create-post';
import VideoTagProducts from 'modules/upload-video/screens/tag-products';
import VideoAdvancedSettings from 'modules/upload-video/screens/advanced-settings';

import ConversationList from 'modules/direct/screens/conversation-list';
import Chat from 'modules/direct/screens/chat';

import Discover from 'modules/discover/screens/discover';

import Shop from 'modules/shop/screens/shop';
import ProductDetail from 'modules/shop/screens/product-detail';

import Settings from 'modules/my-page/screens/settings';
import AccountSettings from 'modules/my-page/screens/account-settings';
import BlockList from 'modules/my-page/screens/block-list';
import Policies from 'modules/my-page/screens/policies';
import TermsOfService from 'modules/my-page/screens/terms-of-service';
import PrivacyPolicy from 'modules/my-page/screens/privacy-policy';
import Law from 'modules/my-page/screens/law';
import AboutUs from 'modules/my-page/screens/about-us';
import Security from 'modules/my-page/screens/security';
import ChangePassword from 'modules/my-page/screens/change-password';
import Emails from 'modules/my-page/screens/emails';
import EmailDetail from 'modules/my-page/screens/email-detail';
import SavedAll from 'modules/my-page/screens/saved';

import LoadingSpinner from 'components/loading-spinner';
import AlertBox from 'components/alert-box';
import ActionSheet from 'components/action-sheet';
import BottomSheet from 'components/bottom-sheet';
import stores from 'stores';

import CreatorList from 'modules/discover/screens/creator-list';
import DiscoverSearch from 'modules/discover/screens/search';
import Videos from 'modules/videos/screens/videos';
import EditVideo from 'modules/edit-video/screens/edit-video';
import GuideLines from 'modules/guidelines/screens/guidelines';
import Starting from 'modules/guidelines/screens/starting';

import PostDetails from 'modules/post/screens/post-details';
import EditPost from 'modules/edit-post/screens/edit-post';
import UserProfile from 'modules/user-profile/screens/user-profile';
import Followers from 'modules/user-profile/screens/followers';
import Following from 'modules/user-profile/screens/following';

import { history } from 'utils/history';
import SessionStore from 'stores/session';
import useStores from 'hooks/useStores';
import { queryClient } from 'utils/query-client';
import { ENV } from 'config';
import { getMyProfile, getXSRFToken, updateFcmToken } from 'services/auth';
import UIStore from 'stores/ui';

import './App.scss';
import ToastNotify from 'components/toastify';
import {
  sendMessageToNative,
  registHandleNativeMessage,
  startListenNativeMessage,
  isOnWebview,
} from 'services/webviewService';
import { WEBVIEW_MESSAGE } from 'consts';
import EditVideoTags from 'modules/edit-video/screens/tag-products';
import EditVideoOptions from 'modules/edit-video/screens/edit-video-options';
import EditPostUserTag from 'modules/edit-post/screens/tag-users';
import EditPostProductTag from 'modules/edit-post/screens/tag-products';
import EditPostOptions from 'modules/edit-post/screens/edit-post-options';
import EditBottomLink from 'modules/edit-post/screens/edit-bottom-link';
import Splash from 'modules/auth/screens/splash';

const CustomRouter = ({ history, ...props }: any) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

const PrivateRoute = observer(() => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  uiStore.setAppStatusBarHeight(parseInt(localStorage.getItem('app_status_bar_height') as string))

  useEffect(() => {
    if (isOnWebview()) {
      sendMessageToNative(WEBVIEW_MESSAGE.GET_STATUS_BAR_HEIGHT, {});
      registHandleNativeMessage(
        WEBVIEW_MESSAGE.GET_STATUS_BAR_HEIGHT,
        async (channel: string, data: any) => {
          uiStore.setAppStatusBarHeight(data);
        }
      );
    }

    const getProfile = async () => {
      try {
        uiStore.showLoading();
        const data = await getMyProfile();
        sessionStore.setProfile(data.data);
      } catch (err: any) {
        if (err.message === 'E_LoginRequired') {
          navigate('/login-methods');
        }
        if (err.message === 'E_NoInternet') {
          navigate('/feed');
        }
      } finally {
        uiStore.hideLoading();
      }
    };

    if (!sessionStore.profile) {
      getProfile();
    }
  }, []);

  return sessionStore.profile ? <Outlet /> : <div />;
});

const PublicRoute = observer(() => {
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  uiStore.setAppStatusBarHeight(parseInt(localStorage.getItem('app_status_bar_height') as string))

  useEffect(() => {
    if (isOnWebview()) {
      sendMessageToNative(WEBVIEW_MESSAGE.GET_STATUS_BAR_HEIGHT, {});
      registHandleNativeMessage(
        WEBVIEW_MESSAGE.GET_STATUS_BAR_HEIGHT,
        async (channel: string, data: any) => {
          uiStore.setAppStatusBarHeight(data);
        }
      );
    }

    const getProfile = async () => {
      try {
        await getXSRFToken();
        uiStore.showLoading();
        const data = await getMyProfile();
        sessionStore.setProfile(data.data);
      } catch (err: any) {
        console.log('error in public route: ', err, Object.keys(err));
      } finally {
        uiStore.hideLoading();
      }
    };

    if (!sessionStore.profile) {
      getProfile();
    }
  }, []);

  return sessionStore.profile ? <Navigate to="/feed" /> : <Outlet />;
  // return <Outlet />;
});

const LoginUnrequiredRoute = observer(() => {
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  uiStore.setAppStatusBarHeight(parseInt(localStorage.getItem('app_status_bar_height') as string))

  useEffect(() => {
    if (isOnWebview()) {
      sendMessageToNative(WEBVIEW_MESSAGE.GET_STATUS_BAR_HEIGHT, {});
      registHandleNativeMessage(
        WEBVIEW_MESSAGE.GET_STATUS_BAR_HEIGHT,
        async (channel: string, data: any) => {
          uiStore.setAppStatusBarHeight(data);
        }
      );
    }

    const getProfile = async () => {
      try {
        await getXSRFToken();
        uiStore.showLoading();
        const data = await getMyProfile();
        sessionStore.setProfile(data.data);
      } catch (err: any) {
        console.log('error in login unrequired route: ', err, Object.keys(err));
      } finally {
        uiStore.hideLoading();
      }
    };

    if (!sessionStore.profile) {
      getProfile();
    }
  }, []);

  // return sessionStore.profile ? <Navigate to="/feed" /> : <Outlet />;
  return <Outlet />;
});

function App() {
  useEffect(() => {
    startListenNativeMessage();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider {...stores}>
        <CustomRouter history={history}>
          <Routes>
            <Route path="/app-start" element={<Splash />} />

            <Route path="/" element={<PublicRoute />}>
              <Route path="/" element={<Navigate to="/feed" />} />

              <Route path="/guides" element={<GuideLines />} />
              <Route path="/start" element={<Starting />} />

              <Route path="/login" element={<Login />} />
              <Route path="/login-methods" element={<LoginMethods />} />
              <Route
                path="/registration-methods"
                element={<RegistrationMethods />}
              />
              <Route path="/registration" element={<Registration />} />
              <Route
                path="/reset-password"
                element={<ResetPasswordEnterMail />}
              />
              <Route
                path="/reset-password/code"
                element={<ResetPasswordEnterCode />}
              />
              <Route
                path="/reset-password/new-password"
                element={<ResetPasswordNewPassword />}
              />
            </Route>

            <Route path="/" element={<LoginUnrequiredRoute />}>
              <Route path="/feed" element={<Home />} />
              <Route path="/posts/:id" element={<PostDetails />} />
              <Route path="/posts/:id/comments" element={<CommentList />} />
              <Route path="/posts/:id/likes" element={<LikeList />} />

              <Route path="/discover" element={<Discover />} />
              <Route path="/discover/creators" element={<CreatorList />} />
              <Route path="/discover/search" element={<DiscoverSearch />} />

              <Route path="/video" element={<Videos />} />

              <Route path="/shop" element={<Shop />} />
              <Route path="/product/:id" element={<ProductDetail />} />

              <Route path="/:id" element={<UserProfile />} />
              <Route path="/:id/following" element={<Following />} />
              <Route path="/:id/followers" element={<Followers />} />
            </Route>

            <Route path="/" element={<PrivateRoute />}>
              <Route path="/p/:id/edit" element={<EditPost />}>
                <Route path="" element={<EditPostOptions />} />
                <Route path="tag-users" element={<EditPostUserTag />} />
                <Route path="tag-products" element={<EditPostProductTag />} />
                <Route path="*" element={<Navigate to={'/feed'} />} />
              </Route>
              <Route
                path="/edit-post/add-bottom-link"
                element={<EditBottomLink />}
              />

              <Route path="/activity-list" element={<ActivityList />} />

              <Route path="/upload-photos" element={<UploadPhotos />}>
                <Route path="" element={<CreatePost />} />
                <Route path="tag-users" element={<TagUsers />} />
                <Route path="tag-products" element={<TagProducts />} />
              </Route>
              <Route
                path="/upload-photos/add-bottom-link"
                element={<AddBottomLink />}
              />
              <Route
                path="/upload-photos/advanced-settings"
                element={<AdvancedSettings />}
              />

              <Route path="/upload-video" element={<UploadVideo />}>
                <Route path="" element={<CreateVideoPost />} />
                <Route path="tag-products" element={<VideoTagProducts />} />
              </Route>
              <Route
                path="/upload-video/advanced-settings"
                element={<VideoAdvancedSettings />}
              />
              <Route path="/v/:id/edit" element={<EditVideo />}>
                <Route path="" element={<EditVideoOptions />} />
                <Route path="tags" element={<EditVideoTags />} />
                <Route path="*" element={<Navigate to={'/video'} />} />
              </Route>

              <Route path="/direct/inbox" element={<ConversationList />} />
              <Route path="/direct/:id" element={<Chat />} />

              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/account" element={<AccountSettings />} />
              <Route path="/settings/block-list" element={<BlockList />} />
              <Route path="/settings/security" element={<Security />} />
              <Route
                path="/settings/change-password"
                element={<ChangePassword />}
              />
              <Route path="/settings/emails" element={<Emails />} />
              <Route path="/settings/email/:id" element={<EmailDetail />} />
              <Route path="/saved" element={<SavedAll />} />
              <Route path="/policies" element={<Policies />} />
              <Route path="/policies/terms" element={<TermsOfService />} />
              <Route path="/policies/privacy" element={<PrivacyPolicy />} />
              <Route path="/policies/law" element={<Law />} />
              <Route path="/policies/about-us" element={<AboutUs />} />
            </Route>
          </Routes>

          <LoadingSpinner />
          <AlertBox />
          <ActionSheet />
          <BottomSheet />
          <ToastNotify />
        </CustomRouter>
      </Provider>
      {ENV === 'local' && (
        <ReactQueryDevtools initialIsOpen position="top-right" />
      )}
    </QueryClientProvider>
  );
}

export default App;
