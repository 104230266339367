import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getNotificationDetail } from 'services/notification';
import Header from 'components/header';
import Layout from 'components/layout';
import styles from './styles.module.scss';
import { nl2br } from 'utils/string';

const EmailDetail = () => {
  const { id } = useParams();
  const { data } = useQuery(['email-detail', id], () =>
    getNotificationDetail(+id!)
  );

  return (
    <Layout bottomNavBar>
      <Header title="ENTAMAからのメール" back bordered />
      {data && (
        <section className={styles.container}>
          <div className={styles.date}>{data.created_at}</div>
          <div className={styles.title}>{data.title}</div>
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: nl2br(data.content) }} />
        </section>
      )}
    </Layout>
  );
};

export default EmailDetail;
