import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import UserList from '../../components/user-list';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';
import EditPostStore from 'stores/edit-post';
import UserTagList from 'modules/edit-post/components/user-tag-list';

const EditPostUserTag = observer(() => {
  const uiStore: UIStore = useStores().uiStore;
  const editPostStore: EditPostStore = useStores().editPostStore;

  const index = editPostStore.activeImageIndex;

  const [modal, setModal] = useState(false);

  const [currentImageTagListLength, setCurrentImageTagListLength] = useState(0);

  const checkUsersLength = () => {
    if (currentImageTagListLength < 10) {
      setModal(true);
    } else
      setTimeout(() => {
        uiStore.showAlertBox({
          title: '人物のタグ付けは10人まで可能です。',
          buttons: [{ title: '閉じる' }], // cancel
        });
      }, 100);
  };

  const Instruction = () => (
    <div className={styles.content}>
      {/* tap on the photo to tag */}
      写真をタップしてタグ付けできます。
      <br />
      {/* can tag up to 10 people */}
      人物のタグ付けは10人まで可能です。
    </div>
  );

  // this will toggle user tags
  useEffect(() => {
    editPostStore.setIsTaggingUser(true);
    return () => editPostStore.setIsTaggingUser(false);
  }, []);

  // check list length after removing or adding
  useEffect(() => {
    const tagListLength = editPostStore.userTagList[index].list.length;
    setCurrentImageTagListLength(tagListLength);
  }, [
    editPostStore.activeImageIndex,
    editPostStore.userTagList[index].list.length,
  ]);

  // if the user clicks on the image, check list length.
  useEffect(() => {
    console.log(editPostStore.currentPosition);
    if (editPostStore.currentPosition !== null) checkUsersLength();
  }, [editPostStore.currentPosition]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>タグを追加</div>

      {currentImageTagListLength === 0 ? (
        <Instruction />
      ) : (
        <UserTagList
          users={editPostStore.userTagList[index].list}
          onUntag={(userId: number) => {
            editPostStore.unTagUser(userId);
          }}
        />
      )}

      {modal && (
        <UserList
          visible={modal}
          onCancel={() => setModal(false)}
          onSelect={(user) => {
            editPostStore.addUserToTagList(user);
            setModal(false);
          }}
        />
      )}
    </div>
  );
});

export default EditPostUserTag;
