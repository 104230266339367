import { get, post } from 'services';

export const getXSRFToken = () => {
  return get('sanctum/csrf-cookie', {});
};

export const login = (email: string, password: string) => {
  return post('login', {
    email,
    password,
  });
};

export const logout = () => {
  return post('logout', {});
};

export const getMyProfile = () => {
  return get('api/user/profile', {});
};

export const requestPasswordReset = (email: string) => {
  return post('api/user/password/reset/request', { email });
};

export const checkOTP = (id: number, code: string) => {
  return post('api/user/password/reset/otp/check', { id, code });
};

export const resetPassword = (
  secret: string,
  password: string,
  password_confirm: string
) => {
  return post('api/user/password/reset', {
    secret,
    password,
    password_confirm,
  });
};

export const register = (
  email: string,
  nick_name: string,
  password: string,
  password_confirm: string,
  sns_token?: string
) => {
  const params = {
    email,
    nick_name,
    password,
    password_confirm,
  };
  return post(
    'api/user/register',
    sns_token ? { ...params, sns_token } : params
  );
};

export const loginSnsWebView = (
  provider: string,
  token: string,
  token_secret?: string
) => {
  return post('login_sns_app', {
    provider,
    token,
    token_secret,
  });
};

export const updateFcmToken = (
  device_type: string,
  fcm_token: string
) => {
  return post('api/user/fcmtoken/update', {
    device_type,
    fcm_token
  });
};
