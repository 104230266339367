import { useEffect } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useMutation } from 'react-query';

import Layout from 'components/layout';
import Header from 'components/header';
import { createPost } from 'services/post';
import { PostType } from 'consts';
import UploadVideoStore from 'stores/upload-video';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';

const UploadVideo = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();

  const videoStore: UploadVideoStore = useStores().uploadVideoStore;

  useEffect(() => {
    if (!videoStore.video) {
      navigate(-1);
    }
  }, []);

  const mutation = useMutation(
    ({ type, content, files, settings }: any) =>
      createPost(type, content, files, settings),
    {
      onSuccess: () => {
        videoStore.clear();
        navigate(-1);
      },
    }
  );

  const upload = () => {
    mutation.mutate({
      type: PostType.Video,
      content: videoStore.caption,
      files: [
        {
          id: videoStore.video?.id,
          product_tags: videoStore.products
            .filter(({ name, url }) => name.length > 0 && url.length > 0)
            .map(({ name, url }) => ({ title: name, link: url, type: 2 })),
        },
      ],
      settings: videoStore.settings,
    });
  };

  return (
    <Layout>
      <div className="d-flex flex-column">
        <Header
          title="新規投稿"
          back
          bordered
          onBackClick={() => {
            if (location.pathname.endsWith('/upload-videos')) {
              videoStore.clear();
            }
            navigate(-1);
          }}
          rightComponent={
            <button
              className={styles.share}
              disabled={!videoStore.areProductTagsValid}
              onClick={() => {
                location.pathname.endsWith('/upload-video')
                  ? upload()
                  : navigate(-1);
              }}
            >
              {location.pathname.endsWith('/upload-video') ? 'シェア' : '完了'}
            </button>
          }
        />
        <video className={styles.video} autoPlay playsInline>
          {videoStore.video && (
            <source
              src={URL.createObjectURL(videoStore.video.file)}
              type="video/mp4"
            />
          )}
        </video>
        <Outlet />
      </div>
    </Layout>
  );
});

export default UploadVideo;
