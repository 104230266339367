import { useInfiniteQuery } from 'react-query';

import Header from 'components/header';
import Layout from 'components/layout';
import { getNotifications } from 'services/notification';
import { getNextPageParam, transformData } from 'utils/pagination';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmailItem from 'modules/my-page/components/email-item';

const Emails = () => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['notifications'],
    ({ pageParam = 1 }) => getNotifications(pageParam),
    {
      select(data) {
        return transformData(data, 'notifications');
      },
      getNextPageParam,
    }
  );

  return (
    <Layout bottomNavBar>
      <Header title="ENTAMAからのメール" back bordered />
      <InfiniteScroll
        dataLength={data?.pages.flat().length || 0}
        next={fetchNextPage}
        hasMore={hasNextPage || false}
        loader={null}
      >
        {data?.pages.flat().map((item) => (
          <EmailItem notification={item} />
        ))}
      </InfiniteScroll>
    </Layout>
  );
};

export default Emails;
