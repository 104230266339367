import icons from 'assets/images/';

import styles from './styles.module.scss';

interface IProps {
  user: any;
  addUserToComment: (name: string) => void;
}

const MentionItem = (props: IProps) => {
  const addUserToCommentHandler = (_event: any) => {
    props.addUserToComment(props.user.nick_name);
  };

  return (
    <div className={styles.container} onClick={addUserToCommentHandler}>
      <div className={styles.avatar}>
        <img
          src={`${
            !!props.user.avatar
              ? props.user.avatar.small
              : icons.imgDefaultAvatar
          }`}
          alt=""
        />
      </div>
      <div style={{ wordBreak: 'break-all' }}>
        <p style={{ fontWeight: 'bold' }}>{props.user.name}</p>
        <p style={{ color: '#707070' }}>{props.user.nick_name}</p>
      </div>
    </div>
  );
};

export default MentionItem;
