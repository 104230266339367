import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import copy from 'copy-to-clipboard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import {
  clickAffiliateLink,
  getProductDetails,
  saveProduct,
  unsaveProduct,
} from 'services/product';
import { ReactComponent as BookmarkUnsaved } from 'assets/svg/ic_svg_bookmark_unsaved_24dp.svg';
import { ReactComponent as BookmarkSaved } from 'assets/svg/ic_svg_bookmark_saved_24dp.svg';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import Layout from 'components/layout';
import icons from 'assets/images';
import Header from 'components/header';
import { showToast } from 'components/toastify';
import { TProduct } from 'types/product';
import SessionStore from 'stores/session';
import useLoginRequired from 'hooks/useLoginRequired';

import styles from './styles.module.scss';
import './styles.scss';

const ProductDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id ? parseInt(params.id) : null;
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const { data, refetch, isLoading } = useQuery<TProduct>(['product', id], () =>
    getProductDetails(+id!)
  );

  const saveMutation = useMutation(() => saveProduct(id!), {
    onSuccess: () => refetch(),
  });
  const unsaveMutation = useMutation(() => unsaveProduct(id!), {
    onSuccess: () => refetch(),
  });

  const { showLoginPopup } = useLoginRequired();

  useEffect(() => {
    if (!id) navigate(-1);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  const showActionSheet = () => {
    uiStore.showActionSheet([
      {
        title: 'ショップのURLをコピー',
        onPress: () => {
          copy(data!.url);
          showToast('リンクがクリップボードにコピーされました');
        },
      },
    ]);
  };

  const renderProduct = () => {
    return (
      <div className={styles.container}>
        <div className="product-detail-images">
          <Swiper
            pagination={{ type: 'fraction' }}
            navigation={true}
            loop={false}
            modules={[Pagination, Navigation]}
            className={styles.mySwiper}
            watchOverflow={true}
            speed={300}
            slidesPerView={1}
            spaceBetween={1}
          >
            {data!.images.map((image) => (
              <SwiperSlide key={image.id}>
                <img src={image.origin} loading="lazy" alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.productDetail}>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <div className={styles.name}>{data?.name}</div>
            <div
              onClick={() =>
                sessionStore.profile
                  ? data!.is_saved
                    ? unsaveMutation.mutate()
                    : saveMutation.mutate()
                  : showLoginPopup()
              }
            >
              {data!.is_saved ? (
                <BookmarkSaved fill="#3E3E3E" />
              ) : (
                <BookmarkUnsaved fill="#707070" />
              )}
            </div>
          </div>
          <div className={styles.price}>
            {data && data?.price != null ? `¥${data.price}` : null}
          </div>
          <div className={styles.description}>{data?.description}</div>
        </div>
        <div className={styles.goToShopBtn}>
          <button
            onClick={() => {
              clickAffiliateLink(data?.token || '');
              window.open(data?.url);
            }}
          >
            ウェブサイトで見る
          </button>
        </div>
      </div>
    );
    // }
  };

  useEffect(() => {
    return () => {
      uiStore.hideActionSheet();
      uiStore.hideAlertBox();
      uiStore.hideBottomSheet();
    };
  }, [uiStore]);

  return (
    <Layout bottomNavBar>
      <Header
        title=" "
        back
        rightComponent={
          <img
            src={icons.icThreeDots}
            style={{ width: 22 }}
            alt=""
            onClick={showActionSheet}
            loading="lazy"
          />
        }
      />
      <div style={{ minHeight: 'calc(100vh - 1.5rem - 33px)' }}>
        {data && renderProduct()}
      </div>
    </Layout>
  );
};

export default ProductDetail;
