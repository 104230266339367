import ReactSwitch, { ReactSwitchProps } from 'react-switch';

const Switch = (props: ReactSwitchProps) => {
  return (
    <ReactSwitch
      {...props}
      onColor="#D95454"
      offColor="#DDDBD7"
      uncheckedIcon={false}
      checkedIcon={false}
    />
  );
};

export default Switch;
