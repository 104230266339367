import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IProps {
  visible: boolean;
}

const LoadingSpinner = ({ visible }: IProps) => {
  return (
    <div className={classNames(styles.container, { 'd-none': !visible })}>
      <Spinner animation="border" className={styles.spinner} />
    </div>
  );
};

export default LoadingSpinner;
