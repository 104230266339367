import styles from './styles.module.scss';
import Guide from 'modules/guidelines/components/guide';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import './styles.scss';
import icons from '../../../../assets/images/index';
import WelcomeImage11 from '../../../../assets/images/welcome11.png';
import WelcomeImage12 from '../../../../assets/images/welcome12.png';
import WelcomeImage2 from '../../../../assets/images/welcome2.png';
import WelcomeImage3 from '../../../../assets/images/welcome3.png';

const GuideLines = () => {
  const arr = [
    <Guide
      title={{ img: icons.icUnion, text: '新たなSNS' }}
      images={[WelcomeImage11, WelcomeImage12]}
      content="ENTAMEはクリエイターがプロモートする「欲しい！」に出会えるSNSです。"
      start={false}
    />,
    <Guide
      title={{ img: icons.icGift, text: '商品購入' }}
      images={[WelcomeImage2]}
      content="「欲しい！」と思った商品はすぐに購入できます。"
      start={false}
    />,
    <Guide
      title={{ img: icons.icCoin, text: '収益獲得' }}
      images={[WelcomeImage3]}
      content="クリエイターはアフェリエイト収益が得られる。"
      start={true}
    />,
  ];

  return (
    <div className="guidelines-swiper-container">
      <div className={styles.clipBackground}></div>
      <div className={styles.main}>
        <header className={styles.welcomeText}>
          <h2>Welcome to</h2>
          <h1>ENTAMA</h1>
        </header>

        <Swiper
          pagination={{
            clickable: true,
          }}
          className={`${styles.content} `}
          slidesPerView={1}
          modules={[Pagination]}
          spaceBetween={250}
          speed={350}
        >
          {arr.map((guide, index) => {
            return (
              <SwiperSlide className={styles.swipeItem} key={index}>
                {guide}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default GuideLines;
