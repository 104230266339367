import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import Header from 'components/header';
import Layout from 'components/layout';
import UserList from 'components/user-list';
import useStores from 'hooks/useStores';
import { getFollowing } from 'services/user';
import { TUser } from 'types/user';
import { getNextPageParam, transformData } from 'utils/pagination';
import useUser from 'hooks/useUser';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';

const Following = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id ? parseInt(params.id) : null;

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const { data, fetchNextPage, hasNextPage, refetch, isLoading, isRefetching } =
    useInfiniteQuery<any, unknown, TUser[], any>(
      ['following', id],
      ({ pageParam }) => getFollowing(pageParam, id!),
      {
        enabled: false,
        select(data) {
          return transformData(data, 'users');
        },
        getNextPageParam,
      }
    );

  const { followMutation, unfollowMutation } = useUser(refetch);

  useEffect(() => {
    if (!id || isNaN(id)) {
      navigate(-1);
    } else {
      window.scrollTo({ top: 0 });
      refetch();
    }
  }, []);

  useEffect(() => {
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  return (
    <Layout bottomNavBar>
      <Header title="フォロー" bordered back />
      <div
        style={{
          minHeight: 'calc(100vh - 30px - 1.125rem * 1.5 - 1.5rem - 33px)',
        }}
      >
        <UserList
          users={data?.pages.flat() || []}
          loadMore={fetchNextPage}
          hasMore={hasNextPage}
          rightButton={(user: TUser) =>
            sessionStore.profile &&
            sessionStore.profile.id !== user.id && (
              <button
                className={user.is_followed ? 'followingBtn' : 'followBtn'}
                onClick={() =>
                  user.is_followed
                    ? unfollowMutation.mutate(user.id)
                    : followMutation.mutate(user.id)
                }
              >
                {user.is_followed ? 'フォロー中' : 'フォローする'}
              </button>
            )
          }
          isFetching={isRefetching}
        />
      </div>
    </Layout>
  );
};

export default Following;
