import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

interface IProps {
  notification: {
    id: number;
    created_at: string;
    title: string;
  };
}

const EmailItem = ({ notification }: IProps) => {
  const navigate = useNavigate();
  const { id, created_at, title } = notification;

  return (
    <div
      className={styles.emailItem}
      onClick={() => navigate(`/settings/email/${id}`)}
    >
      <div>{created_at}</div>
      <div>{title}</div>
    </div>
  );
};

export default EmailItem;
