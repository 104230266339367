import { CSSProperties } from 'react';

import Images from 'assets/images';

interface IProps {
  className?: any;
  style?: CSSProperties;
}

const UserIcon = ({ className, style }: IProps) => {
  return (
    <div
      className={className}
      style={{
        position: 'absolute',
        zIndex: 2,
        height: 25,
        width: 25,
        borderRadius: '50%',
        backgroundColor: '#000000b3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <img
        src={Images.icPerson}
        alt=""
        style={{ width: 8, height: 9, objectFit: 'contain' }}
      />
    </div>
  );
};

export default UserIcon;
