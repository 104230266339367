import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { loginSnsWebView } from 'services/auth';

import AuthScreenTemplate from 'modules/auth/components/template';
import AuthMethods from 'modules/auth/components/auth-methods';

import { WEBVIEW_MESSAGE } from 'consts';
import { isOnWebview } from 'services/webviewService';
import { useEffect } from 'react';

const LoginMethods = () => {
  const navigate = useNavigate();

  let provider: string;
  let token: string;
  let tokenSecret: string;

  const loginSnsWebViewMutation = useMutation(() => loginSnsWebView(provider, token, tokenSecret), {
    onSuccess: (data) => {
      if (data.data.is_registered) {
        navigate('/feed')
      } else {
        const origin = window.location.origin;
        const token: string = data.data.token || '';
        const name: string = data.data.name || '';
        const nickName: string = data.data.nick_name || '';
        const email: string = data.data.email || '';

        window.location.href = origin + '/registration' + '?token=' + token + '&name=' + name + '&nick_name=' + nickName + '&email=' + email;
      }
    },
  });

  useEffect(() => {
    // Cheat for iOS review
    if (isOnWebview()) {
      navigate('/login');
    }
  })

  return (
    <AuthScreenTemplate title="ログイン">
      <AuthMethods
        className="mt-5"
        onMailClick={() => navigate('/login')}
        handleSnsWebView={(channel: string, data: any) => {
          if (channel == WEBVIEW_MESSAGE.LOGIN_SNS_TWITTER) {
            provider = 'twitter';
          }
          if (channel == WEBVIEW_MESSAGE.LOGIN_SNS_LINE) {
            provider = 'line';
          }
          token = data.token;
          tokenSecret = data.tokenSecret || undefined;
          loginSnsWebViewMutation.mutate()
        }}
        type="login"
      />
    </AuthScreenTemplate>
  );
};

export default LoginMethods;
