import { useEffect, useRef, useState } from 'react';

interface IProps {
  nickname: string;
  onClick: () => void;
  x: number;
  y: number;
  leftOffset?: number;
  rightOffset?: number;
  topOffset?: number;
}

const UserTag = ({
  nickname,
  onClick,
  x,
  y,
  leftOffset = 0,
  rightOffset = window.innerWidth,
  topOffset,
}: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      if (topOffset && rect.top < topOffset) {
        setReverse(true);
      } else {
        if (rect.right > rightOffset) {
          ref.current.style.transform = `translateX(calc(${
            rightOffset - rect.right
          }px - 50% + 2.5px))`;
        } else if (rect.left < leftOffset) {
          ref.current.style.transform = `translateX(calc(${
            leftOffset - rect.left
          }px - 50% + 2.5px))`;
        } else {
          ref.current.style.transform = `translateX(calc(- 50% + 2.5px))`;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (reverse) {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.right > rightOffset) {
          ref.current.style.transform = `translateX(calc(${
            rightOffset - rect.right
          }px - 50% + 2.5px))`;
        } else if (rect.left < leftOffset) {
          ref.current.style.transform = `translateX(calc(${
            leftOffset - rect.left
          }px - 50% + 2.5px))`;
        } else {
          ref.current.style.transform = `translateX(calc(- 50% + 2.5px))`;
        }
      }
    }
  }, [reverse]);

  return !reverse ? (
    <div
      style={{
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
        transform: 'translateY(-2.05rem)',
      }}
      onClick={onClick}
    >
      <div
        className="image-tag"
        ref={ref}
        style={{
          transform: 'translateX(calc(-50% + 2.5px))',
        }}
      >
        {nickname}
      </div>
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderTop: '5px solid #000000b3',
        }}
      />
    </div>
  ) : (
    <div
      style={{
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
      }}
      onClick={onClick}
    >
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderBottom: '5px solid #000000b3',
        }}
      />
      <div
        className="image-tag"
        ref={ref}
        style={{
          transform: 'translateX(calc(-50% + 2.5px))',
        }}
      >
        {nickname}
      </div>
    </div>
  );
};

export default UserTag;
