import { Session } from 'inspector';
import { useNavigate } from 'react-router-dom';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import useStores from './useStores';

const useLoginRequired = () => {
  const sessionStore: SessionStore = useStores().sessionStore;
  const uiStore: UIStore = useStores().uiStore;
  const navigate = useNavigate();

  const showLoginPopup = () => {
    uiStore.showAlertBox({
      title: 'ログインしてください',
      content: 'アクションを実行する場合、ログインをする必要があります。',
      buttons: [
        { title: 'ログイン画面へ', onPress: () => navigate('/login-methods') },
      ],
    });
  };

  return { showLoginPopup };
};

export default useLoginRequired;
