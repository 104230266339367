import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useState } from 'react';

import Images from 'assets/images';
import useStores from 'hooks/useStores';
import useUser from 'hooks/useUser';
import UIStore from 'stores/ui';
import { TUser } from 'types/user';
import { createConversation, getConversation } from 'services/firebase';

import { formatNumberHuman } from 'utils/time';

import styles from './styles.module.scss';

interface IProps {
  user: TUser;
  refetch?: () => void;
  myId?: number;
}

const Profile = ({ user, refetch, myId }: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const isMe = user.id === myId;

  const [showFullName, setShowFullName] = useState(false);

  const { followMutation, unfollowMutation, unblockMutation } = useUser(
    refetch,
    user.nick_name
  );

  const goToChat = async () => {
    if (myId) {
      try {
        uiStore.showLoading();
        const conversation = await getConversation(myId, user.id);
        if (!conversation) {
          const conversationId = await createConversation(myId, user.id);
          uiStore.hideLoading();
          navigate(`/direct/${conversationId}`);
        } else {
          uiStore.hideLoading();
          navigate(`/direct/${conversation.id}`);
        }
      } catch (err) {
        uiStore.hideLoading();
        console.log('Error get/create conversation: ', err);
      }
    }
  };

  const renderTextWithLinks = (text:any) => {
    const urlPattern = /https?:\/\/[^\s]+/g;
    const urls = text.match(urlPattern);
    if (urls) {
      const segments = text.split(urlPattern);
      return segments.map((segment:any, index:number) => (
        <span key={index}>
          {segment}
          {index < urls.length && (
            <a href={urls[index]} target="_blank" rel="noopener noreferrer">
              {urls[index]}
            </a>
          )}
        </span>
      ));
    } else {
      return text;
    }
  };

  return (
    <div className={styles.container}>
      <div className="d-flex align-items-center mb-2">
        <img
          src={user.avatar?.medium || Images.imgDefaultAvatar}
          className={styles.avatar}
          alt=""
        />
        <div className="d-flex justify-content-between flex-grow-1">
          <div className={styles.stats}>
            <div>{formatNumberHuman(user.number_posts)}</div>
            <label>POSTS</label>
          </div>
          <div
            className={styles.stats}
            onClick={() => navigate(`/${user.id}/following`)}
          >
            <div>{formatNumberHuman(user.number_following)}</div>
            <label>FOLLOWING</label>
          </div>
          <div
            className={styles.stats}
            onClick={() => navigate(`/${user.id}/followers`)}
          >
            <div>{formatNumberHuman(user.number_followers)}</div>
            <label>FOLLOWERS</label>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.nameTop}>
          <div
            className={classNames(
              styles.name,
              showFullName ? '' : styles.hidden
            )}
            onClick={() => setShowFullName(!showFullName)}
          >
            {user.name}
          </div>
          {!isMe && myId && (
            <div className="d-flex">
              {user.is_blocked ? (
                <button
                  className={styles.unblockBtn}
                  onClick={() =>
                    uiStore.showAlertBox({
                      title: `${
                        user.nick_name.length > 15
                          ? user.nick_name.substring(0, 15) + '...'
                          : user.nick_name
                      }のブロックを解除しますか？`,
                      content: `${
                        user.nick_name.length > 15
                          ? user.nick_name.substring(0, 15) + '...'
                          : user.nick_name
                      }はENTAMAであなたにメッセージを送信できる他、あなたのプロフィール、投稿なども見ることができます。ブロック解除したことは相手には知らされません。`,
                      buttons: [
                        {
                          title: 'ブロックを解除',
                          isRed: true,
                          onPress: () => unblockMutation.mutate(user.id),
                        },
                        {
                          title: 'キャンセル',
                        },
                      ],
                    })
                  }
                >
                  ブロックを解除
                </button>
              ) : (
                <button
                  className={classNames(
                    styles.followBtn,
                    user.is_followed ? styles.unfollow : ''
                  )}
                  onClick={() => {
                    user.is_followed
                      ? unfollowMutation.mutate(user.id)
                      : followMutation.mutate(user.id);
                  }}
                >
                  {user.is_followed ? 'フォロー中' : 'フォローする'}
                </button>
              )}

              <button className={styles.chatBtn} onClick={goToChat}>
                メッセージ
              </button>
            </div>
          )}
        </div>
        <div className={styles.username}>{user.nick_name}</div>
      </div>
      <div className={styles.bio}>
        {user.biography?.split('\n').map((segment) => (
          <>
            {renderTextWithLinks(segment.trim())}
            <br />
          </>
        ))}
      </div>
    </div>
  );
};

export default Profile;
