import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import Switch from 'react-switch';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import Layout from 'components/layout';
import Header from 'components/header';
import Images from 'assets/images';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';
import useStores from 'hooks/useStores';
import { logout } from 'services/auth';
import { suspend } from 'services/user';
import { TUser } from 'types/user';
import { getMyProfile } from 'services/auth';
import { updateNotificationSetting } from 'services/notification';
import { queryClient } from 'utils/query-client';

import styles from './styles.module.scss';

const Settings = observer(() => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const query = useQuery<TUser>(['get-my-profile'], getMyProfile, {
    onSuccess: (data) => {
      sessionStore.setProfile(data);
    },
  });

  const logoutMutation = useMutation(logout, {
    onSuccess: () => {
      window.location.reload();
      // navigate('/login', { replace: true });
    },
  });

  const suspendMutation = useMutation(suspend, {
    onSuccess: (data) => {
      window.location.reload();
    },
  });

  const notificationMutation = useMutation(updateNotificationSetting, {
    onSuccess: () => queryClient.invalidateQueries('get-my-profile'),
  });

  useEffect(() => {
    if (query.isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [query.isLoading]);

  const logOut = () => {
    uiStore.showAlertBox({
      title: 'ログアウトします、\nよろしいでしょうか。',
      buttons: [
        {
          title: 'ログアウト',
          onPress: () => {
            sessionStore.setProfile(null);
            logoutMutation.mutate();
          },
          isRed: true,
        },
        {
          title: 'キャンセル',
        },
      ],
    });
  };

  const suspendAccount = () => {
    uiStore.showAlertBox({
      title: 'ENTAMAを退会しますか？',
      content:
        'ENTAMAを退会された場合、今までのアカウントの全ての情報が消去されます。',
      buttons: [
        {
          title: '退会する',
          isRed: true,
          onPress: () => suspendMutation.mutate(),
        },
        {
          title: 'キャンセル', // cancel
        },
      ],
    });
  };

  return (
    <Layout bottomNavBar>
      <Header title="設定" back bordered />
      <div className={classNames('d-flex flex-column', styles.container)}>
        <div
          className={classNames(
            'd-flex justify-content-between',
            styles.switch
          )}
        >
          <div>通知</div>
          <Switch
            checked={query.data?.setting.notification || false}
            onChange={(checked) => notificationMutation.mutate(checked)}
            onColor="#D95454"
            offColor="#DDDBD7"
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>
        <button onClick={() => navigate('/settings/block-list')}>
          {/* block list */}
          ブロックしたアカウント
          <img src={Images.icNext} alt="" />
        </button>

        <button onClick={() => navigate('/settings/security')}>
          {/* security */}
          セキュリティ
          <img src={Images.icNext} alt="" />
        </button>

        <button onClick={() => navigate('/settings/account')}>
          {/* account */}
          アカウント
          <img src={Images.icNext} alt="" />
        </button>

        <button onClick={() => navigate('/policies')}>
          {/* terms& policies */}
          規約一覧
          <img src={Images.icNext} alt="" />
        </button>

        <button onClick={suspendAccount}>
          {/* leave entama */}
          ENTAMAを退会
          <img src={Images.icNext} alt="" />
        </button>

        <button onClick={logOut}>
          {/* log out */}
          ログアウト
          <img src={Images.icNext} alt="" />
        </button>
      </div>
    </Layout>
  );
});

export default Settings;
