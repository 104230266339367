import classNames from 'classnames';

import TextInput from 'components/text-input';

import './styles.scss';

interface IProps {
  required?: boolean;
  label: string;
  placeholder?: string;
  note?: string;
  className?: any;
  value?: string;
  onChange?: (text: string) => void;
  type?: 'text' | 'password';
  pattern?: string;
  side?: string;
}

const TextInputField = ({
  required = false,
  label,
  note,
  placeholder,
  className,
  value,
  onChange,
  type = 'text',
  pattern,
  side,
}: IProps) => {
  return (
    <div className={classNames('w-100 component-text-input-field', className)}>
      <div className="d-flex flex-row align-items-center top-container">
        {required && side === 'left' && (
          <div
            className={`d-flex flex-row justify-content-center align-items-center required`}
            style={{ margin: '0 0.5em 0 0' }}
          >
            必須
          </div>
        )}
        <div className="label">{label}</div>
        {required && side === 'right' && (
          <div
            className={`d-flex flex-row justify-content-center align-items-center required`}
            style={{ margin: '0 0 0 0.5em' }}
          >
            必須
          </div>
        )}
      </div>
      <TextInput
        placeholder={placeholder || ''}
        type={type}
        pattern={pattern}
        value={value}
        onChange={(text) => onChange && onChange(text)}
        required={required}
      />
      <div className="note">{note}</div>
    </div>
  );
};

export default TextInputField;
