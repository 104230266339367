import { useRef, useEffect, CSSProperties } from 'react';
import { useMutation, useQuery } from 'react-query';

import UploadOptions from 'components/upload-options';
import CreatorSns from 'components/creator-sns';
import Images from 'assets/images';
import useForceUpdate from 'hooks/useForceUpdate';
import useBackdropPress from 'hooks/useBackdropPress';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';
import { UserRole, VerificationStatus } from 'consts';
import { uploadFiles } from 'services/file';
import { checkCreatorVerificationStatus } from 'services/user';

import styles from './styles.module.scss';
import useLoginRequired from 'hooks/useLoginRequired';

interface IProps {
  style?: CSSProperties;
}

const UploadOptionsButton = ({ style }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const modalRef = useRef(null);
  const heightRef = useRef(0);
  const exceptRef = useRef(null);
  const inputRef = useRef<any>(null);

  const forceUpdate = useForceUpdate();
  useBackdropPress(
    modalRef,
    () => {
      let element = document.getElementById('upload-menu');
      if (!element?.classList.contains('d-none')) {
        element?.classList.add('d-none');
      }
    },
    exceptRef
  );

  const { showLoginPopup } = useLoginRequired();

  useEffect(() => {
    const div = document.getElementById('anhanq');
    const nav = document.querySelector('nav');
    if (div && nav) {
      heightRef.current = (nav.offsetHeight - div.offsetHeight) / 2;
      forceUpdate();
    }
  }, []);

  const uploadMutation = useMutation<unknown, unknown, File, unknown>(
    (file) => uploadFiles([file]),
    {
      onSuccess: (data: any) => {
        uiStore.setVerificationDocument(data.data[0].id);
        uiStore.showAlertBox({
          title: 'アップロードが完了しました',
          content:
            '本人確認書類のアップロードが完了しました。問題なければ次に必要情報を入力いただきます。',
          buttons: [
            {
              title: '次へ',
              isRed: true,
              onPress: () =>
                uiStore.showAlertBox({
                  title: 'クリエイター確認情報を入力してください。',
                  contentComponent: <CreatorSns />,
                }),
            },
            {
              title: 'アップロードしなおす',
              onPress: () => inputRef.current.click(),
            },
          ],
        });
      },
    }
  );

  const checkVerificationStatusQuery = useQuery(
    'check-verification-status',
    checkCreatorVerificationStatus,
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.status === VerificationStatus.NotSubmitted) {
          uiStore.showAlertBox({
            title: 'クリエイター審査がまだ 完了していません。',
            content:
              '本アプリではクリエイター以外投稿ができない仕様となっております。あなたはクリエイター審査が完了していないので投稿ができません。投稿をしたい場合クリエイター審査に進んでください。',
            buttons: [
              {
                title: '審査する',
                isRed: true,
                onPress: () =>
                  uiStore.showAlertBox({
                    title: '本人確認書類をアップロード してください。',
                    contentComponent: (
                      <div className={styles.popupVerification}>
                        <p>
                          本人確認書類から1つお選びいただいたうえで写真のアップロードをお願い致します。
                        </p>
                        <p>◎運転免許証</p>
                        <p>◎健康保険証</p>
                        <p>◎パスポート</p>
                        <p>◎マイナンバーカード</p>
                        <p>◎その他本人確認が可能な書類（在留カード）</p>
                      </div>
                    ),
                    buttons: [
                      {
                        title: '写真をアップロード',
                        onPress: () => inputRef.current.click(),
                      },
                    ],
                  }),
              },
              {
                title: 'キャンセル',
              },
            ],
          });
        } else if (data.status === VerificationStatus.Waiting) {
          uiStore.showAlertBox({
            title: 'クリエイター審査中です',
            content:
              '確認が完了し次第、本サービスをご利用いただけますようになります。確認が完了しましたらメールにてお知らせ致します。',
            buttons: [
              {
                title: '閉じる',
              },
            ],
          });
        } else if (data.status === VerificationStatus.Verified) {
          toggleUploadMenu();
        }
      },
    }
  );

  const toggleUploadMenu = () => {
    let element = document.getElementById('upload-menu');
    if (element?.classList.contains('d-none')) {
      element.classList.remove('d-none');
    } else {
      element?.classList.add('d-none');
    }
  };

  return (
    <div
      id="anhanq"
      className="d-flex align-items-center justify-content-center"
      style={{ position: 'relative', zIndex: 1, ...style }}
    >
      <img
        src={Images.icPlus}
        ref={exceptRef}
        alt=""
        className={styles.plusIcon}
        onClick={() => {
          if (sessionStore.profile) {
            if (sessionStore.profile.role === UserRole.Creator)
              toggleUploadMenu();
            else checkVerificationStatusQuery.refetch();
          } else showLoginPopup();
        }}
      />
      <UploadOptions modalRef={modalRef} heightRef={heightRef} />
      <input
        className="d-none"
        ref={inputRef}
        type="file"
        accept="image/*"
        multiple
        onChange={(event) => {
          if (event.target.files && event.target.files?.length > 0) {
            uploadMutation.mutate(event.target.files[0]);
          }
        }}
      />
    </div>
  );
};

export default UploadOptionsButton;
