import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import CheckBox from 'components/check-box';
import TextInput from 'components/text-input';
import AuthScreenTemplate from 'modules/auth/components/template';
import { getMyProfile, getXSRFToken, login } from 'services/auth';
import SessionStore from 'stores/session';
import useStores from 'hooks/useStores';
import { LocalStorageKey } from 'consts';

import styles from './styles.module.scss';

const Login = () => {
  const sessionStore: SessionStore = useStores().sessionStore;

  const navigate = useNavigate();

  const [email, setEmail] = useState(
    localStorage.getItem(LocalStorageKey.Email) || ''
  );
  const [password, setPassword] = useState(
    localStorage.getItem(LocalStorageKey.Password) || ''
  );
  const [saveEmail, setSaveEmail] = useState(
    !!localStorage.getItem(LocalStorageKey.Email)
  );
  const [savePassword, setSavePassword] = useState(
    !!localStorage.getItem(LocalStorageKey.Password)
  );

  useQuery('xsrf', getXSRFToken);

  const loginMutation = useMutation(() => login(email, password), {
    onSuccess: () => {
      if (saveEmail) localStorage.setItem(LocalStorageKey.Email, email);
      else localStorage.removeItem(LocalStorageKey.Email);
      if (savePassword)
        localStorage.setItem(LocalStorageKey.Password, password);
      else localStorage.removeItem(LocalStorageKey.Password);

      profileQuery.refetch();
    },
  });

  const profileQuery = useQuery(['get-my-profile'], getMyProfile, {
    enabled: false,
    onSuccess: (data) => {
      sessionStore.setProfile(data);
      navigate('/feed');
    },
  });

  return (
    <AuthScreenTemplate title="ログイン">
      <div className="d-flex flex-column align-items-center">
        <div className="w-100 mt-5">
          <TextInput
            placeholder="メールアドレス"
            value={email}
            onChange={(text) => setEmail(text)}
          />
          <CheckBox
            className={styles.checkBox}
            label="電話番号/ログインIDを保存する"
            value={saveEmail}
            toggle={(checked) => setSaveEmail(checked)}
          />
          <TextInput
            placeholder="パスワード"
            value={password}
            onChange={(text) => setPassword(text)}
            type="password"
          />
          <CheckBox
            className={styles.checkBox}
            label="パスワード保存する"
            value={savePassword}
            toggle={(checked) => setSavePassword(checked)}
          />
        </div>
        <button
          className={styles.submit}
          disabled={email.length <= 0 || password.length <= 0}
          onClick={() => loginMutation.mutate()}
        >
          ログイン
        </button>
        <button
          className={styles.smallButton}
          onClick={() => navigate('/registration-methods')}
        >
          新規アカウントを作成される方
        </button>
        <button
          className={styles.smallButton}
          onClick={() => navigate('/reset-password')}
        >
          パスワードをお忘れの方
        </button>
      </div>
    </AuthScreenTemplate>
  );
};

export default Login;
