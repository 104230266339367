import { observer } from 'mobx-react';

import TextInputField from 'components/text-input-field';
import useStores from 'hooks/useStores';
import UploadVideoStore from 'stores/upload-video';

import styles from './styles.module.scss';
import { urlValidation } from 'utils/validation';

const TagProducts = observer(() => {
  const videoStore: UploadVideoStore = useStores().uploadVideoStore;

  const renderItem = (index: number) => {
    return (
      <div className={styles.item} key={index}>
        <div className={styles.mark}>商品{index + 1}</div>
        <TextInputField
          label="タグ名"
          placeholder="タグ名を入力してください"
          value={videoStore.products[index].name}
          onChange={(text: string) => {
            const newProducts = [...videoStore.products];
            newProducts[index].name = text;
            videoStore.setProducts(newProducts);
            videoStore.setAreProductTagsValid();
          }}
        />
        {videoStore.products[index].name.length > 15 && (
          <p className={styles.error}>タグ名は15文字以内で入力してください。</p>
        )}
        <TextInputField
          label="商品URL"
          placeholder="商品ページのURLを入力してください"
          value={videoStore.products[index].url}
          onChange={(text: string) => {
            const newProducts = [...videoStore.products];
            newProducts[index].url = text;
            videoStore.setProducts(newProducts);
            videoStore.setAreProductTagsValid();
          }}
        />
        {!urlValidation(videoStore.products[index].url) && (
          <p className={styles.error}>無効なURLです。</p>
        )}
      </div>
    );
  };

  return (
    <form className={styles.container}>
      <div className={styles.header}>商品URLの追加</div>
      {new Array(4).fill(0).map((_, index) => renderItem(index))}
    </form>
  );
});

export default TagProducts;
