import { useEffect } from 'react';

const useBackdropPress = (
  containerRef: any,
  onBackdropPress: any,
  exceptRef?: any
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !exceptRef?.current.contains(event.target) &&
        onBackdropPress
      ) {
        onBackdropPress();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);
};

export default useBackdropPress;
