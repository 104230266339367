import { useMutation } from 'react-query';

import { block, follow, unblock, unfollow, reportUser } from 'services/user';
import UIStore from 'stores/ui';
import useStores from './useStores';
import Report from 'components/report-modal';
import ReportSuccess from 'components/report-success-modal';
import { showToast } from 'components/toastify';

const useUser = (onSuccess?: () => void, username?: string) => {
  const uiStore: UIStore = useStores().uiStore;

  const followMutation = useMutation((id: number) => follow(id), {
    onSuccess: () => onSuccess && onSuccess(),
  });

  const unfollowMutation = useMutation((id: number) => unfollow(id), {
    onSuccess: () => onSuccess && onSuccess(),
  });

  const blockMutation = useMutation((id: number) => block(id), {
    onSuccess: () => {
      onSuccess && onSuccess();
      if (username)
        showToast(
          `${
            username.length > 15 ? username.substring(0, 15) + '...' : username
          }をブロックしました`
        );
    },
  });

  const unblockMutation = useMutation((id: number) => unblock(id), {
    onSuccess: () => {
      onSuccess && onSuccess();
      if (username)
        showToast(
          `${
            username.length > 15 ? username.substring(0, 15) + '...' : username
          }のブロックを解除しました`
        );
    },
  });

  const reportMutation = useMutation<
    any,
    unknown,
    { id: number; reason: string },
    unknown
  >(({ id, reason }) => reportUser(id, reason), {
    onSuccess: () => {
      uiStore.showBottomSheet(<ReportSuccess />);
    },
  });

  const report = (id: number) => {
    uiStore.showBottomSheet(
      <Report
        onSelect={(reason) => {
          uiStore.hideBottomSheet();
          reportMutation.mutate({ id, reason });
        }}
      />
    );
  };

  return {
    followMutation,
    unfollowMutation,
    blockMutation,
    unblockMutation,
    report,
  };
};

export default useUser;
