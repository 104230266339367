import { useState } from 'react';
import { useMutation } from 'react-query';

import Header from 'components/header';
import Layout from 'components/layout';
import TextInputField from 'components/text-input-field';
import { changePassword } from 'services/user';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';
import { PASSWORD_REGEX } from 'consts';

const ChangePassword = () => {
  const uiStore: UIStore = useStores().uiStore;

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const mutation = useMutation(
    () => changePassword(oldPassword, newPassword, confirmPassword),
    {
      onSuccess: () => {
        uiStore.showAlertBox({
          title: 'パスワードが変更されました',
          content:
            'パスワードの変更が完了しました。以前使用していたパスワードでログインしている端末はすべてログアウトされます。',
          buttons: [
            { title: 'キャンセル', onPress: () => window.location.reload() },
          ],
          onBackdropPress: () => window.location.reload(),
        });
      },
    }
  );

  const onChangePassword = async (event: any) => {
    event.preventDefault();
    if (!PASSWORD_REGEX.test(newPassword)) {
      uiStore.showAlertBox({
        title: ' ※無効なパスワードです。',
        buttons: [
          {
            title: 'キャンセル',
          },
        ],
      });
    } else if (newPassword !== confirmPassword) {
      uiStore.showAlertBox({
        title: '※パスワードが一致しませんでした。',
        buttons: [
          {
            title: 'キャンセル',
          },
        ],
      });
    } else {
      mutation.mutate();
    }
  };

  return (
    <Layout bottomNavBar>
      <Header title="パスワード変更" back bordered />
      <form className={styles.form} onSubmit={onChangePassword}>
        <p>
          パスワードは、数字とアルファベットにて6文字以上32文字以内にて入力ください。
        </p>

        <TextInputField
          label="現在のパスワード"
          type="password"
          value={oldPassword}
          onChange={(text) => setOldPassword(text)}
        />
        <TextInputField
          label="新しいパスワード"
          type="password"
          value={newPassword}
          onChange={(text) => setNewPassword(text)}
        />
        <TextInputField
          label="新しいパスワード（確認）"
          type="password"
          value={confirmPassword}
          onChange={(text) => setConfirmPassword(text)}
        />
        <div className={styles.button}>
          <button
            type="submit"
            disabled={!oldPassword || !newPassword || !confirmPassword}
          >
            決定
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default ChangePassword;
