import Images from 'assets/images';

import styles from './styles.module.scss';

interface IProps {
  onUnhide: () => void;
}

const EmptyPost = ({ onUnhide }: IProps) => {
  return (
    <div className={styles.emptyContainer}>
      <div className={styles.checked}>
        <img src={Images.icChecked} alt="" />
      </div>
      <div className={styles.text}>この投稿は非表示になりました。</div>
      <button onClick={onUnhide}>取り消す</button>
    </div>
  );
};

export default EmptyPost;
