import { action, makeObservable, observable } from 'mobx';

import { TConversation } from 'types/direct';

class DirectStore {
  @observable conversation: TConversation | null = null;

  constructor() {
    makeObservable(this);
  }

  @action setConversation(conversation: TConversation | null) {
    this.conversation = conversation;
  }
}

export default DirectStore;
