import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'consts';
import 'moment/locale/ja';

moment.locale('ja');

export const formatMessageTime = (timestamp: number) => {
  const isSame = moment().isSame(moment.unix(timestamp / 1000), 'day');
  if (isSame) {
    const time = moment.unix(timestamp / 1000);
    return { time: time.format(TIME_FORMAT) };
  }
  return {
    date: moment.unix(timestamp / 1000).format(DATE_FORMAT),
    time: moment.unix(timestamp / 1000).format(TIME_FORMAT),
  };
};

export const dateConverter = (time: string) => {
  // convert UTC to local
  var utcTime = moment.utc(time).toDate();
  var localTime = moment(utcTime).local().format('YYYY-MM-DD HH:mm:ss');

  let date = new Date(localTime);
  let timestamp = date.getTime();
  var monthsAgo = new Date().getTime() - 26 * 24 * 60 * 60 * 1000;

  if (timestamp < monthsAgo) {
    return moment(localTime).format('YYYY年MM月DD日');
  } else {
    return moment(localTime).fromNow();
  }
};


export const formatNumberHuman = (number: any) => {
  const value = parseInt(number);

  if (value < 10000) {
    return value;
  }

  if (value < 1000000) {
    if (value % 1000 < 100) {
      return (value / 1000).toFixed(0) + 'k';
    }
    return (value / 1000).toFixed(1) + 'k';
  }

  if (value < 1000000000) {
    if (value % 1000000 < 100000) {
      return (value / 1000000).toFixed(0) + 'm';
    }
    return (value / 1000000).toFixed(1) + 'm';
  }

  return value;
}
