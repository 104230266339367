import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';

import Layout from 'components/layout';
import UserList from 'components/user-list';
import LoadingSpinner from 'components/inner-loading-spinner';
import { searchFreeWord, searchUsers } from 'services/user';
import { TUser } from 'types/user';
import useUser from 'hooks/useUser';
import { getNextPageParam, transformData } from 'utils/pagination';
import { queryClient } from 'utils/query-client';
import { TPost } from 'types/post';
import Images from 'assets/images';
import { PostType } from 'consts';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';

const DiscoverSearch = () => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const myId = sessionStore.profile?.id;

  const [keyword, setKeyword] = useState('');
  const [activeTab, setActiveTab] = useState<'creator' | 'freeword'>('creator');

  const userQuery = useInfiniteQuery<any, Error, Array<TUser>, any>(
    ['creator', keyword],
    ({ pageParam }) => searchUsers(pageParam, keyword),
    {
      select(data) {
        return transformData(data, 'users');
      },
      getNextPageParam,
    }
  );

  const freeWordQuery = useInfiniteQuery<
    Array<{ type: 1 | 2; object: TPost | TUser }>
  >(
    ['freeword', keyword],
    ({ pageParam }) => searchFreeWord(pageParam, keyword),
    {
      select(data) {
        return transformData(data, 'results');
      },
      getNextPageParam,
    }
  );

  const { followMutation, unfollowMutation } = useUser(() => {
    queryClient.invalidateQueries(['creator', keyword]);
    queryClient.invalidateQueries(['freeword', keyword]);
  });

  useEffect(() => {
    if (freeWordQuery.isLoading || userQuery.isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [freeWordQuery.isLoading, userQuery.isLoading]);

  return (
    <Layout bottomNavBar>
      <div className={styles.header} style={{paddingTop: uiStore.appStatusBarHeight}}>
        <div className={styles.top}>
          <input
            type="text"
            placeholder="検索"
            autoFocus
            value={keyword}
            onChange={(event) => setKeyword(event.target.value)}
          />
          <button onClick={() => navigate(-1)}>
            <p>キャンセル</p>
          </button>
        </div>
        <div className={styles.nav}>
          <button
            className={activeTab === 'creator' ? styles.active : ''}
            onClick={() => {
              if (activeTab !== 'creator') {
                setActiveTab('creator');
              }
            }}
          >
            アカウント
          </button>
          <button
            className={activeTab === 'freeword' ? styles.active : ''}
            onClick={() => {
              if (activeTab !== 'freeword') {
                setActiveTab('freeword');
              }
            }}
          >
            フリーワード
          </button>
        </div>
      </div>
      <div className={activeTab !== 'creator' ? 'd-none' : ''}>
        <InfiniteScroll
          dataLength={userQuery.data?.pages.flat().length || 0}
          next={userQuery.fetchNextPage}
          hasMore={userQuery.hasNextPage || false}
          loader={null}
        >
          <UserList
            users={userQuery.data?.pages.flat() || []}
            rightButton={(user) =>
              myId &&
              user.id !== myId && (
                <div
                  className={user.is_followed ? 'followingBtn' : 'followBtn'}
                  onClick={() => {
                    if (!user.is_followed) {
                      followMutation.mutate(user.id);
                    } else unfollowMutation.mutate(user.id);
                  }}
                >
                  {user.is_followed ? 'フォロー中' : 'フォローする'}
                </div>
              )
            }
            isFetching={userQuery.isRefetching}
          />
        </InfiniteScroll>
      </div>
      <div className={activeTab !== 'freeword' ? 'd-none' : ''}>
        <InfiniteScroll
          dataLength={freeWordQuery.data?.pages.flat().length || 0}
          next={freeWordQuery.fetchNextPage}
          hasMore={freeWordQuery.hasNextPage || false}
          loader={null}
        >
          {freeWordQuery.data?.pages.flat().map((item) => {
            const user = item.object as TUser;
            const post = item.object as TPost;
            return (
              <div className={styles.freeWordItem}>
                {item.type === 1 && (
                  <>
                    <div
                      className={styles.left}
                      onClick={() => navigate(`/${user.id}`)}
                    >
                      <img
                        className={styles.avatar}
                        src={user.avatar?.small || Images.imgDefaultAvatar}
                        alt=""
                      />
                      <div className={styles.nameContainer}>
                        <div>{user.name}</div>
                        <div>{user.nick_name}</div>
                      </div>
                    </div>
                    {sessionStore.profile && (
                      <button
                        className={
                          user.is_followed ? 'followingBtn' : 'followBtn'
                        }
                        onClick={() =>
                          user.is_followed
                            ? unfollowMutation.mutate(user.id)
                            : followMutation.mutate(user.id)
                        }
                      >
                        {user.is_followed ? 'フォロー中' : 'フォローする'}
                      </button>
                    )}
                  </>
                )}
                {item.type === 2 && (
                  <>
                    <div
                      className={styles.left}
                      onClick={() => navigate(`/posts/${post.id}`)}
                    >
                      <img
                        className={styles.avatar}
                        src={
                          post.creator.avatar?.small || Images.imgDefaultAvatar
                        }
                        alt=""
                      />
                      <div>{post.creator.name}の投稿</div>
                    </div>
                    {post.type === PostType.Image ? (
                      <img
                        src={post.files[0].medium}
                        alt=""
                        className={styles.post}
                      />
                    ) : post.files[0].small ? (
                      <img
                        src={post.files[0].small}
                        alt=""
                        className={styles.post}
                      />
                    ) : (
                      <video
                        preload="metadata"
                        className={styles.post}
                        playsInline
                      >
                        <source src={post.files[0].origin} type="video/mp4" />
                      </video>
                    )}
                  </>
                )}
              </div>
            );
          })}
          <LoadingSpinner visible={freeWordQuery.isRefetching} />
        </InfiniteScroll>
      </div>
    </Layout>
  );
};

export default DiscoverSearch;
