import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PlayIcon } from 'assets/svg/ic_play_arrow_white_48dp.svg';
import Images from 'assets/images/';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import { TPost } from 'types/post';

import styles from './styles.module.scss';
import { useVideoWatcher } from 'hooks/use-video-watcher';

import ReactPlayer from 'react-player/vimeo';
interface IProps {
  post: TPost;
  invalidateQueryKey?: Array<string | number>;
}

const VideoPostItemMini = ({ post, invalidateQueryKey }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;
  const navigate = useNavigate();

  const videoRef = useRef<any>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const pause = () => {
    setIsPlaying(false);
    if (typeof videoRef.current.pause === 'function') {
      videoRef.current.pause();
    }

    if (typeof videoRef.current.getInternalPlayer === 'function') {
      if (videoRef.current.getInternalPlayer()) {
        videoRef.current.getInternalPlayer().pause();
      }
    }
  };

  const play = () => {
    setIsPlaying(true);
    if (typeof videoRef.current.play === 'function') {
      videoRef.current.play();
    }

    if (typeof videoRef.current.getInternalPlayer === 'function') {
      if (videoRef.current.getInternalPlayer()) {
        videoRef.current.getInternalPlayer().play();
      }
    }
  };

  // pause video when out of screen.
  useVideoWatcher({ target: videoRef, pause, play });

  useEffect(() => {
    return () => {
      uiStore.setIsReadingVideoCaption(false);
    };
  }, []);

  return (
    <div className={styles.container}>
      {post.files[0].small ? (
        <img
          className={styles.videoThumbnail}
          key={post.id}
          src={post.files[0].small}
          alt=""
          onClick={() => navigate(`/posts/${post.id}`)}
        />
      ) :
        (post.files[0].hls ? (
          <ReactPlayer
            ref={videoRef}
            url={post.files[0].hls}
            controls={false}
            playsinline={true}
            config={{
              playerOptions: {
                loop: true,
              },
            }}
            onClick={() => navigate(`/posts/${post.id}`)}
          />
        ) : (
          <video playsInline preload="metadata" ref={videoRef} onClick={() => navigate(`/posts/${post.id}`)}>
            <source src={post.files[0].origin} type="video/mp4" />
          </video>
        ))
      }
      {!isPlaying && <PlayIcon className={styles.playIcon} onClick={play} />}
      <div className={styles.infoContainer}>
        <div>
          <div
            className={styles.userInfo}
            onClick={() => navigate(`/${post.creator.id}#movies`)}
          >
            <img
              src={post.creator.avatar?.small || Images.imgDefaultAvatar}
              alt=""
            />
            <div className={styles.userNames}>
              <div>{post.creator.name}</div>
              <div>{post.creator.nick_name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPostItemMini;
