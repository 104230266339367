import { LIMIT, PAGE } from 'consts';
import { get, post } from 'services';

export const searchUsers = (page: number, keyword: string = '') => {
  return get('api/user/search', {
    limit: LIMIT,
    page,
    keyword,
  });
};

export const searchCreators = (page: number = PAGE, keyword: string = '') => {
  return get('api/user/search', {
    limit: LIMIT,
    page,
    type: 2,
    keyword,
  });
};

export const getProfile = (userId: number) => {
  return get('api/user/profile', { user_id: userId });
};

export const follow = (userId: number) => {
  return post('api/user/follow', {
    user_id: userId,
    type: 1,
  });
};

export const unfollow = (userId: number) => {
  return post('api/user/follow', {
    user_id: userId,
    type: 2,
  });
};

export const getFollowing = (page: number = PAGE, userId?: number) => {
  const params = {
    limit: LIMIT,
    page,
    following: 1,
  };
  return get(
    'api/user/search',
    userId ? { ...params, user_id: userId } : params
  );
};

export const getFollowers = (page: number = PAGE, userId?: number) => {
  const params = {
    limit: LIMIT,
    page,
    follower: 1,
  };
  return get(
    'api/user/search',
    userId ? { ...params, user_id: userId } : params
  );
};

export const block = (userId: number) => {
  return post('api/user/block', {
    user_id: userId,
    type: 1,
  });
};

export const unblock = (userId: number) => {
  return post('api/user/block', {
    user_id: userId,
    type: 2,
  });
};

export const reportUser = (userId: number, reason: string) => {
  return post('api/user/report', {
    user_id: userId,
    reason,
  });
};

export const getBlockList = async (page: number) => {
  const data = await get('api/user/search', {
    limit: LIMIT,
    page,
    blocked: 1,
  });

  return data;
};

export const changeAvatar = (id: number) => {
  return post('api/user/avatar/change', {
    file_id: id,
  });
};

export const uploadCreatorProfile = (data: any) => {
  return post('api/user/creator/profile/update', data);
};

export const uploadCreatorBankInfo = (data: any) => {
  return post('api/user/creator/bank/update', data);
};

export const updateProfile = (
  email: string,
  name: string,
  nick_name: string,
  biography: string
) => {
  return post('api/user/profile/update', {
    email,
    name,
    nick_name,
    biography,
  });
};

export const getActivities = (page: number, type: string) => {
  return get('api/user/activity/search', {
    limit: 20,
    page,
    type,
  });
};

export const getNumberUnreadActivities = () => {
  return get('api/user/activity/number-unread', {});
};

export const markAllReadActivities = () => {
  return post('api/user/activity/mark-all-read', {});
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
) => {
  return post('api/user/password/change', {
    old_password: oldPassword,
    new_password: newPassword,
    new_password_confirmation: confirmPassword,
  });
};

export const suspend = () => {
  return post('api/user/leave', {});
};

export const searchFreeWord = (page: number, keyword: string = '') => {
  return get('api/discover/search', {
    limit: LIMIT,
    page,
    keyword,
  });
};

export const checkCreatorVerificationStatus = () => {
  return get('api/user/creator/status', {});
};

export const createMessage = (
  user_id: number,
  type: number,
  content: string,
  files: any
) => {
  return post('api/user/conversation/message/create', {
    user_id,
    type,
    content,
    files,
  });
};
