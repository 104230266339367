import { useNavigate } from 'react-router-dom';
import TextArea from 'react-textarea-autosize';
import { observer } from 'mobx-react';

import Images from 'assets/images';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';
import EditVideoStore from 'stores/edit-video';

const EditVideoOptions = observer(() => {
  const navigate = useNavigate();
  const editVideoStore: EditVideoStore = useStores().editVideoStore;

  return (
    <div className={styles.mainContainer}>
      <div onClick={() => navigate('tags')}>
        商品URLの追加
        <img src={Images.icNext} alt="" />
      </div>

      <TextArea
        placeholder="キャプションを書く"
        value={editVideoStore.content}
        onChange={(event) => editVideoStore.setContent(event.target.value)}
      />
    </div>
  );
});

export default EditVideoOptions;
