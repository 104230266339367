import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import AppBar from 'components/app-bar';
import Layout from 'components/layout';
import UploadOptionsButton from 'components/upload-options-button';
import Profile from '../../components/profile';
import ProfilePosts from '../../components/profile-posts';
import { getProfile } from 'services/user';
import Images from 'assets/images';
import useStores from 'hooks/useStores';
import useUser from 'hooks/useUser';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';
import { UserRole } from 'consts';
import PullToRefresh from 'react-simple-pull-to-refresh';

import { AFFILIATE_URL } from 'config';

import styles from './styles.module.scss';
import { isOnWebview } from 'services/webviewService';

const UserProfile = () => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const params = useParams();
  const id = params.id ? parseInt(params.id) : null;
  const isMe = sessionStore.profile?.id === id;

  const profilePostRef = useRef();
  const [profilePostsKey, setProfilePostsKey] = useState(1);

  const { report } = useUser();

  const { data, refetch, isLoading } = useQuery(
    ['profile', id],
    () => getProfile(id!),
    {
      enabled: false,
    }
  );

  const { blockMutation } = useUser(refetch, data?.nick_name);

  const refeshPage = () => {

    const tasks = [refetch()];
    if(profilePostRef && profilePostRef.current) {
      tasks.push((profilePostRef.current as any).refreshPosts());
    }

    return Promise.all(tasks)
  }

  useEffect(() => {
    if (!id || isNaN(id)) {
      navigate(-1);
    } else refetch();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  const showAffiliateAlert = () => {
    uiStore.showAlertBox({
      title: '提携できるアフィリエイト案件を探しますか？',
      content:
        'クリエイターはアフィリエイト案件を受けて成果報酬を獲得することができます。ここから先は提携できるアフィリエイト案件を探しに行きます。',
      buttons: [
        {
          title: '案件を探す', isRed: true, onPress: () => {
            window.open(AFFILIATE_URL);
          }
        },
        { title: 'キャンセル' }
      ],
    });
  };

  const showBlockAlert = () => {
    uiStore.showAlertBox({
      title: `${data.nick_name.length > 15
        ? data.nick_name.substring(0, 15) + '...'
        : data.nick_name
        }をブロックしますか？`,
      content:
        'ブロックされた人はENTAMAであなたにメッセージを送信できなくなり、あなたのプロフィー ル、投稿なども見られなくなります。ブロックし たことは相手には知らされません。',
      buttons: [
        {
          title: 'ブロック',
          isRed: true,
          onPress: () => blockMutation.mutate(id!),
        },
        {
          title: 'キャンセル',
        },
      ],
    });
  };

  const showUserActions = () => {
    if (isMe)
      uiStore.showBottomSheet(
        <div className={styles.bottomSheet}>
          <div>
            <button
              onClick={() => {
                uiStore.hideBottomSheet();
                navigate('/settings');
              }}
            >
              設定
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                uiStore.hideBottomSheet();
                navigate('/saved');
              }}
            >
              保存済み
            </button>
          </div>
        </div>
      );
    else
      uiStore.showActionSheet([
        {
          title: 'ブロック',
          isRed: true,
          onPress: () => showBlockAlert(),
        },
        {
          title: '報告する',
          isRed: true,
          onPress: () => report(id!),
        },
      ]);
  };

  const renderMyProfileHeader = () => (
    <AppBar>
      {sessionStore.profile.role === UserRole.Creator && (
        <div className="d-flex align-items-center justify-content-center">
          <button className={styles.affiliate} onClick={showAffiliateAlert}>
            アフィリエイト
          </button>
        </div>
      )}
      <UploadOptionsButton style={{ marginRight: '1rem' }} />
      <div
        className="d-flex align-items-center justify-content-center"
        onClick={showUserActions}
      >
        <img src={Images.icMenu} alt="" className={styles.menuIcon} />
      </div>
    </AppBar>
  );

  const renderOtherProfileHeader = () => (
    // Only show the icon if the current user has logged in & doesn't block this user
    <AppBar>
      {sessionStore.profile && !data?.is_blocked && (
        <img
          src={Images.icThreeDots}
          style={{ width: 22 }}
          alt=""
          className={styles.menuIcon}
          onClick={showUserActions}
        />
      )}
    </AppBar>
  );
  return (
    <Layout bottomNavBar>

      <div
        style={{
          minHeight: 'calc(100vh - 1.5rem - 33px)',
        }}
      >
        {isMe ? renderMyProfileHeader() : renderOtherProfileHeader()}
        <PullToRefresh
          isPullable={isOnWebview()}
          onRefresh={refeshPage}
          pullingContent={""}
        >
          <div style={{ minHeight: "70vh" }}>
            {data && (
              <Profile
                user={data}
                refetch={refetch}
                myId={sessionStore.profile?.id}
              />
            )}
            {data && data.role === UserRole.Creator && (
              <ProfilePosts ref={profilePostRef} userId={id!} tabs={['images', 'movies', 'tags']} />
            )}
          </div>
        </PullToRefresh>
      </div>

    </Layout>
  );
};

export default UserProfile;
