import { action, makeObservable, observable } from 'mobx';

import { TPostSettings } from 'types/post';
import { DEFAULT_POST_SETTINGS } from 'consts';
import { urlValidation } from 'utils/validation';

const EMPTY_PRODUCTS = [
  { name: '', url: '' },
  { name: '', url: '' },
  { name: '', url: '' },
  { name: '', url: '' },
];

class UploadVideoStore {
  @observable isTagging: boolean = false;
  @observable video: {
    id: number;
    file: File;
  } | null = null;
  @observable caption: string = '';
  @observable settings: TPostSettings = DEFAULT_POST_SETTINGS;

  @observable areProductTagsValid: boolean = true;
  @observable products: Array<{ name: string; url: string }> = EMPTY_PRODUCTS;

  constructor() {
    makeObservable(this);
  }

  @action chooseVideo(id: number, file: File) {
    this.video = { id, file };
  }

  @action setCaption(caption: string) {
    this.caption = caption;
  }

  @action changeSettings(settings: TPostSettings) {
    this.settings = settings;
  }

  @action setProducts(products: Array<{ name: string; url: string }>) {
    this.products = products;
  }

  @action clear() {
    this.video = null;
    this.caption = '';
    this.settings = DEFAULT_POST_SETTINGS;
    this.products = EMPTY_PRODUCTS;
  }

  @action setAreProductTagsValid() {
    this.areProductTagsValid = this.products.every(({ name, url }) => {
      if (name.length === 0 && url.length === 0) return true;
      else if (!!name.length && url.length === 0) return false;
      else if (name.length === 0 && !!url.length) return false;
      else if (!!name.length && !!url.length) {
        if (name.length > 15 || !urlValidation(url)) return false;
      }
      return true;
    });
  }
}

export default UploadVideoStore;
