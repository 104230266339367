import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import background from '../../../../assets/images/starting-background-2.png';

const Starting = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <img src={background} alt="" loading="lazy" />
      <div className={styles.content}>
        <h2>Welcome to</h2>
        <h1>ENTAMA</h1>

        {/* new member */}
        <button
          style={{
            height: '20.6%',
            backgroundColor: '#D95454',
            borderRadius: '4rem 0 0 0',
          }}
          onClick={() => navigate('/registration-methods')}
        >
          <p
            style={{
              alignSelf: 'baseline',
              height: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            新規会員登録
          </p>
        </button>

        {/* already have an account */}
        <button
          style={{
            height: '10.3%',
            backgroundColor: '#0B0B0B',
            borderRadius: '4rem 0 0 0',
          }}
          onClick={() => navigate('/login-methods')}
        >
          <p>既にアカウントをお持ちの方</p>
        </button>
      </div>
    </div>
  );
};

export default Starting;
