import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useQuery } from 'react-query';

import CheckBox from 'components/check-box';
import { TConversation } from 'types/direct';
import { formatMessageTime } from 'utils/time';
import useStores from 'hooks/useStores';
import Images from 'assets/images';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { getProfile } from 'services/user';

import styles from './styles.module.scss';
import './styles.scss';
import { clearUnreadCount } from 'services/firebase';

interface IProps {
  selecting: boolean;
  toggle: (checked: boolean) => void;
  item: TConversation;
  onDelete: any;
}

const ConversationItem = ({ selecting, toggle, item, onDelete }: IProps) => {
  const navigate = useNavigate();
  const sessionStore: SessionStore = useStores().sessionStore;
  const uiStore: UIStore = useStores().uiStore;

  const { data, isLoading, refetch } = useQuery(
    ['profile', item.user?.id],
    () => getProfile(item.user?.id || 0),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.is_blocked || data.is_blocked_me) {
          uiStore.showAlertBox({
            title: 'このユーザーは退会済みです。',
            buttons: [{ title: '閉じる' }],
          });
        } else {
          navigate(`/direct/${item.id}`);
        }
      },
      onError: (err) => {
        console.log('Error getting profile: ', err);
        if (err == 'User not found') {
          clearUnreadCount(item.id);
          uiStore.showAlertBox({
            title: 'このユーザーは退会済みです。',
            buttons: [{ title: '閉じる' }],
          });
        }
      },
    }
  );

  const { date, time } = formatMessageTime(item.lastSentTimestamp);

  // useEffect(() => {
  //   if (!item.user) {
  //     clearUnreadCount(item.id);
  //     uiStore.showAlertBox({
  //       title: 'このユーザーは退会済みです。',
  //       buttons: [{ title: '閉じる', onPress: () => navigate(-1) }],
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  return (
    <Swiper slidesPerView={'auto'} modules={[Pagination]}>
      <SwiperSlide>
        <div className={styles.container}>
          {selecting && (
            <CheckBox checkBoxClassName={styles.checkbox} toggle={toggle} />
          )}
          <div
            className={styles.left}
            onClick={() => {
              if (item.user) refetch();
              else {
                clearUnreadCount(item.id);
                uiStore.showAlertBox({
                  title: 'このユーザーは退会済みです。',
                  buttons: [{ title: '閉じる' }],
                });
              }
            }}
          >
            <div className={styles.avatarContainer}>
              <img
                src={
                  item.user ? item.user.avatar?.small : Images.imgDefaultAvatar
                }
                alt=""
              />
              {item.lastMessage.senderId !== sessionStore.profile.id &&
                item.unreadCount > 0 && (
                  <div className="d-flex justify-content-center align-items-center">
                    {item.unreadCount}
                  </div>
                )}
            </div>
            <div style={{ flex: 1, minWidth: 0 }}>
              <div className={styles.name}>
                {item.user
                  ? item.user.name
                    ? item.user.name
                    : item.user.nick_name
                  : ''}
              </div>
              <div className={styles.message}>
                {item.lastMessage.type === 'text'
                  ? item.lastMessage.text
                  : 'Last message is an image one'}
              </div>
            </div>
          </div>
          <div className={styles.time}>{date ? `${date} ${time}` : time}</div>
        </div>
      </SwiperSlide>
      {!selecting && (
        <SwiperSlide>
          <button onClick={onDelete}>削除</button>
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default ConversationItem;
