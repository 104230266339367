import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { observer } from 'mobx-react';

import PostAdvancedSettings from 'components/post-advanced-settings';
import useStores from 'hooks/useStores';
import UploadVideoStore from 'stores/upload-video';
import { getMyProfile, loginSnsWebView } from 'services/auth';
import SessionStore from 'stores/session';

import { WEBVIEW_MESSAGE } from 'consts';

const VideoAdvancedSettings = observer(() => {
  const navigate = useNavigate();

  const videoStore: UploadVideoStore = useStores().uploadVideoStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  let provider: string;
  let token: string;
  let tokenSecret: string;

  const loginSnsWebViewMutation = useMutation(() => loginSnsWebView(provider, token, tokenSecret), {
    onSuccess: async (data) => {
      if (data.data.is_registered) {
        const res = await getMyProfile();
        if (res.data && res.data.is_twitter_linked) {
          sessionStore.setProfile(res.data);
        }
      } else {
        // Will not happen
      }
    },
  });

  const checkTwitterLinked = (openedWindow: any) => {
    let count = 0;
    const jobId = setInterval(async () => {
      count++;
      if (count > 15) {
        clearInterval(jobId);
      }

      const res = await getMyProfile();
      if(res.data && res.data.is_twitter_linked) {
        clearInterval(jobId);
        openedWindow.close();
        sessionStore.setProfile(res.data);
      }
    }, 2000)
  }

  return (
    <PostAdvancedSettings
      initialValue={videoStore.settings}
      isTwitterLinked={sessionStore.profile.is_twitter_linked}
      onGoBack={(value) => {
        videoStore.changeSettings(value);
        navigate(-1);
      }}
      onLinkTwitterWindowOpen={checkTwitterLinked}
      handleSnsWebView={(channel: string, data: any) => {
        if (channel == WEBVIEW_MESSAGE.LOGIN_SNS_TWITTER) {
          provider = 'twitter';
        }
        token = data.token;
        tokenSecret = data.tokenSecret || undefined;
        loginSnsWebViewMutation.mutate()
      }}
    />
  );
});

export default VideoAdvancedSettings;
