import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Layout from 'components/layout';
import Header from 'components/header';
import SavedTab from '../../components/saved-tab';
import {
  getSavedPosts,
  getSavedProducts,
  getSavedVideos,
} from 'services/saved-data';
import { getNextPageParam, transformData } from 'utils/pagination';
import { TPost } from 'types/post';
import { TProduct } from 'types/product';

import styles from './styles.module.scss';

const SavedAll = observer(() => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<'IMAGES' | 'MOVIES' | 'SHOPS'>(
    'IMAGES'
  );

  const imageQuery = useInfiniteQuery<Array<TPost>>(
    ['saved-images'],
    ({ pageParam = 1 }) => getSavedPosts(pageParam),
    {
      enabled: false,
      select(data) {
        return transformData(data);
      },
      getNextPageParam,
    }
  );

  const videoQuery = useInfiniteQuery<Array<TPost>>(
    ['saved-videos'],
    ({ pageParam = 1 }) => getSavedVideos(pageParam),
    {
      enabled: false,
      select(data) {
        return transformData(data);
      },
      getNextPageParam,
    }
  );

  const productQuery = useInfiniteQuery<Array<TProduct>>(
    ['saved-products'],
    ({ pageParam = 1 }) => getSavedProducts(pageParam),
    {
      enabled: false,
      select(data) {
        return transformData(data, 'products');
      },
      getNextPageParam,
    }
  );

  useEffect(() => {
    return () => {
      imageQuery.remove();
      videoQuery.remove();
      imageQuery.remove();
    };
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case 'IMAGES':
        if (!imageQuery.isFetched) imageQuery.refetch();
        break;
      case 'MOVIES':
        if (!videoQuery.isFetched) videoQuery.refetch();
        break;
      case 'SHOPS':
        if (!productQuery.isFetched) productQuery.refetch();
        break;
    }
  }, [activeTab]);

  const renderImageTab = () => (
    <SavedTab
      query={imageQuery}
      isActive={activeTab === 'IMAGES'}
      renderItem={(item: TPost) => (
        <div>
          <img
            key={item.id}
            src={item.files[0].medium}
            alt=""
            onClick={() => navigate(`/posts/${item.id}`)}
          />
        </div>
      )}
    />
  );

  const renderVideoTab = () => (
    <SavedTab
      query={videoQuery}
      isActive={activeTab === 'MOVIES'}
      renderItem={(item: TPost) => (

        item.files[0].small ? (
          <div>
            <img
              key={item.id}
              src={item.files[0].small}
              alt=""
              onClick={() => navigate(`/posts/${item.id}`)}
            />
          </div>
        ) : (
          <div>
            <video
              preload="metadata"
              playsInline
              onClick={() => navigate(`/posts/${item.id}`)}
            >
              <source src={item.files[0].origin} type="video/mp4" />
            </video>
          </div>
        )

      )}
    />
  );

  const renderProductTab = () => (
    <SavedTab
      query={productQuery}
      isActive={activeTab === 'SHOPS'}
      renderItem={(item: TProduct) => (
        <div>
          <img
            src={item.images[0].medium}
            alt=""
            onClick={() => navigate(`/product/${item.id}`)}
          />
        </div>
      )}
    />
  );

  return (
    <Layout bottomNavBar>
      <Header title="保存済み" back bordered />
      <div className={styles.container}>
        <ul>
          {(
            ['IMAGES', 'MOVIES', 'SHOPS'] as Array<
              'IMAGES' | 'MOVIES' | 'SHOPS'
            >
          ).map((item) => (
            <li
              key={item}
              className={activeTab === item ? styles.active : ''}
              onClick={() => setActiveTab(item)}
            >
              <div>{item}</div>
            </li>
          ))}
        </ul>
        {renderImageTab()}
        {renderVideoTab()}
        {renderProductTab()}
      </div>
    </Layout>
  );
});

export default SavedAll;
