import Layout from 'components/layout';

import styles from './styles.module.scss';

interface IProps {
  title: string;
  subtitle?: any;
  children: any;
}

const AuthScreenTemplate = ({ title, subtitle, children }: IProps) => {
  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.content}>{children}</div>
      </div>
    </Layout>
  );
};

export default AuthScreenTemplate;
