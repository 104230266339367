import { useState } from 'react';
import { v4 } from 'uuid';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IProps {
  label?: string;
  value?: boolean;
  toggle?: (checked: boolean) => void;
  className?: any;
  labelClassName?: any;
  checkBoxClassName?: any;
}

const CheckBox = ({
  label,
  value = false,
  toggle,
  className,
  labelClassName,
  checkBoxClassName,
}: IProps) => {
  const id = v4();

  const [status, setStatus] = useState(value);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <input
        type="checkbox"
        id={id}
        className={checkBoxClassName}
        checked={status}
        onChange={(event) => {
          setStatus(event.target.checked);
          toggle && toggle(event.target.checked);
        }}
      />
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
