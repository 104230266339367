import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import UserList from '../../components/user-list';
import UserTagList from '../../components/user-tag-list';
import UploadPhotosStore from 'stores/upload-photos';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import useTag from 'hooks/useTag';

import styles from './styles.module.scss';

const TagUsers = observer(() => {
  const uiStore: UIStore = useStores().uiStore;
  const uploadStore: UploadPhotosStore = useStores().uploadPhotosStore;
  const { currentIndex, photos } = uploadStore;

  const [modal, setModal] = useState(false);

  const positionRef = useRef<{ top: number; left: number } | null>(null);
  const userLengthRef = useRef(0);

  const checkUsersLength = () => {
    if (userLengthRef.current < 10) {
      setModal(true);
    } else
      setTimeout(() => {
        uiStore.showAlertBox({
          title: '人物のタグ付けは10人まで可能です。',
          buttons: [
            {
              title: '閉じる',
            },
          ],
        });
      }, 100);
  };

  useTag(positionRef, () => checkUsersLength());

  useEffect(() => {
    uploadStore.toggleTaggingUser(true);
    return () => {
      uploadStore.toggleTaggingUser(false);
    };
  }, []);

  useEffect(() => {
    userLengthRef.current = photos[currentIndex].users.length;
  }, [photos, currentIndex, photos[currentIndex], photos[currentIndex].users]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>タグを追加</div>
      {photos[currentIndex].users.length > 0 ? (
        <UserTagList
          users={photos[currentIndex].users.map((tag) => tag.user)}
          onUntag={(userId: number) => {
            uploadStore.untagUser(userId);
          }}
        />
      ) : (
        <div className={styles.content}>
          写真をタップしてタグ付けできます。
          <br />
          人物のタグ付けは10人まで可能です。
        </div>
      )}
      {modal && (
        <UserList
          visible={modal}
          onCancel={() => setModal(false)}
          onSelect={(user) => {
            uploadStore.tagUser(
              positionRef.current?.top || 0,
              positionRef.current?.left || 0,
              user
            );
            setModal(false);
          }}
        />
      )}
    </div>
  );
});

export default TagUsers;
