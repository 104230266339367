import UIStore from './ui';
import SessionStore from './session';
import UploadPhotosStore from './upload-photos';
import UploadVideoStore from './upload-video';
import DirectStore from './direct';
import EditVideoStore from './edit-video';
import EditPostStore from './edit-post';

const stores = {
  uiStore: new UIStore(),
  sessionStore: new SessionStore(),
  uploadPhotosStore: new UploadPhotosStore(),
  uploadVideoStore: new UploadVideoStore(),
  directStore: new DirectStore(),
  editVideoStore: new EditVideoStore(),
  editPostStore: new EditPostStore(),
};

export default stores;
