import classNames from 'classnames';

import { API_URL } from 'config';
import { WEBVIEW_MESSAGE } from 'consts';
import { useEffect } from 'react';
import {
  isOnWebview,
  registHandleNativeMessage,
  sendMessageToNative,
} from 'services/webviewService';
import { loginSnsWebView } from 'services/auth';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

interface IProps {
  onMailClick: () => void;
  handleSnsWebView: (channel: string, data: any) => void;
  className?: any;
  type: 'login' | 'registration';
}

const AuthMethods = ({ onMailClick, handleSnsWebView, className, type }: IProps) => {
  const navigate = useNavigate();

  const origin = window.location.origin;
  const getUrl = (sns: 'line' | 'facebook' | 'twitter') => {
    return `${API_URL}login_sns/${sns}?authenticated_url=${origin}/feed&register_url=${origin}/registration`;
  };

  const handleLoginSns = (
    type: 'login' | 'registration',
    provider: 'line' | 'facebook' | 'twitter'
  ) => {
    if (!isOnWebview()) {
      window.open(getUrl(provider));
      return;
    }

    let handleKey = '';

    if (type === 'login') {
      if (provider === 'line') {
        handleKey = WEBVIEW_MESSAGE.LOGIN_SNS_LINE;
      } else if (provider === 'twitter') {
        handleKey = WEBVIEW_MESSAGE.LOGIN_SNS_TWITTER;
      }
    } else {
      if (provider === 'line') {
        handleKey = WEBVIEW_MESSAGE.REGIST_SNS_LINE;
      } else if (provider === 'twitter') {
        handleKey = WEBVIEW_MESSAGE.REGIST_SNS_TWITTER;
      }
    }

    registHandleNativeMessage(handleKey, handleSnsWebView);
    sendMessageToNative(handleKey, null);
  };

  registHandleNativeMessage(WEBVIEW_MESSAGE.NAVIGATION, (channel: string, data: any) => {
    if (data.path) {
      navigate(data.path);
    }
  });

  return (
    <div className={classNames('d-flex flex-column', className)}>
      <button
        className={classNames(styles.btn, styles.red)}
        onClick={onMailClick}
      >
        {type === 'login' ? 'メールアドレスでログイン' : 'メールアドレスで登録'}
      </button>

      {!isOnWebview() && (
        <button
          className={classNames(styles.btn, styles.green)}
          onClick={() => handleLoginSns(type, 'line')}
        >
          {type === 'login' ? 'LINEでログイン' : 'LINEで登録'}
        </button>
      )}

      {!isOnWebview() && (
        <button
          className={classNames(styles.btn, styles.blue)}
          onClick={() => handleLoginSns(type, 'twitter')}
        >
          {type === 'login' ? 'Twitterでログイン' : 'Twitterで登録'}
        </button>
      )}

    </div>
  );
};

export default AuthMethods;
