import AuthScreenTemplate from 'modules/auth/components/template';
import AuthMethods from 'modules/auth/components/auth-methods';

import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { loginSnsWebView } from 'services/auth';

import { WEBVIEW_MESSAGE } from 'consts';
import { useEffect } from 'react';
import { isOnWebview } from 'services/webviewService';

const RegistrationMethods = () => {
  const navigate = useNavigate();

  let provider: string;
  let token: string;
  let tokenSecret: string;

  const loginSnsWebViewMutation = useMutation(() => loginSnsWebView(provider, token, tokenSecret), {
    onSuccess: (data) => {
      if (data.data.is_registered) {
        navigate('/feed')
      } else {
        const origin = window.location.origin;
        const token: string = data.data.token || '';
        const name: string = data.data.name || '';
        const nickName: string = data.data.nick_name || '';
        const email: string = data.data.email || '';

        window.location.href = origin + '/registration' + '?token=' + token + '&name=' + name + '&nick_name=' + nickName + '&email=' + email;
      }
    },
  });

  useEffect(() => {
    // Cheat for iOS review
    if (isOnWebview()) {
      navigate('/registration');
    }
  })

  return (
    <AuthScreenTemplate title="新規会員登録">
      <div className="d-flex flex-column">
        <AuthMethods
          className="mt-5"
          onMailClick={() => navigate('/registration')}
          handleSnsWebView={(channel: string, data: any) => {
            if (channel == WEBVIEW_MESSAGE.LOGIN_SNS_TWITTER) {
              provider = 'twitter';
            }
            if (channel == WEBVIEW_MESSAGE.LOGIN_SNS_LINE) {
              provider = 'line';
            }
            token = data.token;
            tokenSecret = data.tokenSecret || undefined;
            loginSnsWebViewMutation.mutate()
          }}
          type="registration"
        />
        <button
          className={styles.smallButton}
          onClick={() => navigate('/login-methods')}
        >
          既にアカウントをお持ちの方
        </button>
      </div>
    </AuthScreenTemplate>
  );
};

export default RegistrationMethods;
