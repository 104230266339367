import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Layout from 'components/layout';
import Images from 'assets/images';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';
import ConversationItem from '../../components/conversation-item';
import {
  deleteConversation,
  deleteConversations,
  detachConversationListListener,
  getConversationList,
} from 'services/firebase';
import { TConversation } from 'types/direct';
import SessionStore from 'stores/session';

import styles from './styles.module.scss';

const ConversationList = observer(() => {
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const myId = sessionStore.profile.id;

  const navigate = useNavigate();

  const [conversations, setConversations] = useState<Array<TConversation>>([]);
  const [selecting, setSelecting] = useState(false);
  const [selectedConvos, setSelectedConvos] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        uiStore.showLoading();
        await getConversationList(sessionStore.profile.id, (cons) => {
          uiStore.hideLoading();
          setConversations(cons);
        });
      } catch (err) {
        console.log('get conversation list error: ', err);
      } finally {
        uiStore.hideLoading();
      }
    };
    fetchData();
    return () => {
      detachConversationListListener();
    };
  }, []);

  useEffect(() => {
    if (!selecting) setSelectedConvos([]);
  }, [selecting]);

  const del = async () => {
    try {
      uiStore.showLoading();
      setSelecting(false);
      await deleteConversations(myId, selectedConvos);
    } catch (err) {
      console.log('Error here: ', err);
    } finally {
      uiStore.hideLoading();
    }
  };

  const showDeletePopup = (onDelete: any) => {
    uiStore.showAlertBox({
      title: 'このチャットを完全に削除しますか？',
      content:
        'チャットを削除すると、そのスレッドはあなたの受信箱からは消えますが、相手には引き続き表示されます。あなたがブロックしない限り、相手はあなたにメッセージを送信できます。',
      buttons: [
        {
          title: '削除',
          isRed: true,
          onPress: onDelete,
        },
        {
          title: 'キャンセル',
          onPress: () => {
            setSelecting(false);
          },
        },
      ],
    });
  };

  return (
    <Layout>
      <div
        className="d-flex flex-column h-100 w-100"
        style={{ minHeight: '100vh' }}
      >
        <div className={styles.header} style={{paddingTop: uiStore.appStatusBarHeight }}>
          <div className={styles.left}>
            <img src={Images.icBack} alt="" onClick={() => navigate(-1)} />
            <div className={styles.title}>{sessionStore.profile.nick_name}</div>
          </div>
          {!selecting && (
            <img
              src={Images.icFilter}
              alt=""
              onClick={() => setSelecting(true)}
            />
          )}
          {selecting && (
            <div className={styles.cancel} onClick={() => setSelecting(false)}>
              キャンセル
            </div>
          )}
        </div>
        <div style={{ flex: 1 }}>
          {conversations.map((conversation, index) => (
            <ConversationItem
              key={conversation.id}
              item={conversation}
              selecting={selecting}
              onDelete={async () => {
                showDeletePopup(async () => {
                  try {
                    uiStore.showLoading();
                    await deleteConversation(myId, conversation.id);
                  } catch (err) {
                    console.log('Error delete one conversation: ', err);
                  } finally {
                    uiStore.hideLoading();
                  }
                });
              }}
              toggle={(checked: boolean) => {
                if (checked)
                  setSelectedConvos([...selectedConvos, conversation.id]);
                else {
                  setSelectedConvos(
                    selectedConvos.filter((i: any) => i !== conversation.id)
                  );
                }
              }}
            />
          ))}
          {/* <LoadingSpinner visible={uiStore.isLoading} /> */}
        </div>
        {selecting && (
          <div
            className={styles.delete}
            onClick={() => selectedConvos.length > 0 && showDeletePopup(del)}
          >
            削除（{selectedConvos.length}）
          </div>
        )}
      </div>
    </Layout>
  );
});

export default ConversationList;
