const icHeart = require('./ic_heart.png');
const icHeartActive = require('./ic_heart_active.png');
const icPlus = require('./ic_plus.png');
const icChat = require('./ic_chat.png');
const icBook = require('./ic_book.png');

const icHome = require('./ic_home_inactive.png');
const icHomeActive = require('./ic_home_active.png');
const icDiscover = require('./ic_discover_inactive.png');
const icDiscoverActive = require('./ic_discover_active.png');
const icPlay = require('./ic_play.png');
const icCart = require('./ic_cart_inactive.png');
const icCartActive = require('./ic_cart_active.png');
const icProfile = require('./ic_profile_inactive.png');
const icProfileActive = require('./ic_profile_active.png');

const icBack = require('./ic_back.png');
const icBackLight = require('./ic_back_light.png');
const icNext = require('./ic_next.png');
const icBookmark = require('./ic_bookmark.png');
const icBookmarkActive = require('./ic_bookmark_active.png');

const icThreeDots = require('./ic_three_dots.png');
const icPerson = require('./ic_person.png');
const icBag = require('./ic_bag.png');
const icSend = require('./ic_send.png');
const icMenu = require('./ic_menu.png');
const icMenuBento = require('./ic_menu_bento.png');
const icFilter = require('./ic_filter.png');
const icPlayGray = require('./ic_play_gray.png');
const icLibrary = require('./ic_library.png');
const icRedCircle = require('./ic_red_circle.png');
const icChecked = require('./ic_checked.png');
const icSearch = require('./ic_magnifying_glass.png');
const icClose = require('./ic_close.png');
const icUpload = require('./ic_upload.png');
const icVideo = require('./ic_video.png');

const imgDefaultAvatar = require('./img_default_avatar.png');
const icHeartVideo = require('./ic_heart_video.png');
const icChain = require('./ic_chain.png');
const icCommentVideo = require('./ic_comment_video.png');
const icEllipsis = require('./ic_ellipsis.png');
const icCoin = require('./ic_coin.png');
const icUnion = require('./ic_union.png');
const icGift = require('./ic_gift.png');
const icCheckedContainer = require('./ic_checked_container.png');
const icGreenChecked = require('./ic_green_checked.png');
const imgMobile = require('./mobile.jpg');
const imgLogo = require('./logo.png');
const imgGooglePlay = require('./download-google-play.jpg');
const imgAppStore = require('./download-apple-store.jpg');

const imgEmpty = require('./empty.png')

const Images = {
  icHeart,
  icHeartActive,
  icPlus,
  icChat,
  icBook,
  icHome,
  icHomeActive,
  icDiscoverActive,
  icDiscover,
  icPlay,
  icCart,
  icCartActive,
  icProfile,
  icProfileActive,
  icBack,
  icBackLight,
  icNext,
  imgDefaultAvatar,
  icBookmark,
  icBookmarkActive,
  icThreeDots,
  icPerson,
  icBag,
  icSend,
  icMenu,
  icFilter,
  icMenuBento,
  icPlayGray,
  icLibrary,
  icRedCircle,
  icChecked,
  icHeartVideo,
  icChain,
  icCommentVideo,
  icEllipsis,
  icSearch,
  icClose,
  icUpload,
  icCoin,
  icUnion,
  icGift,
  icCheckedContainer,
  icGreenChecked,
  icVideo,
  imgMobile,
  imgLogo,
  imgGooglePlay,
  imgAppStore,
  imgEmpty
};

export default Images;
