import { useNavigate } from 'react-router-dom';
import TextArea from 'react-textarea-autosize';
import { observer } from 'mobx-react';

import Images from 'assets/images';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';
import EditPostStore from 'stores/edit-post';

const EditPostOptions = observer(() => {
  const navigate = useNavigate();

  const editPostStore: EditPostStore = useStores().editPostStore;

  return (
    <div className={styles.mainContainer}>
      <div onClick={() => navigate('tag-users')}>
        タグ付け
        <img src={Images.icNext} alt="" />
      </div>
      <div onClick={() => navigate('tag-products')}>
        商品URLの追加
        <img src={Images.icNext} alt="" />
      </div>

      <TextArea
        placeholder="キャプションを書く"
        value={editPostStore.content}
        onChange={(event) => editPostStore.setContent(event.target.value)}
      />
    </div>
  );
});

export default EditPostOptions;
