import { Navbar, Container, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';

import './styles.scss';

interface IProps {
  children?: any;
}

const AppBar = ({ children }: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;

  return (
    <Navbar sticky="top" className="app-bar" style={{paddingTop: uiStore.appStatusBarHeight + 5}}>
      <Container>
        <Navbar.Brand className="entama" onClick={() => navigate('/feed')}>
          ENTAMA
        </Navbar.Brand>
        <Nav>{children}</Nav>
      </Container>
    </Navbar>
  );
};

export default AppBar;
