import { get } from 'services';

const LIMIT = 50;

export const getSavedPosts = (page: number) => {
  return get('api/post/search', {
    limit: LIMIT,
    page,
    type: 1,
    saved_post: 1,
  });
};

export const getSavedVideos = (page: number) => {
  return get('api/post/search', {
    limit: LIMIT,
    page,
    type: 2,
    saved_post: 1,
  });
};

export const getSavedProducts = (page: number) => {
  return get('api/product/search', {
    limit: LIMIT,
    page,
    saved_product: 1,
  });
};
