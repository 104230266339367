import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';

import VideoComments from 'modules/videos/components/video-comments';
import { showToast } from 'components/toastify';
import { ReactComponent as PlayIcon } from 'assets/svg/ic_play_arrow_white_48dp.svg';
import Images from 'assets/images/';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';
import useStores from 'hooks/useStores';
import usePost from 'hooks/usePost';
import useUser from 'hooks/useUser';
import { TPost } from 'types/post';
import { queryClient } from 'utils/query-client';

import styles from './styles.module.scss';
import { useVideoWatcher } from 'hooks/use-video-watcher';
import useLoginRequired from 'hooks/useLoginRequired';

import ReactPlayer from 'react-player/vimeo';
interface IProps {
  post: TPost;
  invalidateQueryKey?: Array<string | number>;
}

const VideoPost = ({ post, invalidateQueryKey }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const navigate = useNavigate();

  const videoRef = useRef<any>();
  const captionRef = useRef<any>();

  const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isVimeoReady, setIsVimeoReady] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [captionVisible, setCaptionVisible] = useState(false);
  const {
    hideMutation,
    saveMutation,
    likeMutation,
    unlikeMutation,
    toggleLikesMutation,
    toggleCommentsMutation,
    deletePostMutation,
    report,
    hidden,
    unhideMutation,
  } = usePost(post, invalidateQueryKey);

  const { followMutation } = useUser(() =>
    queryClient.invalidateQueries(invalidateQueryKey)
  );

  const { showLoginPopup } = useLoginRequired();

  const deletePost = () => {
    uiStore.showAlertBox({
      title: '本当にこの投稿を削除しますか？',
      buttons: [
        {
          title: '削除',
          isRed: true,
          onPress: () => deletePostMutation.mutate(),
        },
        {
          title: 'キャンセル',
        },
      ],
    });
  };

  const isMe = sessionStore.profile?.id === post.creator.id;
  const settings = sessionStore.profile
    ? isMe
      ? [
        {
          title: '削除',
          onPress: deletePost,
          isRed: true,
        },
        {
          title: post.setting.show_number_like
            ? '「いいね！」数を表示する'
            : '「いいね！」数を非表示にする',
          onPress: () =>
            toggleLikesMutation.mutate({
              status: !post.setting.show_number_like,
            }),
        },
        {
          title: post.setting.show_comment
            ? 'コメントをオフにする'
            : 'コメントをオンにする',
          onPress: () =>
            toggleCommentsMutation.mutate({
              status: !post.setting.show_comment,
            }),
        },
        {
          title: '編集', // edit
          onPress: () => navigate(`/v/${post.id}/edit`),
        },
        {
          title: 'リンクをコピー',
          onPress: () => {
            copy(`${window.location.origin}/posts/${post.id}`);
            showToast('リンクがクリップボードにコピーされました');
          },
        },
      ]
      : [
        {
          title: '報告する',
          onPress: report,
          isRed: true,
        },
        {
          title: '非表示にする',
          onPress: () => hideMutation.mutate(),
          isRed: true,
        },
        {
          title: '保存する',
          onPress: () => {
            saveMutation.mutate();
          },
        },
        {
          title: 'リンクをコピー',
          onPress: () => {
            copy(`${window.location.origin}/posts/${post.id}`);
            showToast('リンクがクリップボードにコピーされました');
          },
        },
      ]
    : [
      {
        title: 'リンクをコピー',
        onPress: () => {
          copy(`${window.location.origin}/posts/${post.id}`);
          showToast('リンクがクリップボードにコピーされました');
        },
      },
    ];

  const pause = () => {
    setIsPlaying(false);
    if (typeof videoRef.current.pause === 'function') {
      videoRef.current.pause();
    }

    if (typeof videoRef.current.getInternalPlayer === 'function') {
      if (videoRef.current.getInternalPlayer()) {
        videoRef.current.getInternalPlayer().pause();
      }
    }
  };

  const play = () => {
    setIsPlaying(true);
    if (typeof videoRef.current.play === 'function') {
      videoRef.current.play();
    }

    if (typeof videoRef.current.getInternalPlayer === 'function') {
      if (videoRef.current.getInternalPlayer()) {
        videoRef.current.getInternalPlayer().play();
      }
    }
  };

  const captionTouchStartHandler = (event: any) => {
    setCurrentScrollPosition(event.touches[0].clientY);
  };

  /**
   * scrollHeight value is not the same among Android devices.
   * IOS phones has the exact value.
   */
  const captionTouchMoveHandler = (event: any) => {
    captionRef.current.scrollTop -= Math.floor(
      (event.touches[0].clientY - currentScrollPosition) / 9
    );

    if (
      captionRef.current.scrollTop === 0 ||
      (captionRef.current.scrollTop + captionRef.current.clientHeight >
        captionRef.current.scrollHeight - 10 &&
        captionRef.current.scrollTop + captionRef.current.clientHeight <
        captionRef.current.scrollHeight + 10)
    ) {
      uiStore.setIsReadingVideoCaption(false);
    } else {
      uiStore.setIsReadingVideoCaption(true);
    }
  };

  const captionTouchEndHandler = (_event: any) => {
    uiStore.setIsReadingVideoCaption(false);
  };

  // pause video when out of screen.
  useVideoWatcher({ target: videoRef, pause, play });

  useEffect(() => {
    return () => {
      uiStore.setIsReadingVideoCaption(false);
    };
  }, []);

  return (
    <div className={styles.container}>
      {post.files[0].hls ? (
        <ReactPlayer
          ref={videoRef}
          url={post.files[0].hls}
          controls={false}
          playsinline={true}
          playing={true}
          light={post.files[0].small ? post.files[0].small : false}
          config={{
            playerOptions: {
              loop: true,
              autoplay: true
            },
          }}
          onPlay={() => {
            
          }}
          onPause={() => {

          }}
          onProgress={({played}) => {
            if(isFirstLoad && !isPlaying && played > 0) {
              // because ios browser can't autoplay video ...
              setIsPlaying(true);
              setIsFirstLoad(false);
            }
          }}
          onReady={() => {
            setIsVimeoReady(true);
          }}
          width="100%"
          height="100%"
        />
      ) : (
        <video playsInline preload="none" ref={videoRef} poster={post.files[0].small ? post.files[0].small : Images.imgEmpty} onClick={pause}>
          <source src={post.files[0].origin} type="video/mp4" />
        </video>
      )}
      {((post.files[0].hls && isVimeoReady) || !post.files[0].hls) && !isPlaying && <PlayIcon className={styles.playIcon} onClick={play} />}
      <div className={styles.infoContainer}>
        <div>
          <div>
            {post.files[0].product_tags.map((tag) => (
              <div
                key={tag.id}
                className={styles.tag}
                onClick={() => window.open(tag.link)}
              >
                <img src={Images.icChain} alt="" />
                <div>{tag.title}</div>
              </div>
            ))}
          </div>
          <div>
            <div className={styles.actionContainer}>
              <img
                src={post.is_liked ? Images.icHeartActive : Images.icHeartVideo}
                style={{
                  width: 22,
                }}
                alt=""
                onClick={() =>
                  sessionStore.profile
                    ? post.is_liked
                      ? unlikeMutation.mutate()
                      : likeMutation.mutate()
                    : showLoginPopup()
                }
              />
              {post.setting.show_number_like && <div>{post.number_likes}</div>}
            </div>
            {post.setting.show_comment && (
              <div
                className={styles.actionContainer}
                onClick={() =>
                  uiStore.showBottomSheet(<VideoComments postId={post.id} />)
                }
              >
                <img
                  src={Images.icCommentVideo}
                  style={{
                    width: 22,
                  }}
                  alt=""
                />
                <div>{post.number_comments}</div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div
            className={styles.userInfo}
            onClick={() => navigate(`/${post.creator.id}#movies`)}
          >
            <img
              src={post.creator.avatar?.small || Images.imgDefaultAvatar}
              alt=""
            />
            <div className={styles.userNames}>
              <div>{post.creator.name}</div>
              <div>{post.creator.nick_name}</div>
            </div>
          </div>
          <img
            src={Images.icEllipsis}
            style={{
              width: 22,
            }}
            alt=""
            onClick={() => {
              if (isPlaying) pause();
              uiStore.showActionSheet(settings);
            }}
          />
        </div>
        <div>
          <div
            ref={captionRef}
            className={styles.caption}
            onTouchStart={captionTouchStartHandler}
            onTouchMove={captionTouchMoveHandler}
            onTouchEnd={captionTouchEndHandler}
          >
            {post.content.length > 100
              ? captionVisible
                ? post.content
                : `${post.content.substring(0, 101)}...`
              : post.content}
            {post.content.length > 100 && (
              <span
                className={classNames(styles.readMore, 'm-1')}
                onClick={() => setCaptionVisible(!captionVisible)}
              >
                {captionVisible ? '省略' : '続きを読む'}
              </span>
            )}
          </div>
          {sessionStore.profile && !isMe && !post.creator.is_followed && (
            <button
              className={styles.follow}
              onClick={() => followMutation.mutate(post.creator.id)}
            >
              フォローする
            </button>
          )}
        </div>
      </div>
      {hidden && (
        <div className={styles.hidden}>
          <div>この投稿は非表示になりました。</div>
          <button onClick={() => unhideMutation.mutate()}>元に戻す</button>
        </div>
      )}
    </div>
  );
};

export default VideoPost;
