import { useInfiniteQuery } from 'react-query';

import Layout from 'components/layout';
import Header from 'components/header';
import UserList from 'components/user-list';
import { getBlockList } from 'services/user';
import { TUserInfo } from 'types/user';
import { getNextPageParam, transformData } from 'utils/pagination';
import useUser from 'hooks/useUser';

import styles from './styles.module.scss';

const BlockList = () => {
  const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery<
    Array<TUserInfo>
  >(['block-list'], ({ pageParam = 1 }) => getBlockList(pageParam), {
    select(data) {
      return transformData(data, 'users');
    },
    getNextPageParam,
  });

  const { unblockMutation } = useUser(refetch);

  return (
    <Layout bottomNavBar>
      <Header title="ブロックしたアカウント" back bordered />
      <UserList
        users={data?.pages.flat() || []}
        rightButton={(user) => (
          <button
            className={styles.unblock}
            onClick={() => unblockMutation.mutate(user.id)}
          >
            ブロック解除
          </button>
        )}
        loadMore={fetchNextPage}
        hasMore={hasNextPage || false}
      />
    </Layout>
  );
};

export default BlockList;
