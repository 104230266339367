import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import moment from 'moment';

import { uploadFiles } from 'services/file';
import { TMessage } from 'types/direct';
import { sendMessage, updateLastMessage } from 'services/direct';
import SessionStore from 'stores/session';
import useStores from './useStores';
import { TFile } from 'types/file';

const useDirect = (conversationId: string, friendId: number) => {
  const sessionStore: SessionStore = useStores().sessionStore;

  const EMPTY_MESSAGE: TMessage = {
    id: '',
    createdAt: 0,
    senderId: sessionStore.profile.id,
    type: 'text',
    text: '',
    isRead: false,
  };

  const [photos, setPhotos] = useState<Array<File>>([]);
  const [message, setMessage] = useState<TMessage>(EMPTY_MESSAGE);

  const uploadMutation = useMutation(() => uploadFiles(photos), {
    onSuccess: (data) => {
      setPhotos([]);
      sendImageMessage(data.data);
    },
  });

  const sendMutation = useMutation(sendMessage, {
    onMutate: () => {
      setMessage(EMPTY_MESSAGE);
    },
    onSuccess: (newMessageRef) => {
      updateLastMessage(conversationId, newMessageRef);
    },
  });

  useEffect(() => {
    if (photos.length > 0) {
      uploadMutation.mutate();
    }
  }, [photos]);

  const sendTextMessage = () => {
    if (message.type === 'text' && message.text && message.text?.length > 0) {
      sendMutation.mutate({
        conversationId,
        message: { ...message, createdAt: moment.now(), friendId },
      });
    }
  };

  const sendImageMessage = (images: Array<TFile>) => {
    sendMutation.mutate({
      conversationId,
      message: {
        ...message,
        type: 'image',
        images,
        createdAt: moment.now(),
        friendId
      },
    });
  };

  return { setPhotos, setMessage, sendTextMessage, message };
};

export default useDirect;
