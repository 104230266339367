import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { postAddComment } from 'services/post';
import { searchUsers } from 'services/user';

import MentionSuggestions from '../mention-suggestions';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';

import styles from './styles.module.scss';
import Images from 'assets/images';

const QuickComment = () => {
  const [comment, setComment] = useState<string>('');
  const [hasComment, setHasComment] = useState<boolean>(false);
  const [isMentionActive, setMentionActive] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');

  const inputRef = useRef<any>();
  const currPosRef = useRef<number>(0);
  const start = useRef<number>(0);
  const end = useRef<number>(0);

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const mentionUsersData = useQuery(
    ['mention-users', keyword],
    async () => await searchUsers(1, keyword),
    {
      refetchOnReconnect: 'always',
    }
  );

  const quickCommentMutation = useMutation(
    (payload: {
      postId: number;
      comment: string;
      commentParentId: number;
      taggedUsers: string[];
    }) =>
      postAddComment(
        payload.postId,
        payload.comment,
        payload.commentParentId,
        payload.taggedUsers
      ),
    {
      onSuccess: (_data) => {
        uiStore.closeQuickComment();
      },
    }
  );

  const autoGrowHandler = (event: any) => {
    event.target.style.height = '2.5625rem';
    event.target.style.height = event.target.scrollHeight + 'px'; // !imporant
  };

  const addUserToComment = (name: string) => {
    // mention a user at the end of input
    let newComment: string;
    if (currPosRef.current === comment.length) {
      let commentCurr = comment.split(/(\s)/);
      commentCurr[commentCurr.length - 1] = '@' + name + ' ';
      newComment = commentCurr.join('');
    }
    // add user mention in the middle of input
    else {
      let newData = '@' + name + ' ';

      newComment = comment
        .slice(0, start.current)
        .concat(newData)
        .concat(comment.slice(end.current + 1));
    }
    // automatically set IsMentionActive to false by leaving a whitespace
    setComment(newComment);
    setKeyword('');

    // this will fix bottom sheet bug: current returns to first position after tagging s1
    inputRef.current.focus();
  };

  const validateMention = useCallback(() => {
    // cursor at the end of input
    if (currPosRef.current === comment.length) {
      const arr = comment.split(/(\s)/);
      if (/^@[\w]+$/.test(arr[arr.length - 1])) {
        let key = arr[arr.length - 1].replace('@', '').toLowerCase();
        setMentionActive(true);
        setKeyword(key);
      } else {
        setMentionActive(false);
      }
    }
    // cursor at the start or in the middle
    else {
      let arr = [comment[currPosRef.current]];
      let pos = currPosRef.current;
      let text: string;
      start.current = pos;
      end.current = pos;

      if (comment[currPosRef.current] !== ' ') {
        while (
          /[\w@]/.test(comment[end.current]) &&
          end.current < comment.length
        ) {
          end.current += 1;
          arr.push(comment[end.current]);
        }
      }

      if (currPosRef.current !== 0) {
        while (/[\w@]/.test(comment[start.current - 1]) && start.current > 0) {
          start.current -= 1;
          arr.unshift(comment[start.current]);
        }
      }

      text = arr.join('').trim();
      if (/^@[\w]+$/.test(text)) {
        let key = text.replace(/@/, '');
        setMentionActive(true);
        setKeyword(key);
      } else {
        if (isMentionActive) {
          setMentionActive(false);
        }
      }
    }
  }, [comment, isMentionActive]);

  const submitCommentHandler = (event: any) => {
    event.preventDefault();

    let taggedUsers: string[] = [];
    let arr = comment.split(/(@[a-zA-Z0-9_]+)/g);
    let filteredUsers = arr.filter((item) => /^@[a-zA-Z0-9_]+$/.test(item));
    let postId = uiStore.quickComment.postId;
    taggedUsers = [...new Set(filteredUsers)];

    quickCommentMutation.mutate({
      postId,
      comment,
      commentParentId: 0,
      taggedUsers,
    });
  };

  // keeping track of hasComment flag
  useEffect(() => {
    if (comment.length === 0 || /^\s+$/.test(comment) === true) {
      setHasComment(false);
    } else {
      setHasComment(true);
      inputRef.current.focus();
    }
  }, [comment]);

  // keeping track of mentions
  useEffect(() => {
    currPosRef.current = inputRef.current.selectionStart;
    validateMention();
  }, [comment]);

  return (
    <div className={styles.fastCommentContainer} style={{paddingTop: uiStore.appStatusBarHeight + 10 }}>
      <form onSubmit={submitCommentHandler}>
        <div className={styles.avatar}>
          <img
            src={sessionStore.profile.avatar?.small || Images.imgDefaultAvatar}
            alt=""
            className={styles.avatar}
            loading="lazy"
            aria-label="user-avatar"
          />
        </div>
        <textarea
          name="comment"
          ref={inputRef}
          placeholder="コメントを入力..."
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          autoComplete="off"
          autoCorrect="off"
          aria-label="comment-input"
          onInput={autoGrowHandler}
          autoFocus={true}
        />
        <button type="submit" className={styles.sendBtn} disabled={!hasComment}>
          <img src={Images.icSend} alt="" loading="lazy" />
        </button>
      </form>
      {isMentionActive && (
        <MentionSuggestions
          mentionUsersData={mentionUsersData}
          addUserToComment={addUserToComment}
        />
      )}
    </div>
  );
};

export default QuickComment;
