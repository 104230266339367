import { makeObservable, action, observable } from 'mobx';
import { urlValidation } from 'utils/validation';

const EMPTY_PRODUCTS = [
  { title: '', link: '', type: 2, position_x: 0, position_y: 0 },
  { title: '', link: '', type: 2, position_x: 0, position_y: 0 },
  { title: '', link: '', type: 2, position_x: 0, position_y: 0 },
  { title: '', link: '', type: 2, position_x: 0, position_y: 0 },
];

interface IProductTag {
  title: string;
  link: string;
  type: number;
  position_x: number;
  position_y: number;
}

class EditVideoStore {
  constructor() {
    makeObservable(this);
  }

  @observable videoLink: string = '';
  @observable content: string = '';
  @observable products: Array<IProductTag> = EMPTY_PRODUCTS;
  @observable areTagsValid: boolean = true;
  @observable fileId: number = 0;

  @action setVideoLink(link: string) {
    this.videoLink = link;
  }
  @action setContent(content: string) {
    this.content = content;
  }

  @action setDefaultProducts(products: Array<IProductTag>) {
    if (products.length === 0) return;
    for (let i = 0; i < products.length; i++) {
      this.products[i] = products[i];
    }
  }

  @action setTitle(index: number, title: string) {
    this.products[index].title = title;
    this.validateTags();
  }

  @action setLink(index: number, link: string) {
    this.products[index].link = link;
    this.validateTags();
  }

  @action validateTags() {
    this.areTagsValid = !this.products.some(
      (product) =>
        product.title.length > 15 ||
        !urlValidation(product.link) ||
        (product.title.length !== 0 && product.link.length === 0) ||
        (product.title.length === 0 && product.link.length !== 0)
    );
  }

  @action
  setFileId(id: number) {
    this.fileId = id;
  }

  @action clearStore() {
    this.products = EMPTY_PRODUCTS;
    this.content = '';
    this.videoLink = '';
    this.areTagsValid = true;
  }
}

export default EditVideoStore;
