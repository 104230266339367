import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import UploadPhotosStore from 'stores/upload-photos';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import useTag from 'hooks/useTag';
import TextInputField from 'components/text-input-field';

import styles from './styles.module.scss';
import { tagProductNameValidation, urlValidation } from 'utils/validation';

const TagProducts = observer(() => {
  const navigate = useNavigate();
  const [isTagNameValid, setIsTagNameValid] = useState<boolean>(true);
  const [isURLValid, setIsURLValid] = useState<boolean>(true);

  const uiStore: UIStore = useStores().uiStore;
  const uploadStore: UploadPhotosStore = useStores().uploadPhotosStore;
  const { photos, currentIndex } = uploadStore;

  const [isTagging, setIsTagging] = useState(false);
  const [productInfo, setProductInfo] = useState({ name: '', url: '' });

  const positionRef = useRef<{ top: number; left: number } | null>(null);

  useTag(positionRef, () => {
    if (photos[currentIndex].products.length < 10) {
      setIsTagging(true);
    } else
      setTimeout(
        () =>
          uiStore.showAlertBox({
            title: '商品のタグ付けは10件まで可能です。',
            buttons: [
              {
                title: '閉じる',
              },
            ],
          }),
        100
      );
  });

  useEffect(() => {
    uploadStore.toggleTaggingProduct(true);
    return () => {
      uploadStore.toggleTaggingProduct(false);
    };
  }, []);

  const renderInputs = () => (
    <div className={styles.inputs}>
      <TextInputField
        label="タグ名"
        placeholder="タグ名を入力してください"
        value={productInfo.name}
        onChange={(text: string) =>
          setProductInfo({ ...productInfo, name: text })
        }
      />
      {!isTagNameValid && (
        <p className={styles.error}>タグ名は15文字以内で入力してください。</p>
      )}
      <TextInputField
        label="商品URL"
        placeholder="商品ページのURLを入力してください"
        value={productInfo.url}
        onChange={(text: string) =>
          setProductInfo({ ...productInfo, url: text })
        }
      />
      {!isURLValid && <p className={styles.error}>無効なURLです。</p>}
      <button
        className={styles.addBtn}
        onClick={() => addProduct()}
        type="submit"
        disabled={productInfo.name.length === 0 || productInfo.url.length === 0}
      >
        登録
      </button>
    </div>
  );

  const addProduct = () => {
    if (!isTagNameValid) return;

    uploadStore.tagProduct(
      positionRef.current?.top || 0,
      positionRef.current?.left || 0,
      {
        name: productInfo.name,
        url: productInfo.url,
      }
    );
    setProductInfo({ name: '', url: '' });
    setIsTagging(false);
  };

  useEffect(() => {
    if (!tagProductNameValidation(productInfo.name)) {
      setIsTagNameValid(false);
    } else {
      if (!isTagNameValid) setIsTagNameValid(true);
    }
  }, [productInfo.name, isTagNameValid]);

  useEffect(() => {
    if (!urlValidation(productInfo.url)) {
      setIsURLValid(false);
    } else {
      if (!isURLValid) setIsURLValid(true);
    }
  }, [productInfo.url, isURLValid]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>商品URLの追加</div>
      {isTagging ? (
        renderInputs()
      ) : (
        <>
          <div className={styles.content}>
            写真をタップしてタグの位置を決定できます。
            <br />
            商品のタグ付けは10件まで可能です。
          </div>
          <button onClick={() => navigate('/upload-photos/add-bottom-link')}>
            画像下部に固定リンク設置
          </button>
        </>
      )}
    </div>
  );
});

export default TagProducts;
