import Layout from 'components/layout';
import Header from 'components/header';

import '../styles.scss';

const PrivacyPolicy = () => {
  return (
    <Layout bottomNavBar>
      <Header title="個人情報保護法" back bordered />
      <div className="static-page">
        1．当社は、個人情報の保護に関する法律その他関係法令及びガイドライン等の社会規範を遵守し、個人情報の保護の実践に努めてまいります。
        <br />
        2．当社は、個人情報を取得させていただく場合は、利用目的をできる限り特定するとともに、利用目的をあらかじめ公表したうえで、適法かつ公正な手段によって、必要な範囲の個人情報を取得させていただきます。
        <br />
        3．当社は、個人情報を特定した利用目的の範囲内でのみ利用します。また、特定した利用目的の達成に必要な範囲を超えた個人情報の取扱いを防止するための措置を講じます。
        <br />
        4．当社は、法令が認める場合を除き、当該個人情報によって識別されるご本人（以下、ご本人）の同意を得ずには、第三者への個人情報の提供・開示等はいたしません。
        <br />
        5．当社は、個人情報の保護を実践するため、個人情報の取得・提供・取扱いの委受託等に関する外部との手続を、適切に整備・運用します。
        <br />
        6．当社は、保有する個人情報について、ご本人の個人情報開示・修正・削除依頼に対しては別途定める手続に則って合理的な範囲ですみやかに対応させていただきます。
        <br />
        7．当社は、個人情報の漏えい、滅失またはき損などの危険を防止および是正するための、社内規程や責任体制を定め、合理的で適正な安全対策を講じます。
        <br />
        8．当社は、当社に対し個人情報の取扱いに関する苦情及びご相談がある場合は、適切かつ迅速に対応します。
        <br />
        9．当社は、個人情報保護に関するマネジメントシステム（本方針、個人情報保護に関する規程･規則等を含む）を構築し、これを従業員その他関係者に周知徹底させて実施・維持するとともに、継続的に改善します。
        <br />
        10．個人情報保護・取扱いの考え方及び方法については適宜改善・改良を行います。従いまして本方針は予告無しに変更されることがあります。
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
