import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import Images from 'assets/images';
import { TUser, TUserInfo } from 'types/user';
import LoadingSpinner from 'components/inner-loading-spinner';

import styles from './styles.module.scss';

interface IProps {
  users: Array<TUser | TUserInfo>;
  rightButton: (user: any) => any;
  loadMore?: () => void;
  hasMore?: boolean;
  isFetching?: boolean;
}

const UserList = ({
  users,
  rightButton,
  loadMore,
  hasMore,
  isFetching = false,
}: IProps) => {
  const navigate = useNavigate();

  return (
    <div>
      <InfiniteScroll
        dataLength={users.length}
        next={() => loadMore && loadMore()}
        hasMore={hasMore || false}
        loader={null}
      >
        {users.map((user) => (
          <div key={user.id} className={styles.item}>
            <div
              className={styles.left}
              onClick={() => navigate(`/${user.id}`)}
            >
              <img
                src={user.avatar?.small || Images.imgDefaultAvatar}
                alt=""
              />
              <div className={styles.nameContainer}>
                <div className={styles.nickname}>{user.name}</div>
                <div className={styles.username}>{user.nick_name}</div>
              </div>
            </div>
            {rightButton(user)}
          </div>
        ))}
      </InfiniteScroll>
      <LoadingSpinner visible={isFetching} />
    </div>
  );
};

export default UserList;
