export const emailValidation = (email: string) => {
  if (!/^[a-zA-Z0-9.]{1,30}@[a-zA-Z0-9]+\..{2,10}$/.test(email)) {
    return false;
  } else {
    const username = email.split(/(@)/)[0];
    if (
      /^\./.test(username) ||
      /\.$/.test(username) ||
      username.includes('..')
    ) {
      return false;
    }
  }
  return true;
};

export const urlValidation = (url: string) => {
  const isValid =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
      url
    );
  if (!isValid && url && url.length > 0) {
    return false;
  }
  return true;
};

export const tagProductNameValidation = (name: string) => {
  if (name.length > 15) return false;
  else return true;
};
