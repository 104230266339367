import { useState } from 'react';
import { useMutation } from 'react-query';

import TextInputField from 'components/text-input-field';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';
import { uploadCreatorProfile } from 'services/user';

import styles from './styles.module.scss';

interface IProps {
  initialData?: {
    instagram: string;
    facebook: string;
    youtube: string;
    tiktok: string;
    twitter: string;
    activity: string;
  };
}

const CreatorSns = ({ initialData }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;

  const [info, setInfo] = useState(
    initialData || {
      instagram: '',
      facebook: '',
      youtube: '',
      tiktok: '',
      twitter: '',
      activity: '',
    }
  );

  const mutation = useMutation(
    () =>
      uploadCreatorProfile({
        ...info,
        document: uiStore.verificationDocumentId,
      }),
    {
      onSuccess: () =>
        uiStore.showAlertBox({
          title: 'すべて完了して確認中です',
          content:
            '確認が完了し次第、本サービスをご利用いただけますようになります。確認が完了しましたらメールにてお知らせ致します。',
          buttons: [
            {
              title: '閉じる',
            },
          ],
        }),
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        自身の保有しているSNSのURLをご入力ください。
        {/* <br /> */}
        すべて任意の項目となります。
      </div>
      <div className="w-100">
        <TextInputField
          className={styles.item}
          label="instagram"
          placeholder="URLを入力してください"
          value={info.instagram}
          onChange={(text) => setInfo({ ...info, instagram: text })}
        />
        <TextInputField
          className={styles.item}
          label="Facebook"
          placeholder="URLを入力してください"
          value={info.facebook}
          onChange={(text) => setInfo({ ...info, facebook: text })}
        />
        <TextInputField
          className={styles.item}
          label="Youtube"
          placeholder="URLを入力してください"
          value={info.youtube}
          onChange={(text) => setInfo({ ...info, youtube: text })}
        />
        <TextInputField
          className={styles.item}
          label="Tiktok"
          placeholder="URLを入力してください"
          value={info.tiktok}
          onChange={(text) => setInfo({ ...info, tiktok: text })}
        />
        <TextInputField
          className={styles.item}
          label="Twitter"
          placeholder="URLを入力してください"
          value={info.twitter}
          onChange={(text) => setInfo({ ...info, twitter: text })}
        />
        <div className={styles.item}>
          <label>その他活動しているもの</label>
          <textarea
            placeholder="活動内容記入"
            rows={5}
            value={info.activity}
            onChange={(event) =>
              setInfo({ ...info, activity: event.target.value })
            }
          />
        </div>
      </div>
      <button onClick={() => mutation.mutate()}>完了</button>
    </div>
  );
};

export default CreatorSns;
