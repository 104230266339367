import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';

export const showToast = (message: string) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 2000,
    closeOnClick: true,
    hideProgressBar: false,
    theme: 'colored',
    pauseOnFocusLoss: false,
    draggable: false,
    progress: undefined,
    pauseOnHover: false,
    closeButton: false,
    className: styles.toast,
  });
};

const ToastNotify = () => {
  return <ToastContainer limit={3} style={{ top: '8%' }} />;
};

export default ToastNotify;
