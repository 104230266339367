import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Spinner } from 'react-bootstrap';
import { useIsFetching, useIsMutating } from 'react-query';

import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';

import './styles.scss';

const LoadingSpinner = observer(() => {
  const uiStore: UIStore = useStores().uiStore;

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  useEffect(() => {
    if (isMutating > 0) {
      uiStore.showLoading();
    } else uiStore.hideLoading();
  }, [isMutating]);

  return (
    <div id="spinner-container" className={uiStore.isLoading ? 'show' : ''}>
      <Spinner id="spinner" animation="border" />
    </div>
  );
});

export default LoadingSpinner;
