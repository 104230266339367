import { useRef } from 'react';
import { useLocation, Outlet, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useMutation, useQuery } from 'react-query';
import { getPostDetail, updatePost } from 'services/post';
import EditVideoStore from 'stores/edit-video';
import useStores from 'hooks/useStores';
import Layout from 'components/layout';
import Header from 'components/header';
import styles from './styles.module.scss';
import { PostDetail } from 'types/file';

const EditVideo = observer(() => {
  const { id: videoId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef<any>();
  const editVideoStore: EditVideoStore = useStores().editVideoStore;
  const videoDetail = useQuery<PostDetail>(
    ['post-detail', videoId],
    () => getPostDetail(+videoId!),
    {
      onSuccess: (data) => {
        editVideoStore.setVideoLink(data.files[0].origin);
        editVideoStore.setContent(data.content);
        editVideoStore.setDefaultProducts(data.files[0].product_tags);
        editVideoStore.setFileId(data.files[0].id);
        videoRef.current.load();
        console.log(data);
      },
      onError: (_err) => {
        navigate('/video');
      },
    }
  );

  const updateVideoMutation = useMutation(
    (payload: any) => updatePost(payload.id, payload.data),
    {
      onSuccess: (_data) => {
        navigate(-1);
        editVideoStore.clearStore();
      },
    }
  );

  const onBackClick = () => {
    if (location.pathname.endsWith('/edit')) {
      editVideoStore.clearStore();
    }
    navigate(-1);
  };

  const rightBtn = () => {
    const saveBtnClick = () => {
      if (location.pathname.endsWith('/edit')) {
        updateVideo();
      } else {
        navigate(-1);
      }
    };

    return (
      <button
        className={styles.share}
        disabled={!editVideoStore.areTagsValid}
        onClick={saveBtnClick}
      >
        完了
      </button>
    );
    //  完了 ~ complete
  };

  // Waiting for API
  const updateVideo = () => {
    const content = editVideoStore.content;
    const fileId = editVideoStore.fileId;
    const products = editVideoStore.products.filter(
      (product) => product.title !== '' && product.link !== ''
    );

    updateVideoMutation.mutate({
      id: +videoId!,
      data: {
        content,
        files: [
          {
            id: fileId,
            product_tags: products,
          },
        ],
      },
    });
  };

  return (
    <Layout>
      <div className="d-flex flex-column">
        <Header
          title="情報を編集" // edit information
          back
          bordered
          onBackClick={onBackClick}
          rightComponent={rightBtn()}
        />

        <div>
          <video
            className={styles.video}
            preload="metadata"
            muted
            autoPlay
            loop
            playsInline
            ref={videoRef}
          >
            <source src={editVideoStore.videoLink} type="video/mp4" />
          </video>
        </div>

        <Outlet />
      </div>
    </Layout>
  );
});

export default EditVideo;
