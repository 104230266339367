import { useNavigate } from 'react-router-dom';
import Header from 'components/header';
import Layout from 'components/layout';
import icons from '../../../../assets/images';
import styles from "./styles.module.scss";

const Security = () => {
  const navigate = useNavigate();

  return (
    <Layout bottomNavBar>
      <Header title="セキュリティ" back bordered />

      <button
        className={styles.button}
        onClick={() => navigate('/settings/change-password')}
      >
        <p>パスワード変更</p>
        <img src={icons.icNext} alt="" />
      </button>

      <button
        className={styles.button}
        onClick={() => navigate('/settings/emails')}
      >
        <p>ENTAMAからのメール</p>
        <img src={icons.icNext} alt="" />
      </button>
    </Layout>
  );
};

export default Security;
