import { TPostSettings } from 'types/post';

export const enum LocalStorageKey {
  Email = 'affiliate-email',
  Password = 'affiliate-password',
  LatestActivityId = 'affiliate-latest-activity-id',
}

export const enum PostType {
  Image = 1,
  Video = 2,
}

export const DEFAULT_POST_SETTINGS: TPostSettings = {
  show_number_like: true,
  show_comment: true,
  share_instagram: false,
  share_twitter: false,
};

export const DATE_FORMAT = 'YYYY/MM/DD';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm';

export const LIMIT = 20;
export const PAGE = 1;

export const enum VerificationStatus {
  NotSubmitted = 0,
  Waiting = 1,
  Verified = 2,
}

export const enum UserRole {
  Normal = 1,
  Creator = 2,
}

export const PRODUCT_CATEGORIES = [
  {
    key: '商品一覧',
    value: 0,
  },
  {
    key: 'おすすめ',
    value: 1,
  },
  {
    key: 'ファッション',
    value: 2,
  },
  {
    key: 'コスメ',
    value: 3,
  },
  {
    key: 'グッズ',
    value: 4,
  },
];

export const PHOTO_MAX_SIZE = 10485760;
export const VIDEO_MAX_SIZE = 26214400;

export const WEBVIEW_MESSAGE = {
  FILE_SELECTOR_CREATE_IMAGE_POST: 'FILE_SELECTOR_CREATE_IMAGE_POST',
  LOGIN_SNS_TWITTER: "LOGIN_SNS_TWITTER",
  LOGIN_SNS_LINE: "LOGIN_SNS_LINE",
  REGIST_SNS_TWITTER: "REGIST_SNS_TWITTER",
  REGIST_SNS_LINE: "REGIST_SNS_LINE",
  GET_FCM_TOKEN: "GET_FCM_TOKEN",
  NAVIGATION: "NAVIGATION",
  GET_STATUS_BAR_HEIGHT: "GET_STATUS_BAR_HEIGHT"
}
export const EMAIL_REGEX = new RegExp('^[\\w-+\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
export const NICKNAME_REGEX = new RegExp('^(?=.*[a-z])[a-zA-Z0-9_.+-]{6,32}$');
export const PASSWORD_REGEX = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z]).{6,32}$');
