import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { TProduct } from 'types/product';
import LoadingSpinner from 'components/inner-loading-spinner';

import styles from './styles.module.scss';

interface IProps {
  products: Array<TProduct>;
  loadMore: () => any;
  hasMore: boolean;
  isFetching: boolean;
}

const ProductSearchList = ({
  products,
  loadMore,
  hasMore,
  isFetching,
}: IProps) => {
  const navigate = useNavigate();

  return (
    <div>
      <InfiniteScroll
        dataLength={products.length}
        next={loadMore}
        hasMore={hasMore}
        loader={null}
      >
        {products.map((product) => (
          <div
            className={styles.item}
            key={product.id}
            onClick={() => navigate(`/product/${product.id}`)}
          >
            <div>{product.name}</div>
            <img src={product.images[0].small} alt="" />
          </div>
        ))}
      </InfiniteScroll>
      <LoadingSpinner visible={isFetching} />
    </div>
  );
};

export default ProductSearchList;
