import { FunctionComponent } from 'react';
import { action, makeObservable, observable } from 'mobx';

export default class UIStore {
  @observable isLoading: boolean = false;

  @observable actionSheet: {
    visible: boolean;
    buttons?: Array<{
      title: string;
      onPress: () => void;
      isRed?: boolean;
    }>;
  } = { visible: false, buttons: [] };

  @observable alertBox: {
    visible: boolean;
    title?: string;
    content?: string;
    contentComponent?: FunctionComponent;
    buttons?: Array<{
      title: string;
      isRed?: boolean;
      onPress?: () => void;
    }>;
    onBackdropPress?: () => void;
  } = { visible: false };

  @observable bottomSheet: {
    visible: boolean;
    content?: any;
  } = { visible: false };

  @observable savedIndex: number = 0;

  @observable verificationDocumentId: number | null = null;

  @observable isReadingVideoCaption: boolean = false;

  @observable quickComment: {
    isCommenting: boolean;
    postId: number;
  } = {
      isCommenting: false,
      postId: 0,
    };

  @observable isNewActivity: boolean = false;

  @observable appStatusBarHeight: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action showLoading() {
    this.isLoading = true;
  }

  @action hideLoading() {
    this.isLoading = false;
  }

  @action showActionSheet(
    buttons: Array<{ title: string; onPress: () => void; isRed?: boolean }>
  ) {
    this.actionSheet = { visible: true, buttons };
  }

  @action hideActionSheet() {
    this.actionSheet = { visible: false };
  }

  @action showAlertBox(body: {
    title: string;
    content?: string;
    contentComponent?: any;
    buttons?: Array<{ title: string; onPress?: () => void; isRed?: boolean }>;
    onBackdropPress?: () => void;
  }) {
    this.alertBox = { visible: true, ...body };
  }

  @action hideAlertBox() {
    this.alertBox = { visible: false };
  }

  @action hideBottomSheet() {
    this.bottomSheet = { visible: false };
  }

  @action showBottomSheet(content: any) {
    this.bottomSheet = { visible: true, content };
  }

  @action changeSavedIndex(index: number) {
    this.savedIndex = index;
  }

  @action setVerificationDocument(id: number) {
    this.verificationDocumentId = id;
  }

  @action setIsReadingVideoCaption(state: boolean) {
    this.isReadingVideoCaption = state;
  }

  @action showQuickComment(postId: number) {
    this.quickComment = {
      isCommenting: true,
      postId,
    };
  }

  @action closeQuickComment() {
    this.quickComment = {
      isCommenting: false,
      postId: 0,
    };
  }

  @action setIsNewActivity(state: boolean) {
    this.isNewActivity = state;
  }

  @action setAppStatusBarHeight(appStatusBarHeight: number) {
    if (isNaN(appStatusBarHeight)) {
      return;
    }
    this.appStatusBarHeight = appStatusBarHeight;
  }
}
