import { FeaturedProduct } from 'types/product';
import styles from './styles.module.scss';

interface IProps {
  product: FeaturedProduct;
}

const FeaturedProductItem = (props: IProps) => {
  return (
    <div className={styles.product}>
      <p>{props.product.name}</p>
      <div>
        <img src={props.product.images[0].origin} alt="" />
      </div>
      <p>
        <a href={props.product.url} target="_blank" rel="noreferrer">
          詳しくはこちら
        </a>
      </p>
    </div>
  );
};

export default FeaturedProductItem;
