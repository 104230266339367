import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import TextInputField from 'components/text-input-field';

import styles from './styles.module.scss';
import { tagProductNameValidation, urlValidation } from 'utils/validation';
import EditPostStore from 'stores/edit-post';

const EditPostProductTag = observer(() => {
  const navigate = useNavigate();
  const [isTitleValid, setIsTitleValid] = useState<boolean>(true);
  const [isLinkValid, setIsLinkValid] = useState<boolean>(true);
  const uiStore: UIStore = useStores().uiStore;
  const editPostStore: EditPostStore = useStores().editPostStore;

  const [isTagging, setIsTagging] = useState(false);
  const [productInfo, setProductInfo] = useState({ title: '', link: '' });

  const isTagValid = useMemo(() => {
    return (
      productInfo.title.length < 15 &&
      productInfo.title.length > 0 &&
      urlValidation(productInfo.link) &&
      productInfo.link.length > 0
    );
  }, [productInfo]);

  const renderInputs = () => (
    <div className={styles.inputs}>
      <TextInputField
        label="タグ名"
        placeholder="タグ名を入力してください"
        value={productInfo.title}
        onChange={(text: string) =>
          setProductInfo({ ...productInfo, title: text })
        }
      />
      {!isTitleValid && (
        <p className={styles.error}>タグ名は15文字以内で入力してください。</p>
      )}

      <TextInputField
        label="商品URL"
        placeholder="商品ページのURLを入力してください"
        value={productInfo.link}
        onChange={(text: string) =>
          setProductInfo({ ...productInfo, link: text })
        }
      />
      {!isLinkValid && <p className={styles.error}>無効なURLです。</p>}

      <button
        className={styles.addBtn}
        onClick={addProductTag}
        disabled={!isTagValid}
      >
        登録
      </button>
    </div>
  );

  const addProductTag = () => {
    editPostStore.setProductTag(productInfo.title, productInfo.link);
    setProductInfo({ title: '', link: '' });
    setIsTagging(false);
  };

  useEffect(() => {
    const index = editPostStore.activeImageIndex;
    if (
      editPostStore.currentPosition !== null &&
      editPostStore.files[index].product_tags.length < 10
    ) {
      setIsTagging(true);
    } else {
      setIsTagging(false);
    }
  }, [editPostStore.currentPosition]);

  useEffect(() => {
    if (!tagProductNameValidation(productInfo.title)) setIsTitleValid(false);
    else setIsTitleValid(true);
  }, [productInfo.title]);

  useEffect(() => {
    if (!urlValidation(productInfo.link)) setIsLinkValid(false);
    else setIsLinkValid(true);
  }, [productInfo.link]);

  useEffect(() => {
    editPostStore.setIsReTaggingProduct(true);

    return () => {
      editPostStore.setIsReTaggingProduct(false);
      editPostStore.setCurrentPosition(); // delete current posistion
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>商品URLの追加</div>
      {!isTagging ? (
        <>
          <div className={styles.content}>
            写真をタップしてタグの位置を決定できます。
            <br />
            商品のタグ付けは10件まで可能です。
          </div>
          <button onClick={() => navigate('/edit-post/add-bottom-link')}>
            画像下部に固定リンク設置
          </button>
        </>
      ) : (
        renderInputs()
      )}
    </div>
  );
});

export default EditPostProductTag;
