import { useEffect, useState } from 'react';
import { useQuery, useInfiniteQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

import Layout from 'components/layout';
import Header from 'components/header';
import CommentInputField from '../../components/comment-input-field';
import CommentItem from '../../components/comment-item';
import { TComment, TPost } from 'types/post';
import { deleteComment, getCommentList, getPostDetail } from 'services/post';
import Images from 'assets/images';
import { getNextPageParam, transformData } from 'utils/pagination';
import SessionStore from 'stores/session';
import useStores from 'hooks/useStores';
import { TUser } from 'types/user';

import styles from './styles.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from 'components/inner-loading-spinner';

const CommentList = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const sessionStore: SessionStore = useStores().sessionStore;
  const myProfile: TUser | null = sessionStore.profile;

  const [needUpdated, setNeedUpdated] = useState(0);
  const [userBeingReplied, setUserBeingReplied] = useState('');
  const [commentParentId, setCommentParentId] = useState<number>(0);

  const postDetailQuery = useQuery(
    ['post-detail', id],
    () => getPostDetail(+id!),
    {
      onSuccess(data: any) {
        if (!data.setting.show_comment) {
          navigate(`/posts/${id}`);
        }
      },
    }
  );

  const commentQuery = useInfiniteQuery<Array<TComment>>(
    ['post-comments', id],
    ({ pageParam = 1 }) => getCommentList(pageParam, +id!),
    {
      select(data) {
        return transformData(data, 'comments');
      },
      getNextPageParam,
    }
  );

  const postInfo = useQuery<TPost>(['post-info', id], () =>
    getPostDetail(parseInt(id!))
  );

  useEffect(() => {
    if (needUpdated !== 0) {
      commentQuery.refetch();
      setNeedUpdated(0);
    }
  }, [needUpdated]);

  const onReplyHandler = (username: string, parentId: number) => {
    if (username === userBeingReplied && parentId === commentParentId) return;
    setUserBeingReplied(username);
    setCommentParentId(parentId);
  };

  const onAvatarOrUserNameClickHandler = (_event: any, userId: number) => {
    navigate(`/${userId}`);
  };

  const onDeleteCommentHandler = async (
    commentId: number,
    commentParentId?: number
  ) => {
    try {
      await deleteComment(commentId);
      if (commentParentId) {
        setNeedUpdated(commentParentId);
      } else {
        setNeedUpdated(-1);
      }
    } catch (err) {
      console.log('delete comment error: ', err);
    }
  };

  const renderPostTitle = () => {
    return (
      <>
        <div className={styles.captionContainer}>
          <div
            className="d-flex align-items-center"
            onClick={() => navigate(`/${postInfo.data?.creator.id}`)}
          >
            <img
              src={
                postInfo.data?.creator.avatar?.small || Images.imgDefaultAvatar
              }
              alt=""
            />
            <div className={styles.username}>
              {postInfo.data?.creator.nick_name}
            </div>
          </div>
          <div className={styles.caption}>{postInfo.data?.content}</div>
        </div>
      </>
    );
  };

  const renderComments = () => {
    return (
      <div>
        <InfiniteScroll
          className={styles.commentList}
          next={commentQuery.fetchNextPage}
          loader={null}
          hasMore={!!commentQuery.hasNextPage}
          dataLength={commentQuery.data?.pages.flat().length || 0}
        >
          {commentQuery.data?.pages
            .flat()
            .map((comment: TComment, _index: number) => (
              <CommentItem
                key={comment.id}
                comment={comment}
                postId={parseInt(id!)}
                onReplyHandler={onReplyHandler}
                needUpdated={needUpdated}
                setNeedUpdated={setNeedUpdated}
                onAvatarOrUserNameClickHandler={onAvatarOrUserNameClickHandler}
                onDeleteCommentHandler={onDeleteCommentHandler}
                currentUserNickName={myProfile?.nick_name || ''}
              />
            ))}
        </InfiniteScroll>
        <LoadingSpinner visible={commentQuery.isFetching} />
      </div>
    );
  };

  return (
    <Layout>
      <Header title="コメント" bordered back />
      <div style={{ minHeight: 'calc(100vh - 30px - 1.125rem * 1.5)' }}>
        {/* POST TITLE: user-avatar, content, user-name */}
        <div className="d-flex flex-column" style={{ flex: 1 }}>
          {renderPostTitle()}
          {renderComments()}

          <CommentInputField
            postId={parseInt(id!)}
            userBeingReplied={userBeingReplied}
            commentParentId={commentParentId}
            setUserBeingReplied={setUserBeingReplied}
            setCommentParentId={setCommentParentId}
            setNeedUpdated={setNeedUpdated}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CommentList;
