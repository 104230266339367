import { InfiniteData } from 'react-query';

export const transformData = (
  data: InfiniteData<any>,
  key: string = 'posts'
) => {
  return {
    pageParams: data.pageParams,
    pages: data.pages.map((page) => page.data[key]),
  };
};

export const getNextPageParam = (lastPage: any) => {
  return lastPage.data.last_page > lastPage.data.current_page
    ? lastPage.data.current_page + 1
    : undefined;
};
