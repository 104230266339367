import { useNavigate } from 'react-router-dom';
import TextArea from 'react-textarea-autosize';
import { observer } from 'mobx-react';

import Images from 'assets/images';
import UploadVideoStore from 'stores/upload-video';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';

const CreateVideoPost = observer(() => {
  const navigate = useNavigate();

  const videoStore: UploadVideoStore = useStores().uploadVideoStore;

  return (
    <div className={styles.mainContainer}>
      <div onClick={() => navigate('tag-products')}>
        商品URLの追加
        <img src={Images.icNext} alt="" />
      </div>
      <div onClick={() => navigate('advanced-settings')}>
        詳細設定
        <img src={Images.icNext} alt="" />
      </div>
      <TextArea
        placeholder="キャプションを書く"
        value={videoStore.caption}
        onChange={(event) => videoStore.setCaption(event.target.value)}
      />
    </div>
  );
});

export default CreateVideoPost;
