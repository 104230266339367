import { useEffect } from 'react';
import UIStore from 'stores/ui';
import useStores from './useStores';

const usePostWatcher = ({
  target,
  postId,
}: {
  target: any;
  postId: number;
}) => {
  const uiStore: UIStore = useStores().uiStore;
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting && postId === uiStore.quickComment.postId) {
            uiStore.closeQuickComment();
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
        rootMargin: '200px',
      }
    );

    const element = target && target.current;
    element && observer.observe(element);

    return () => {
      element && observer.unobserve(element);
    };
  }, [target.current]);
};

export default usePostWatcher;
