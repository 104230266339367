import { initializeApp } from 'firebase/app';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  runTransaction,
} from 'firebase/firestore';

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from 'config';
import { TConversation } from 'types/direct';

const COLLECTION_CONVERSATIONS = 'conversations';
const COLLECTION_MESSAGES = 'messages';
const COLLECTION_USERS = 'users';

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore();

export const conversationsRef = collection(db, COLLECTION_CONVERSATIONS);

let conversationListUnsubscribe: any = null;
let conversationUnsubscribe: any = null;
let unreadCountUnsubscribe: any = null;

export const getConversationDocumentRef = (id: string) => {
  return doc(db, COLLECTION_CONVERSATIONS, id);
};

export const getUserDocumentRef = (id: string) => {
  return doc(db, COLLECTION_USERS, id);
};

export const getMessagesCollectionRef = (conversationId: string) => {
  return collection(
    db,
    COLLECTION_CONVERSATIONS,
    conversationId,
    COLLECTION_MESSAGES
  );
};

export const getConversationList = async (
  userId: number,
  onSuccess: (conversations: Array<any>) => void
) => {
  if (conversationListUnsubscribe) conversationListUnsubscribe();

  const q = query(
    conversationsRef,
    where('members', 'array-contains', userId),
    where('lastSentTimestamp', '!=', null),
    orderBy('lastSentTimestamp', 'desc')
  );

  conversationListUnsubscribe = onSnapshot(q, async (querySnapshot) => {
    const conversations: Array<any> = [];
    const otherIds: Array<number> = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.deletedBy) {
        const deletedIndex = data.deletedBy.indexOf(userId);
        if (deletedIndex > -1) {
          const deletedTimestamp = data.deletedFrom[deletedIndex];
          if (deletedTimestamp < data.lastSentTimestamp) {
            conversations.push({ ...data, id: doc.id });
            const otherId = data.members.filter((id: number) => id !== userId);
            if (otherId.length > 0) otherIds.push(otherId[0]);
          }
        } else {
          conversations.push({ ...data, id: doc.id });
          const otherId = data.members.filter((id: number) => id !== userId);
          if (otherId.length > 0) otherIds.push(otherId[0]);
        }
      } else {
        conversations.push({ ...data, id: doc.id });
        const otherId = data.members.filter((id: number) => id !== userId);
        if (otherId.length > 0) otherIds.push(otherId[0]);
      }
    });

    const users = await getUsersInfo(otherIds);
    onSuccess(
      conversations.map((conversation) => {
        const otherId: Array<number> = conversation.members.filter(
          (id: number) => id !== userId
        );
        if (otherId.length > 0 && users[otherId[0].toString()]) {
          return {
            ...conversation,
            user: users[otherId[0].toString()],
          };
        }
        return conversation;
      })
    );
  });
};

export const detachConversationListListener = () => {
  conversationListUnsubscribe && conversationListUnsubscribe();
};

export const getUsersInfo = async (userIds: Array<number>) => {
  const promises = userIds.map((id) => getUserInfo(id));
  const users: { [key: string]: any } = {};

  const snapshots = await Promise.all(promises);
  snapshots.forEach((snapshot) => {
    if (snapshot.exists()) {
      users[snapshot.id] = snapshot.data();
    }
  });

  return users;
};

export const getUserInfo = (userId: number) => {
  return getDoc(getUserDocumentRef(userId.toString()));
};

export const getConversationById = async (
  id: string,
  myId: number,
  onSuccess: (c: TConversation | null) => void
) => {
  if (conversationUnsubscribe) conversationUnsubscribe();

  conversationUnsubscribe = onSnapshot(
    getConversationDocumentRef(id),
    async (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const otherId = data.members.find((id: number) => id !== myId);
        if (otherId) {
          const userDoc = await getUserInfo(otherId);
          if (userDoc.exists())
            onSuccess({
              ...data,
              id: doc.id,
              user: userDoc.data(),
            } as TConversation);
          else onSuccess(null);
        }
      } else onSuccess(null);
    }
  );
};

export const detachConversationListener = () => {
  conversationUnsubscribe && conversationUnsubscribe();
};

export const detachUnreadCountListener = () => {
  unreadCountUnsubscribe && unreadCountUnsubscribe();
};

export const attachUnreadCountListener = (myId: number, onSuccess: any) => {
  if (unreadCountUnsubscribe) unreadCountUnsubscribe();

  const q = query(conversationsRef, where('members', 'array-contains', myId));

  unreadCountUnsubscribe = onSnapshot(q, async (querySnapshot) => {
    let unreadCount = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const lastSenderId = data.lastMessage ? data.lastMessage.senderId : null;
      if (lastSenderId && lastSenderId !== myId && data.unreadCount > 0) {
        unreadCount++;
      }

      onSuccess(unreadCount);
    });
  });
};

export const getConversation = async (myId: number, otherId: number) => {
  const q = query(conversationsRef, where('members', 'array-contains', myId));
  const snapshot = await getDocs(q);
  let conversation: any = null;
  snapshot.docs.forEach((doc) => {
    const data = doc.data();
    if (data.members.includes(myId) && data.members.includes(otherId)) {
      conversation = { ...data, id: doc.id };
      return true;
    }
  });

  return conversation;
};

export const createConversation = async (myId: number, otherId: number) => {
  const docRef = await addDoc(conversationsRef, {
    members: [myId, otherId],
    lastSentTimestamp: null,
    unreadCount: 0,
    lastMessage: null,
  });
  return docRef.id;
};

export const clearUnreadCount = async (id: string) => {
  await updateDoc(getConversationDocumentRef(id), {
    unreadCount: 0,
  });
};

export const deleteConversations = async (
  myId: number,
  conversationIds: Array<string>
) => {
  for (let conversationId of conversationIds) {
    await deleteConversation(myId, conversationId);
  }
};

export const deleteConversation = async (
  myId: number,
  conversationId: string
) => {
  await runTransaction(db, async (transaction) => {
    const conversationDocRef = getConversationDocumentRef(conversationId);
    const conversationDoc = await getDoc(conversationDocRef);
    if (conversationDoc.exists()) {
      const data = conversationDoc.data();
      const lastSentTimestamp = data.lastSentTimestamp;
      const deletedBy = data.deletedBy || [];
      const deletedIndex = deletedBy.indexOf(myId);
      let deletedFrom = data.deletedFrom || [];
      if (deletedIndex > -1) {
        deletedFrom[deletedIndex] = lastSentTimestamp;
      } else {
        deletedBy.push(myId);
        deletedFrom[deletedBy.length - 1] = lastSentTimestamp;
      }
      let unreadCount = data.unreadCount;
      if (data.lastMessage?.senderId != myId) {
        unreadCount = 0;
      }
      transaction.update(conversationDocRef, {
        deletedBy,
        deletedFrom,
        unreadCount,
      });
    } else {
      throw new Error('Document does not exist');
    }
  });
};
