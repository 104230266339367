import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import CommentInputField from 'modules/home/components/comment-input-field';
import CommentItem from 'modules/home/components/comment-item';
import useStores from 'hooks/useStores';
import { getCommentList, deleteComment } from 'services/post';
import SessionStore from 'stores/session';
import { TComment } from 'types/post';
import { TUser } from 'types/user';

import styles from './styles.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { transformData, getNextPageParam } from 'utils/pagination';
import LoadingSpinner from 'components/inner-loading-spinner';

interface IProps {
  postId: number;
}

const VideoComments = ({ postId }: IProps) => {
  const navigate = useNavigate();
  const sessionStore: SessionStore = useStores().sessionStore;
  const currentUserProfile: TUser | null = sessionStore.profile;

  const [needUpdated, setNeedUpdated] = useState(0);
  const [userBeingReplied, setUserBeingReplied] = useState('');
  const [commentParentId, setCommentParentId] = useState<number>(0);

  const commentQuery = useInfiniteQuery(
    ['video-comments', postId],
    ({ pageParam = 1 }) => getCommentList(pageParam, postId),
    {
      select(data) {
        return transformData(data, 'comments');
      },
      getNextPageParam,
    }
  );

  useEffect(() => {
    if (needUpdated !== 0) {
      commentQuery.refetch();
      setNeedUpdated(0);
    }
  }, [needUpdated]);

  const onReplyHandler = (username: string, parentId: number) => {
    if (username === userBeingReplied && parentId === commentParentId) return;
    setUserBeingReplied(username);
    setCommentParentId(parentId);
  };

  const onAvatarOrUserNameClickHandler = (_event: any, userId: number) => {
    navigate(`/${userId}`);
  };

  const onDeleteCommentHandler = async (
    commentId: number,
    commentParentId?: number
  ) => {
    try {
      await deleteComment(commentId);
      if (commentParentId) {
        setNeedUpdated(commentParentId);
      } else {
        setNeedUpdated(-1);
      }
    } catch (err) {
      console.log('delete comment error: ', err);
    }
  };

  const renderComments = () => {
    return (
      <>
        <div
          className={styles.commentList}
          onScroll={(event: any) => {
            // InfiniteScroll here doesn't work because its scroll event
            // is bound to the document.body so we have to come up with a
            // work around.
            if (
              event.target.scrollTop + event.target.clientHeight >
              event.target.scrollHeight - 100
            ) {
              if (
                !!commentQuery.hasNextPage &&
                !commentQuery.isFetchingNextPage
              ) {
                commentQuery.fetchNextPage();
              }
            }
          }}
        >
          {commentQuery.data?.pages.flat().map((comment: TComment) => (
            <CommentItem
              key={comment.id}
              comment={comment}
              postId={postId}
              onReplyHandler={onReplyHandler}
              needUpdated={needUpdated}
              setNeedUpdated={setNeedUpdated}
              onAvatarOrUserNameClickHandler={onAvatarOrUserNameClickHandler}
              onDeleteCommentHandler={onDeleteCommentHandler}
              currentUserNickName={currentUserProfile?.nick_name || ''}
            />
          ))}
        </div>
        <LoadingSpinner visible={commentQuery.isFetching} />
      </>
    );
  };

  return (
    <div className={styles.container}>
      {renderComments()}

      {sessionStore.profile && (
        <CommentInputField
          postId={postId}
          userBeingReplied={userBeingReplied}
          commentParentId={commentParentId}
          setUserBeingReplied={setUserBeingReplied}
          setCommentParentId={setCommentParentId}
          setNeedUpdated={setNeedUpdated}
          position="absolute"
        />
      )}
    </div>
  );
};

export default VideoComments;
