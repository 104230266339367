import BottomNavBar from '../bottom-nav-bar';
// import Div100vh from '../div-100vh';
import Div100vh from 'react-div-100vh';

interface IProps {
  bottomNavBar?: boolean;
  children?: any;
  className?: string;
}

const Layout = ({ bottomNavBar = false, children, className = '' }: IProps) => {
  return (
    <Div100vh
      className={className}
      style={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: 0,
      }}
    >
      <div
        style={{
          flex: 1,
          // overflow: 'scroll',
          position: 'relative',
          // minHeight: '100vh',
          // minHeight: '100%',
          // minHeight: 'calc(100vh - 1.5rem - 33px - 1rem - 0.625rem - 23px)',
        }}
      >
        {children}
      </div>
      {bottomNavBar && <BottomNavBar />}
    </Div100vh>
  );
};

export default Layout;
