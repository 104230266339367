import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';

import Images from 'assets/images';
import Layout from 'components/layout';
import LoadingSpinner from 'components/inner-loading-spinner';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { searchPosts } from 'services/post';
import { getNextPageParam, transformData } from 'utils/pagination';
import { TPost } from 'types/post';
import { PostType } from 'consts';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';
import { isOnWebview } from 'services/webviewService';

const Discover = () => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;

  const { data, hasNextPage, fetchNextPage, isLoading, isRefetching, refetch } =
    useInfiniteQuery<Array<TPost>>(
      'posts',
      ({ pageParam = 1 }) => searchPosts(pageParam),
      {
        select(data) {
          return transformData(data);
        },
        getNextPageParam,
        cacheTime: 0,
      }
    );

  useEffect(() => {
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  return (
    <Layout bottomNavBar>
      <div className={styles.container}>
        <div className={styles.topContainer} style={{ paddingTop: uiStore.appStatusBarHeight + 10 }}>
          <div>
            <input
              placeholder="検索"
              onFocus={() => navigate('/discover/search')}
            />
          </div>
          <button onClick={() => navigate('/discover/creators')}>
            クリエイター一覧
          </button>
        </div>
        <PullToRefresh
          isPullable={isOnWebview()}
          pullingContent={""}
          onRefresh={refetch}>
          <InfiniteScroll
            dataLength={data?.pages.flat().length || 0}
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            loader={null}
            className={styles.postContainer}
          >
            {data?.pages.flat().map((post) => (
              <div key={post.id} onClick={() => navigate(`/posts/${post.id}`)}>
                {post.type === PostType.Image && (
                  <img src={post.files[0].medium} alt="" loading="lazy" />
                )}
                {post.type === PostType.Video &&
                  (post.files[0].small ? (
                    <>
                      <img src={post.files[0].small} alt="" loading="lazy" />
                      <img
                        src={Images.icVideo}
                        alt=""
                        className={styles.videoIcon}
                      />
                    </>
                  ) : (
                    <>
                      <video
                        preload="metadata"
                        playsInline
                        src={post.files[0].origin}
                      >
                        <source src={post.files[0].origin} type="video/mp4" />
                      </video>
                      <img
                        src={Images.icVideo}
                        alt=""
                        className={styles.videoIcon}
                      />
                    </>
                  ))}
              </div>
            ))}
          </InfiniteScroll>
        </PullToRefresh>
        <LoadingSpinner visible={isRefetching} />
      </div>
    </Layout>
  );
};

export default Discover;
