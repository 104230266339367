import Images from 'assets/images';

import './styles.scss';

interface IProps {
  placeholder: string;
  hasSearchIcon?: boolean;
  value?: string;
  onChange?: (text: string) => void;
  type?: 'text' | 'password';
  pattern?: string;
  required?: boolean;
}

const TextInput = ({
  placeholder,
  hasSearchIcon,
  value,
  onChange,
  type = 'text',
  pattern,
  required = false,
}: IProps) => {
  const styles = {
    backgroundImage: `${hasSearchIcon ? 'url(' + Images.icSearch + ')' : ''}`,
    backgroundPosition: `${hasSearchIcon ? '0.7em 0.6em' : ''}`,
    backgroundRepeat: `${hasSearchIcon ? 'no-repeat' : ''}`,
    padding: `${
      hasSearchIcon
        ? '0.4rem 0.5rem 0.4rem 2rem'
        : '0.5rem 0.5rem 0.5rem 0.75rem'
    }`,
  };

  return (
    <input
      className="w-100 border-0 component-text-input"
      placeholder={placeholder}
      style={styles}
      value={value}
      onChange={(event) => onChange && onChange(event.target.value)}
      type={type}
      pattern={pattern}
      required={required}
    />
  );
};

export default TextInput;
