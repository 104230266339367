import React, { useEffect, useRef } from 'react';

import Images from 'assets/images';

import styles from './styles.module.scss';

interface IProps {
  isSearching: boolean;
  setIsSearching: (arg: boolean) => void;
  setKeyword: (arg: string) => void;
  keyword: string;
}

const ProductSearchBox = ({
  isSearching,
  setIsSearching,
  setKeyword,
  keyword,
}: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const cancelHandler = () => {
    setIsSearching(false);
    setKeyword('');
  };

  useEffect(() => {
    if (isSearching) {
      inputRef.current?.focus();
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <img src={Images.icSearch} alt="" />
        <input
          className={styles.input}
          placeholder="検索"
          onFocus={() => setIsSearching(true)}
          onChange={(event) => setKeyword(event.target.value)}
          ref={inputRef}
          value={keyword}
        />
      </div>
      {isSearching && <button onClick={cancelHandler}>キャンセル</button>}
    </div>
  );
};

export default React.memo(ProductSearchBox);
