import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { getActivities, markAllReadActivities } from 'services/user';
import { queryClient } from 'utils/query-client';
import { Activity } from 'types/activity';
import ActivityFilter from '../../components/activity-filter';
import ActivityItem from '../../components/activity-item';
import Layout from 'components/layout';
import Header from 'components/header';
import Images from 'assets/images';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import SessionStore from 'stores/session';
import useUser from 'hooks/useUser';

import styles from './styles.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { transformData } from 'utils/pagination';
import LoadingSpinner from 'components/inner-loading-spinner';

const ActivityList = () => {
  const [activityType, setActivityType] = useState<string>('0,1,2,3,4');
  const [filteredList, setFilteredList] = useState<number[]>([]);
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const currentUserProfile = sessionStore.profile;

  const activityQueryData = useInfiniteQuery(
    ['activities', activityType],
    async ({ pageParam = 1 }) => await getActivities(pageParam, activityType),
    {
      getNextPageParam(lastPage, allPages) {
        const maxPages = lastPage.data.last_page;
        const nextPage = allPages.length + 1;
        return nextPage <= maxPages ? nextPage : undefined;
      },
      select(data) {
        return transformData(data, 'activities');
      },
    }
  );

  const { followMutation, unfollowMutation } = useUser(() =>
    queryClient.invalidateQueries(['activities', activityType])
  );

  const markActivityRead = async () => {
    try {
      const data = await markAllReadActivities();
      uiStore.setIsNewActivity(false);
    } catch (err: any) {
      console.log('error mark activity read: ', err, Object.keys(err));
    }
  };

  useEffect(() => {
    if (activityQueryData.isFetching) uiStore.showLoading();
    else uiStore.hideLoading();

    if(uiStore.isNewActivity) {
      markActivityRead();
    }
  }, [activityQueryData.isFetching]);

  const tempFilteredList = useRef<number[]>([]);

  const filterActivityHandler = () => {
    if (tempFilteredList.current.length === 0) {
      setActivityType('0,1,2,3,4');
    } else {
      setActivityType(tempFilteredList.current.join(','));
    }
  };

  const showFilerOptionsHandler = () => {
    uiStore.showBottomSheet(
      <ActivityFilter
        filteredList={filteredList}
        setFilteredList={setFilteredList}
      />
    );
  };

  const renderActivities = () => {
    return (
      <>
        <InfiniteScroll
          className={styles.activityContainer}
          next={activityQueryData.fetchNextPage}
          hasMore={!!activityQueryData.hasNextPage}
          loader={null}
          dataLength={activityQueryData.data?.pages.flat().length || 0}
        >
          {activityQueryData.data?.pages.flat().map((activity: Activity) => (
            <ActivityItem
              activity={activity}
              key={activity.id}
              followMutation={followMutation}
              unfollowMutation={unfollowMutation}
              currentUserId={currentUserProfile.id}
            />
          ))}
        </InfiniteScroll>
        <LoadingSpinner visible={activityQueryData.isFetching} />
      </>
    );
  };

  useEffect(() => {
    tempFilteredList.current = filteredList;
    filterActivityHandler();
  }, [filteredList]);

  return (
    <Layout bottomNavBar>
      <Header title="アクティビティ" bordered back />
      <div className={styles.container}>
        <div className={styles.filter}>
          <div>New</div>
          <button onClick={showFilerOptionsHandler}>
            <img src={Images.icFilter} alt="" />
          </button>
        </div>

        {renderActivities()}
      </div>
    </Layout>
  );
};

export default ActivityList;
