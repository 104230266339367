import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useInfiniteQuery } from 'react-query';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { transformData, getNextPageParam } from 'utils/pagination';
import {
  getImagePostsByUser,
  getVideoPostsByUser,
  getTagPostsByUser,
} from 'services/post';
import { TPost } from 'types/post';

import styles from './styles.module.scss';

interface IProps {
  userId: number;
  tabs: Array<'images' | 'movies' | 'tags' | 'shops'>;
}

const ProfilePosts = forwardRef(({ userId, tabs }: IProps, ref) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const imageQuery = useInfiniteQuery(
    ['image-posts', userId],
    ({ pageParam }) => getImagePostsByUser(userId, pageParam),
    {
      select(data) {
        return transformData(data);
      },
      getNextPageParam,
    }
  );
  const videoQuery = useInfiniteQuery(
    ['video-posts', userId],
    ({ pageParam }) => getVideoPostsByUser(userId, pageParam),
    {
      select(data) {
        return transformData(data);
      },
      getNextPageParam,
    }
  );

  const tagQuery = useInfiniteQuery(
    ['tag-posts', userId],
    ({ pageParam }) => getTagPostsByUser(userId, pageParam),
    {
      select(data) {
        return transformData(data);
      },
      getNextPageParam,
    }
  );

  const renderImageTab = () => (
    <InfiniteScroll
      className={classNames(
        activeTab !== 'images' ? 'd-none' : '',
        styles.imgContainer
      )}
      dataLength={imageQuery.data?.pages.flat().length || 0}
      next={() => {
        if (activeTab === 'images') imageQuery.fetchNextPage();
      }}
      hasMore={imageQuery.hasNextPage || false}
      loader={null}
    >
      {imageQuery.data?.pages.flat().map((post: TPost) => (
        <div key={post.id} onClick={() => navigate(`/posts/${post.id}`)}>
          <img src={post.files[0].medium} alt="" loading="lazy" />
        </div>
      ))}
    </InfiniteScroll>
  );

  const renderVideoTab = () => (
    <InfiniteScroll
      className={classNames(
        activeTab !== 'movies' ? 'd-none' : '',
        styles.videoContainer
      )}
      dataLength={videoQuery.data?.pages.flat().length || 0}
      next={() => {
        if (activeTab === 'movies') videoQuery.fetchNextPage();
      }}
      hasMore={videoQuery.hasNextPage || false}
      loader={null}
    >
      {videoQuery.data?.pages.flat().map((post: TPost) => (
        <div key={post.id} onClick={() => navigate(`/posts/${post.id}`)}>
          {(post.files[0].small ? (
            <img src={post.files[0].small} alt="" loading="lazy" />
          ) :
          (
          <video preload="metadata" playsInline>
            <source src={post.files[0].origin} type="video/mp4" />
          </video>
          ))}

        </div>
      ))}
    </InfiniteScroll>
  );

  const renderTagTab = () => (
    <InfiniteScroll
      className={classNames(
        activeTab !== 'tags' ? 'd-none' : '',
        styles.tagContainer
      )}
      dataLength={tagQuery.data?.pages.flat().length || 0}
      next={() => {
        if (activeTab === 'tags') tagQuery.fetchNextPage();
      }}
      hasMore={tagQuery.hasNextPage || false}
      loader={null}
    >
      {tagQuery.data?.pages.flat().map((post: TPost) => (
        <div key={post.id} onClick={() => navigate(`/posts/${post.id}`)}>
          <img src={post.files[0].medium} alt="" loading="lazy" />
        </div>
      ))}
    </InfiniteScroll>
  );

  useImperativeHandle(ref, () => ({

    refreshPosts() {
      return Promise.all([
        imageQuery.refetch(),
        videoQuery.refetch(),
        tagQuery.refetch()
      ]);
    }

  }));

  useEffect(() => {
    if(window.location.hash == '#movies') {
      setActiveTab('movies');
    }
  }, []);

  return (
    <div>
      <div className="d-flex flex-row justify-content-around align-items-center py-2">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={classNames(styles.tabButton, {
              [styles.active]: activeTab === tab,
            })}
            onClick={() => setActiveTab(tab)}
          >
            {tab.toUpperCase()}
          </button>
        ))}
      </div>
      {renderImageTab()}
      {renderVideoTab()}
      {renderTagTab()}
    </div>
  );
});

export default ProfilePosts;
