import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from "swiper";
import styles from './styles.module.scss';

import { getVideoPostsHome } from 'services/post';
import { transformData, getNextPageParam } from 'utils/pagination';
import { useInfiniteQuery } from 'react-query';
import { TPost } from 'types/post';
import VideoPostItemMini from 'components/video-post-item-mini';

const VideoSlide = () => {

    const { data, fetchNextPage, hasNextPage, isLoading, isRefetching } =
        useInfiniteQuery(
            ['video-posts', 'infinite'],
            ({ pageParam }) => getVideoPostsHome(pageParam),
            {
                select(data) {
                    return transformData(data);
                },
                getNextPageParam,
            }
        );

    return (
        <div>
            <span className={styles.videoSlideTitle}>MOVIES</span>
            <Swiper
                className={styles.videoSlideContainer}
                freeMode={true}
                slidesPerView={"auto"}
                modules={[FreeMode]}
                spaceBetween={4}
                onReachEnd={() => { hasNextPage && fetchNextPage() }}
            >
                {data?.pages.flat().map((post: TPost, index: number) => (
                    <SwiperSlide key={post.id} className={styles.videoItem}>
                        <VideoPostItemMini
                            key={post.id}
                            post={post}
                            invalidateQueryKey={['videos']}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default VideoSlide;