import { UseQueryResult } from 'react-query';
import styles from './styles.module.scss';

interface IProps {
  adsQuery: UseQueryResult<any, unknown>;
}

const Ads = (props: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Promotion</div>
      <div className={styles.list}>
        {props.adsQuery.isLoading
          ? ''
          : props.adsQuery.isError
            ? ''
            : props.adsQuery.data?.data.advertisements.map((ad: any) => (
              <div><a target={"_blank"} href={ad.url}>{ad.title.length > 20 ? ad.title.substr(0, 20) + '...' : ad.title}</a></div>
            ))}
      </div>
    </div>
  );
};

export default Ads;
