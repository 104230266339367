import { useEffect } from 'react';

const useTag = (positionRef: any, callback: any) => {
  const onImgClick = (event: any) => {
    const target: any = event.target;
    const rect = target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const left = (x / rect.width) * 100;
    const top = (y / rect.height) * 100;
    positionRef.current = { top, left };
    callback();
  };

  useEffect(() => {
    const elements = Array.from(
      document.getElementsByClassName('upload-image')
    );
    for (let element of elements) {
      element.addEventListener('mousedown', onImgClick);
    }
    return () => {
      for (let element of elements) {
        element.removeEventListener('mousedown', onImgClick);
      }
    };
  }, []);
};

export default useTag;
