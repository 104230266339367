import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Layout from 'components/layout';
import Header from 'components/header';
import Images from 'assets/images';

import styles from './styles.module.scss';

const Policies = () => {
  const navigate = useNavigate();

  return (
    <Layout bottomNavBar>
      <Header title="規約一覧" back bordered />
      <div className={classNames('d-flex flex-column', styles.container)}>
        <button onClick={() => navigate('/policies/terms')}>
          利用規約
          <img src={Images.icNext} alt="" />
        </button>
        <button onClick={() => navigate('/policies/privacy')}>
          個人情報保護法
          <img src={Images.icNext} alt="" />
        </button>
        <button onClick={() => navigate('/policies/law')}>
          特定表記に基づく表記
          <img src={Images.icNext} alt="" />
        </button>
        <button onClick={() => navigate('/policies/about-us')}>
          運営会社
          <img src={Images.icNext} alt="" />
        </button>
      </div>
    </Layout>
  );
};

export default Policies;
