import { useEffect, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import classNames from 'classnames';

import Layout from 'components/layout';
import ProductList from '../../components/product-list';
import ProductSearchBox from '../../components/product-search-box';
import ProductSearchList from '../../components/product-search-list';
import {
  getFeaturedProducts,
  getProductsByCategory,
  getProductsByKeyword,
} from 'services/product';
import { PRODUCT_CATEGORIES } from 'consts';
import { getNextPageParam, transformData } from 'utils/pagination';
import { TProduct } from 'types/product';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';
import FeaturedProducts from 'modules/shop/components/featured-product-container';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { isOnWebview } from 'services/webviewService';

const Shop = () => {
  const uiStore: UIStore = useStores().uiStore;

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedCategory, selectCategory] = useState(0);
  const [keyword, setKeyword] = useState<string>('');

  const categoryQuery = useInfiniteQuery<Array<TProduct>>(
    ['products', selectedCategory],
    ({ pageParam = 1 }) => getProductsByCategory(pageParam, selectedCategory),
    {
      select(data) {
        return transformData(data, 'products');
      },
      getNextPageParam,
      cacheTime: 0,
    }
  );

  const keywordQuery = useInfiniteQuery(
    ['products-by-keyword', keyword],
    ({ pageParam = 1 }) => getProductsByKeyword(pageParam, keyword),
    {
      select(data) {
        return transformData(data, 'products');
      },
      getNextPageParam,
    }
  );

  const featuredProductsQuery = useQuery(
    ['featured-products'],
    getFeaturedProducts,
    {
      select: (data) => data,
    }
  );

  const refreshCategoryResult = () => {
    return Promise.all([
      featuredProductsQuery.refetch(),
      categoryQuery.refetch()
    ])
  }

  useEffect(() => {
    if (categoryQuery.isLoading || keywordQuery.isLoading) {
      uiStore.showLoading();
    } else uiStore.hideLoading();
  }, [categoryQuery.isLoading, keywordQuery.isLoading]);

  return (
    <Layout bottomNavBar>
      <div className={styles.container} style={{ paddingTop: uiStore.appStatusBarHeight + 10 }}>
        <div className={styles.title}>ショップ</div>

        <ProductSearchBox
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          setKeyword={setKeyword}
          keyword={keyword}
        />

        {!isSearching && (
          <div className={styles.categoryContainer}>
            {PRODUCT_CATEGORIES.map((category) => (
              <div
                className={classNames(styles.category, {
                  [styles.active]: selectedCategory === category.value,
                })}
                key={category.value}
                onClick={() =>
                  selectCategory(category.value)
                }
              >
                {category.key}
              </div>
            ))}
          </div>
        )}
      </div>

      {isSearching ? (
        <PullToRefresh
          isPullable={isOnWebview()}
          pullingContent={""}
          onRefresh={keywordQuery.refetch}>
          <ProductSearchList
            products={keywordQuery.data?.pages.flat() || []}
            loadMore={keywordQuery.fetchNextPage}
            hasMore={keywordQuery.hasNextPage || false}
            isFetching={keywordQuery.isRefetching}
          />
        </PullToRefresh>
      ) : (
        <>
          <PullToRefresh
            isPullable={isOnWebview()}
            pullingContent={""}
            onRefresh={refreshCategoryResult}>
            <>
              {(!selectedCategory) && <FeaturedProducts queryData={featuredProductsQuery} />}
              <ProductList
                products={categoryQuery.data?.pages.flat() || []}
                loadMore={categoryQuery.fetchNextPage}
                hasMore={categoryQuery.hasNextPage || false}
                isFetching={categoryQuery.isRefetching}
              />
            </>
          </PullToRefresh>
        </>
      )}
    </Layout>
  );
};

export default Shop;
