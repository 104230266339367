import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import Layout from 'components/layout';
import AppBar from 'components/app-bar';
import UploadOptionsButton from 'components/upload-options-button';
import Images from 'assets/images';

import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';

import { getMyProfile, updateFcmToken } from 'services/auth';
import { WEBVIEW_MESSAGE } from 'consts';

import {
    sendMessageToNative,
    registHandleNativeMessage,
    startListenNativeMessage,
    isOnWebview,
} from 'services/webviewService';

const Splash = () => {
    const navigate = useNavigate();
    const sessionStore: SessionStore = useStores().sessionStore;
    const uiStore: UIStore = useStores().uiStore;

    uiStore.setAppStatusBarHeight(parseInt(localStorage.getItem('app_status_bar_height') as string))

    const getProfile = async () => {
        try {
            uiStore.showLoading();
            const data = await getMyProfile();
            sessionStore.setProfile(data.data);

            if (!sessionStore.profile) {
                navigate('/login-methods');
            } else {
                navigate('/feed');
            }
        } catch (err: any) {
            console.log('error in splash screen: ', err, Object.keys(err));

            if (err.message === 'E_LoginRequired') {
                navigate('/login-methods');
            }

            if (err.message === 'E_NoInternet') {
                navigate('/feed');
            }

        } finally {
            uiStore.hideLoading();
        }
    };

    useEffect(() => {
        if (isOnWebview()) {
            sendMessageToNative(WEBVIEW_MESSAGE.GET_STATUS_BAR_HEIGHT, {});
            registHandleNativeMessage(
                WEBVIEW_MESSAGE.GET_STATUS_BAR_HEIGHT,
                async (channel: string, data: any) => {
                    uiStore.setAppStatusBarHeight(data);
                    localStorage.setItem('app_status_bar_height', data);
                }
            );

            registHandleNativeMessage(WEBVIEW_MESSAGE.NAVIGATION, (channel: string, data: any) => {
                if (data.path) {
                    navigate(data.path);
                }
            });

            sendMessageToNative(WEBVIEW_MESSAGE.GET_FCM_TOKEN, {});
            registHandleNativeMessage(
                WEBVIEW_MESSAGE.GET_FCM_TOKEN,
                async (channel: string, data: any) => {
                    if (data.fcmToken && data.fcmToken != '') {
                        await updateFcmToken(data.deviceType, data.fcmToken);
                    }
                }
            );
        }

        getProfile();
    });

    return (
        <Layout bottomNavBar>
            <div onTouchEnd={() => uiStore.closeQuickComment()}>
                <AppBar>
                    <UploadOptionsButton style={{ marginRight: '1.2rem' }} />
                    <div
                        className="d-flex justify-content-center align-items-center position-relative"
                        style={{ marginRight: '1.2rem' }}
                    >
                        <img
                            src={Images.icHeart}
                            style={{
                                height: 18,
                                width: 22,
                                objectFit: 'contain',
                                cursor: 'pointer',
                            }}
                            alt=""
                        />
                    </div>
                    <div className="d-flex justify-content-center align-items-center position-relative">
                        <img
                            src={Images.icChat}
                            style={{
                                height: 21,
                                width: 22,
                                objectFit: 'contain',
                            }}
                            alt=""
                        />
                    </div>
                </AppBar>
            </div>
        </Layout>
    );
};

export default Splash;
