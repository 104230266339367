import Images from 'assets/images';
import { TUser } from 'types/user';

import styles from './styles.module.scss';

interface IProps {
  users: Array<TUser>;
  onUntag: (userId: number) => void;
}

const UserTagList = ({ users, onUntag }: IProps) => {
  return (
    <div style={{ flex: 1, overflow: 'scroll' }}>
      {users.map((user) => (
        <div className={styles.item} key={user.id}>
          <div
            className="d-flex align-items-center"
            style={{ flex: 1, minWidth: 0, marginRight: '0.5rem' }}
          >
            <img
              src={user.avatar?.origin || Images.imgDefaultAvatar}
              className={styles.avatar}
              alt=""
            />
            <div className={styles.right}>
              <div>{user.nick_name}</div>
              <div>{user.name}</div>
            </div>
          </div>
          <img
            src={Images.icClose}
            alt=""
            className={styles.close}
            onClick={() => onUntag(user.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default UserTagList;
