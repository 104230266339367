import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';

import Images from 'assets/images';

import styles from './styles.module.scss';

interface IProps {
  title: string;
  back?: boolean;
  bordered?: boolean;
  leftComponent?: any;
  rightComponent?: any;
  onBackClick?: () => void;
}

const Header = ({
  title,
  back = false,
  bordered = false,
  leftComponent,
  rightComponent,
  onBackClick,
}: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;

  return (
    <div
      className={classNames(
        'position-sticky top-0 d-flex flex-row justify-content-center align-items-center',
        styles.container,
        { [styles.bordered]: bordered }
      )}
      style={{ paddingTop: uiStore.appStatusBarHeight + 15 }}
    >
      <div
        className={classNames(
          'd-flex justify-content-center align-items-center position-absolute',
          styles.left
        )}
      >
        {leftComponent}
        {back && (
          <img
            src={Images.icBack}
            alt=""
            onClick={() => (onBackClick ? onBackClick() : navigate(-1))}
          />
        )}
      </div>

      <div className={styles.title}>{title}</div>
      <div className={classNames('position-absolute', styles.right)}>
        {rightComponent}
      </div>
    </div>
  );
};

export default Header;
