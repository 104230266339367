import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import Modal from 'components/modal';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';

import './styles.scss';

const AlertBox = observer(() => {
  const uiStore: UIStore = useStores().uiStore;

  const {
    visible,
    title,
    content,
    buttons,
    contentComponent,
    onBackdropPress,
  } = uiStore.alertBox;

  const ref = useRef<any>(null);

  useEffect(() => {
    ref.current = onBackdropPress;
  }, [onBackdropPress]);

  return (
    <Modal
      visible={visible}
      onBackdropPress={() => {
        uiStore.hideAlertBox();
        ref.current && ref.current();
      }}
    >
      <div className="component-alert-box">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="component-alert-box-title">{title}</div>
          {contentComponent}
          {content && <div className="content">{content}</div>}
        </div>
        <div className="d-flex flex-column">
          {buttons?.map((btn, index) => (
            <button
              key={index}
              className={`component-alert-box-btn ${btn.isRed ? 'red' : ''}`}
              onClick={() => {
                uiStore.hideAlertBox();
                btn.onPress && btn.onPress();
              }}
            >
              {btn.title}
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
});

export default AlertBox;
