import { LegacyRef, MutableRefObject, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import Images from 'assets/images';
import UploadPhotosStore from 'stores/upload-photos';
import UploadVideoStore from 'stores/upload-video';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import { uploadFiles } from 'services/file';
import { PHOTO_MAX_SIZE, VIDEO_MAX_SIZE } from 'consts';

import styles from './styles.module.scss';

interface IProps {
  modalRef: LegacyRef<any>;
  heightRef: MutableRefObject<number>;
}

const UploadOptions = ({ modalRef, heightRef }: IProps) => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;
  const photoStore: UploadPhotosStore = useStores().uploadPhotosStore;
  const videoStore: UploadVideoStore = useStores().uploadVideoStore;

  const photoInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);

  const photoRef = useRef<Array<File>>([]);
  const videoRef = useRef<File | null>(null);

  const photosMutation = useMutation(
    (files: Array<File>) => uploadFiles(files),
    {
      onSuccess: (data) => {
        photoStore.choosePhotos(
          photoRef.current.map((photo, index) => ({
            id: data.data[index].id,
            file: photo,
          }))
        );
        navigate('/upload-photos');
      },
    }
  );

  const videoMutation = useMutation((file: File) => uploadFiles([file]), {
    onSuccess: (data) => {
      if (videoRef.current) {
        videoStore.chooseVideo(data.data[0].id, videoRef.current);
        navigate('/upload-video');
      }
    },
  });

  const closeMenu = () => {
    let element = document.getElementById('upload-menu');
    element?.classList.add('d-none');
  };

  return (
    <div
      id="upload-menu"
      className={`d-none ${styles.container}`}
      style={{
        top: `calc(100% + ${heightRef.current - 1}px)`,
      }}
      ref={modalRef}
    >
      <div
        className="d-flex align-items-center justify-content-between"
        onClick={() => {
          closeMenu();
          photoInputRef.current?.click();
        }}
      >
        投稿
        <img src={Images.icMenuBento} alt="" />
        <input
          type="file"
          accept="image/jpeg, image/png, image/jpg, image/gif, image/svg"
          ref={photoInputRef}
          className="d-none"
          multiple
          onChange={(event) => {
            const files = event.target.files;
            if (files && files?.length > 0) {
              if (files.length > 10) {
                uiStore.showAlertBox({
                  title: '一度に10枚まで選択してください。',
                  buttons: [
                    {
                      title: 'Close',
                    },
                  ],
                });
              } else if (
                Array.from(files).some(
                  (file: File) => file.size > PHOTO_MAX_SIZE
                )
              ) {
                uiStore.showAlertBox({
                  title: '画像は10MBまでのものを登録してください。',
                  buttons: [
                    {
                      title: 'Close',
                    },
                  ],
                });
              } else {
                photoRef.current = Array.from(files);
                photosMutation.mutate(Array.from(files));
              }
            }
          }}
        />
      </div>
      <div
        className="d-flex align-items-center justify-content-between"
        onClick={() => {
          closeMenu();
          videoInputRef.current?.click();
        }}
      >
        動画
        <img src={Images.icPlayGray} alt="" style={{}} />
        <input
          type="file"
          accept="video/mp4, video/mp3, video/avi, video/flv, video/mpeg-2, video/mpeg-4, video/m3u8, video/ts, video/3gp, video/webm"
          ref={videoInputRef}
          className="d-none"
          onChange={(event) => {
            const files = event.target.files;
            if (files && files?.length > 0) {
              if (files[0].size > VIDEO_MAX_SIZE) {
                uiStore.showAlertBox({
                  title: '動画は25MBまでのものを登録してください。',
                  buttons: [
                    {
                      title: 'Close',
                    },
                  ],
                });
              } else {
                videoRef.current = files[0];
                videoMutation.mutate(files[0]);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default UploadOptions;
