import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import './styles.scss';

interface IProps {
  children?: any;
  visible: boolean;
  onBackdropPress?: () => void;
  position?: 'bottom' | 'center' | 'top';
}

const Modal = observer(
  ({ visible, children, onBackdropPress, position = 'center' }: IProps) => {
    const containerRef = useRef<any>(null);

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target) &&
          onBackdropPress
        ) {
          onBackdropPress();
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [containerRef]);

    return (
      <div
        className={`component-modal ${visible ? 'show' : ''} ${
          position === 'bottom' ? 'bottom' : ''
        }`}
      >
        <div
          className="d-flex justify-content-center align-items-center w-100 component-modal-container"
          ref={containerRef}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default Modal;
