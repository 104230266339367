// hooks
import { useEffect } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
// css
import Header from 'components/header';
import Layout from 'components/layout';
import Images from 'assets/images';
import styles from './styles.module.scss';
// components
import ImagePostItem from 'components/image-post-item';
import VideoPostItem from 'components/video-post-item';
import Ads from 'modules/post/components';
// types
import { TPost } from 'types/post';
import { PostType } from 'consts';
// services
import { getPostDetail, searchRelevantPosts } from 'services/post';
import { getNextPageParam, transformData } from 'utils/pagination';
import { getAds } from 'services/ad';

import { isOnWebview } from 'services/webviewService';

const Post = () => {
  const uiStore: UIStore = useStores().uiStore;
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id as unknown as number;

  const queryKey = ['post', id];
  const { data, isLoading } = useQuery<TPost, Error>(queryKey, () =>
    getPostDetail(id)
  );

  const relevantPostQuery = useInfiniteQuery(
    ['relevant-posts', id],
    () => searchRelevantPosts(id),
    {
      select(data) {
        return transformData(data);
      },
      getNextPageParam,
      onSuccess: (data) => {
        console.log('relevant Data: ', data);
      },
    }
  );

  const adsQuery = useQuery(['ads'], getAds, {
    select: (data) => data,
    staleTime: 60000 * 5,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isLoading) uiStore.showLoading();
    else uiStore.hideLoading();
  }, [isLoading]);

  const renderImagePost = () => {
    return (
      <>
        <Header title="ENTAMA" back bordered />
        {data && <ImagePostItem item={data} invalidateQueryKey={queryKey} />}
      </>
    );
  };

  const renderVideoPost = () => {
    return (
      data && (
        <div style={{ flex: 1}}>
          <div className={styles.videoHeader} style={{marginTop: uiStore.appStatusBarHeight }}>
            <img src={Images.icBackLight} alt="" onClick={() => navigate(-1)} />
            <div>movie</div>
          </div>
          <VideoPostItem post={data} invalidateQueryKey={queryKey} />
        </div>
      )
    );
  };

  return (
    <Layout bottomNavBar>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {data && data.type === PostType.Video ? (
          renderVideoPost()
        ) : (
          <>
            {renderImagePost()}
            <Ads adsQuery={adsQuery} />

            {!isOnWebview() && (
              <div className={styles.mobile}>
                <a href="https://play.google.com/store/apps/details?id=link.entama.entama"><img src={Images.imgGooglePlay} /></a>
                <a href="https://apps.apple.com/jp/app/entama/id1615695732"><img src={Images.imgAppStore} /></a>
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Post;
