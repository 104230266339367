import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import MentionSuggestions from '../mention-suggestions';
import SessionStore from 'stores/session';
import useStores from 'hooks/useStores';
import Images from 'assets/images';
import { postAddComment } from 'services/post';
import { searchUsers } from 'services/user';

import useLoginRequired from 'hooks/useLoginRequired';

import styles from './styles.module.scss';

interface IProps {
  userAvatar?: string;
  postId: number;
  commentParentId: number;
  userBeingReplied: string;
  setUserBeingReplied: (arg: string) => void;
  setCommentParentId: (arg: number) => void;
  setNeedUpdated: (arg: number) => void;
  position?: 'static' | 'fixed' | 'absolute' | 'sticky' | 'relative';
}

const CommentInputField = (props: IProps) => {
  const sessionStore: SessionStore = useStores().sessionStore;
  const { showLoginPopup } = useLoginRequired();

  const [comment, setComment] = useState<string>('');
  const [hasComment, setHasComment] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');
  const [isMentionActive, setMentionActive] = useState<boolean>(false);
  const {
    postId,
    commentParentId,
    userBeingReplied,
    setUserBeingReplied,
    setCommentParentId,
  } = props;
  const inputRef = useRef<any>();
  const currPosRef = useRef<number>(0);
  const start = useRef<number>(0);
  const end = useRef<number>(0);
  const mentionUsersData = useQuery(
    ['mention-users', keyword],
    async () => await searchUsers(1, keyword),
    {
      refetchOnReconnect: 'always',
    }
  );

  const commentMutation = useMutation(
    (payload: any) =>
      postAddComment(
        payload.postId,
        payload.comment,
        payload.commentParentId,
        payload.taggedUsers
      ),
    {
      onSuccess: (_data) => {
        let id = commentParentId;

        // conditional refetching
        // NeedUpdated = id, refetch all replies of a specific comment
        // all comments will get re-fetched no matter what.
        if (commentParentId === 0) {
          props.setNeedUpdated(-1);
        } else {
          props.setNeedUpdated(id);
        }

        setComment('');
        setCommentParentId(0);
        setUserBeingReplied('');
        inputRef.current.style.height = '2.5625rem';
      },
    }
  );

  const autoGrowHandler = (event: any) => {
    event.target.style.height = '2.5625rem';
    event.target.style.height = event.target.scrollHeight + 'px'; // !imporant
  };

  const inputChangeHandler = (event: any) => {
    setComment(event.target.value);
  };

  const addUserToComment = (name: string) => {
    // mention a user at the end of input
    let newComment: string;
    if (currPosRef.current === comment.length) {
      let commentCurr = comment.split(/(\s)/);
      commentCurr[commentCurr.length - 1] = '@' + name + ' ';
      newComment = commentCurr.join('');
    }
    // add user mention in the middle of input
    else {
      let newData = '@' + name + ' ';

      newComment = comment
        .slice(0, start.current)
        .concat(newData)
        .concat(comment.slice(end.current + 1));
    }
    // automatically set IsMentionActive to false by leaving a whitespace
    setComment(newComment);
    setKeyword('');

    // this will fix bottom sheet bug: current returns to first position after tagging s1
    inputRef.current.focus();
  };

  // revalidate mention state based on current cursor position
  const userCLickInsideHandler = (_event: any) => {
    // currPosRef.current = event.target.selectionStart;
    // validateMention();
  };

  // this function runs before addUserToComment
  const inputBlurHandler = (_event: any) => {
    if (isMentionActive) {
      setTimeout(() => {
        setMentionActive(false);
      }, 0);
    }
  };

  const userFocusHandler = (_event: any) => {};

  const validateMention = useCallback(() => {
    // cursor at the end of input
    if (currPosRef.current === comment.length) {
      const arr = comment.split(/(\s)/);
      if (/^@[\w]+$/.test(arr[arr.length - 1])) {
        let key = arr[arr.length - 1].replace('@', '').toLowerCase();
        setMentionActive(true);
        setKeyword(key);
      } else {
        setMentionActive(false);
      }
    }
    // cursor at the start or in the middle
    else {
      let arr = [comment[currPosRef.current]];
      let pos = currPosRef.current;
      let text: string;
      start.current = pos;
      end.current = pos;

      if (comment[currPosRef.current] !== ' ') {
        while (
          /[\w@]/.test(comment[end.current]) &&
          end.current < comment.length
        ) {
          end.current += 1;
          arr.push(comment[end.current]);
        }
      }

      if (currPosRef.current !== 0) {
        while (/[\w@]/.test(comment[start.current - 1]) && start.current > 0) {
          start.current -= 1;
          arr.unshift(comment[start.current]);
        }
      }

      text = arr.join('').trim();
      if (/^@[\w]+$/.test(text)) {
        let key = text.replace(/@/, '');
        setMentionActive(true);
        setKeyword(key);
      } else {
        if (isMentionActive) {
          setMentionActive(false);
        }
      }
    }
  }, [comment, isMentionActive]);

  const removeReplyTargetHandler = (_event: any) => {
    setCommentParentId(0);
    setUserBeingReplied('');
    setComment('');
    inputRef.current.focus();
    inputRef.current.style.height = '2.5625rem';
  };

  const submitCommentHandler = (event: any) => {
    event.preventDefault();
    let taggedUsers: string[] = [];
    let arr = comment.split(/(@[a-zA-Z0-9_]+)/g);
    let filteredUsers = arr.filter((item) => /^@[a-zA-Z0-9_]+$/.test(item));
    taggedUsers = [...new Set(filteredUsers)];

    if(sessionStore.profile) {
      commentMutation.mutate({ postId, comment, commentParentId, taggedUsers });
    } else {
      showLoginPopup();
    }

  };

  // keeping track of hasComment flag
  useEffect(() => {
    if (comment.length === 0 || /^\s+$/.test(comment) === true) {
      setHasComment(false);
    } else {
      setHasComment(true);
      inputRef.current.focus();
    }
  }, [comment]);

  // keeping track of mentions
  useEffect(() => {
    currPosRef.current = inputRef.current.selectionStart;
    validateMention();
  }, [comment]);

  useEffect(() => {
    if (userBeingReplied !== '' && commentParentId !== 0) {
      let tag = '@' + userBeingReplied + ' ';
      setComment(tag);
    }
  }, [userBeingReplied, commentParentId]);

  return (
    <>
      <div
        className={styles.container}
        style={{ position: props.position || 'fixed' }}
      >
        {isMentionActive && (
          <MentionSuggestions
            mentionUsersData={mentionUsersData}
            addUserToComment={addUserToComment}
          />
        )}

        {commentParentId !== 0 && (
          <div className={styles.replyTarget}>
            <p>@{userBeingReplied}に返信しています</p>
            <span onClick={removeReplyTargetHandler}>X</span>
          </div>
        )}

        <div className={styles.form}>
          <div className={styles.avatar}>
            <img
              src={
                sessionStore.profile && sessionStore.profile.avatar?.small || Images.imgDefaultAvatar
              }
              alt=""
              className={styles.avatar}
              loading="lazy"
              aria-label="user-avatar"
            />
          </div>
          <form>
            <textarea
              id="comment"
              name="comment"
              placeholder="コメントを入力..."
              autoComplete="off"
              autoCorrect="off"
              onInput={autoGrowHandler}
              onChange={inputChangeHandler}
              value={comment}
              ref={inputRef}
              onClick={userCLickInsideHandler}
              onFocus={userFocusHandler}
              onBlur={inputBlurHandler}
              required
              aria-label="comment-input"
            />

            <button
              type="submit"
              className={styles.sendBtn}
              disabled={!hasComment}
              onClick={submitCommentHandler}
            >
              <img src={Images.icSend} alt="" loading="lazy" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CommentInputField;
