import { useLocation, matchPath, Link, useNavigate } from 'react-router-dom';

import Images from 'assets/images';
import SessionStore from 'stores/session';
import useStores from 'hooks/useStores';

import styles from './styles.module.scss';
import useLoginRequired from 'hooks/useLoginRequired';
import { observer } from 'mobx-react';

const items = (myId?: number) => [
  {
    hrefs: ['/feed', '/posts', '/activity-list'],
    icons: {
      active: Images.icHomeActive,
      inactive: Images.icHome,
    },
  },
  {
    hrefs: ['/discover'],
    icons: {
      active: Images.icDiscoverActive,
      inactive: Images.icDiscover,
    },
  },
  {
    hrefs: ['/video'],
    icons: {
      active: Images.icPlay,
      inactive: Images.icPlay,
    },
  },
  {
    hrefs: ['/shop', '/product'],
    icons: {
      active: Images.icCartActive,
      inactive: Images.icCart,
    },
  },
  {
    hrefs: myId
      ? [`/${myId}`, '/settings', '/policies']
      : ['/settings', '/policies'],
    icons: {
      active: Images.icProfileActive,
      inactive: Images.icProfile,
    },
  },
];

const BottomNavBar = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const sessionStore: SessionStore = useStores().sessionStore;

  const { showLoginPopup } = useLoginRequired();

  return (
    <div
      className={`d-flex justify-content-around align-items-center ${styles.container}`}
    >
      {items(sessionStore.profile?.id).map((item, index) => {
        const isActive =
          item.hrefs.filter(
            (href) => !!matchPath({ path: href, end: false }, location.pathname)
          ).length > 0;
        return (
          <div
            key={index}
            onClick={() => {
              if (index === 4) {
                if (sessionStore.profile) {
                  navigate(item.hrefs[0]);
                } else showLoginPopup();
              } else navigate(item.hrefs[0]);
            }}
          >
            <img
              className={index === 2 ? styles.dropShadow : ''}
              src={isActive ? item.icons.active : item.icons.inactive}
              style={index === 2 ? {width: 32} : { width: 20 }}
              alt=""
            />
          </div>
        );
      })}
    </div>
  );
});

export default BottomNavBar;
