import Images from 'assets/images';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';

import styles from './styles.module.scss';

const ReportSuccess = () => {
  const uiStore: UIStore = useStores().uiStore;

  return (
    <div className={styles.reportSuccessContainer}>
      <div>
        <img src={Images.icChecked} alt="" />
      </div>
      <div>ご報告ありがとうございます</div>
      <div className={styles.content}>
        <p> いただいた情報は、ENTAMAコミュニティをより安全なものに</p>
        <p> するために役立たせていただきます。</p>
      </div>
      <button onClick={() => uiStore.hideBottomSheet()}>キャンセル</button>
    </div>
  );
};

export default ReportSuccess;
