import { observer } from 'mobx-react';

import Modal from 'components/modal';
import useStores from 'hooks/useStores';
import UIStore from 'stores/ui';

import './styles.scss';

const ActionSheet = observer(() => {
  const uiStore: UIStore = useStores().uiStore;

  const { visible, buttons } = uiStore.actionSheet;

  return (
    <Modal visible={visible} position="bottom">
      <div className="d-flex flex-column component-action-sheet">
        {buttons && (
          <div className="d-flex flex-column top-container">
            {buttons.map((btn, index) => (
              <button
                key={index}
                className={btn.isRed ? 'red' : ''}
                onClick={() => {
                  uiStore.hideActionSheet();
                  btn.onPress();
                }}
              >
                {btn.title}
              </button>
            ))}
          </div>
        )}
        <button onClick={() => uiStore.hideActionSheet()}>キャンセル</button>
      </div>
    </Modal>
  );
});

export default ActionSheet;
