import useStores from 'hooks/useStores';
import { useState } from 'react';
import UIStore from 'stores/ui';
import styles from './styles.module.scss';
interface IProps {
  setFilteredList: any;
  filteredList: number[];
}

const activities = [
  { key: 'タグ', type: 1 }, // tag
  { key: 'いいね！', type: 2 }, // like
  { key: 'コメント', type: 3 }, // comment
  { key: 'フォロー', type: 4 }, // follow
  { key: 'その他', type: 0 }, // other
];

const ActivityFilter = (props: IProps) => {
  const { filteredList, setFilteredList } = props;
  const [helperList, setHelperList] = useState<number[]>(filteredList);
  const uiStore: UIStore = useStores().uiStore;

  const toggle = (event: any, type: number) => {
    let newHelperList: number[] = [];
    if (event.target.checked) {
      setHelperList((state: number[]) => {
        newHelperList = [...state];
        newHelperList.push(type);
        newHelperList.sort();
        return newHelperList;
      });
    } else {
      setHelperList((state: number[]) => {
        newHelperList = state.filter((item: number) => item !== type);
        newHelperList.sort();
        return newHelperList;
      });
    }
  };

  const clearAllFilterOptionsHandler = (_event: any) => {
    setHelperList([]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>フィルター</div>
        <button className={styles.clear} onClick={clearAllFilterOptionsHandler}>
          クリア
        </button>
      </div>

      {/* tag, like, comment, follow */}
      <div className={styles.body}>
        {activities.map((activity) => (
          <div className={styles.item} key={activity.type}>
            <label htmlFor="activity">{activity.key}</label>
            <input
              type="checkbox"
              name="activity"
              onChange={(event) => toggle(event, activity.type)}
              checked={helperList.includes(activity.type)}
            />
          </div>
        ))}
        <button
          onClick={(_event) => {
            setFilteredList(helperList);
            uiStore.hideBottomSheet();
          }}
        >
          適応する
        </button>
      </div>
    </div>
  );
};

export default ActivityFilter;
