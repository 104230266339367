declare const window: any;

export const isOnWebview = () => {
  return !!window.ReactNativeWebView;
};

export const sendMessageToNative = (channel: string, data: any) => {
  const msg = {
    channel,
    data,
  };
  const { ReactNativeWebView } = window;
  if (ReactNativeWebView) {
    ReactNativeWebView.postMessage(JSON.stringify(msg));
  }
};

let handleFunc: { [key: string]: (channel: string, data: any) => any } = {};

export const registHandleNativeMessage = (
  channel: string,
  handle: (channel: string, data: any) => any
) => {
  handleFunc[channel] = handle;
};

export const startListenNativeMessage = () => {
  window.onRNMessage = (message: string) => {
    const { channel, data } = JSON.parse(message);
    if (handleFunc[channel]) handleFunc[channel](channel, data);
  };
};
