import { useEffect } from 'react';

export const useVideoWatcher = ({
  target,
  pause,
  play,
}: {
  target: any;
  pause: () => void;
  play?: () => void;
}) => {
  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1,
      rootMargin: '0px',
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          pause();
        }
      });
    }, options);

    const element = target && target.current ? (target.current.wrapper ? target.current.wrapper : target.current) : null;
    if (!element) return;

    observer.observe(element);

    return () => observer.unobserve(element);
  }, [target.current]);
};
