import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import Header from 'components/header';
import Layout from 'components/layout';
import TextInputField from 'components/text-input-field';
import useStores from 'hooks/useStores';
import UploadPhotosStore from 'stores/upload-photos';

import styles from './styles.module.scss';
import { urlValidation } from 'utils/validation';

const AddBottomLink = observer(() => {
  const navigate = useNavigate();
  const photosStore: UploadPhotosStore = useStores().uploadPhotosStore;
  const { photos, currentIndex } = photosStore;

  const [name, setName] = useState(photos[currentIndex].bottomLink?.name || '');
  const [url, setUrl] = useState(photos[currentIndex].bottomLink?.url || '');
  const [isTagNameValid, setIsTagNameValid] = useState<boolean>(true);
  const [isURLValid, setIsURLValid] = useState<boolean>(true);

  useEffect(() => {
    if (name.length > 15) setIsTagNameValid(false);
    else setIsTagNameValid(true);
  }, [name]);

  useEffect(() => {
    if (!urlValidation(url)) setIsURLValid(false);
    else setIsURLValid(true);
  }, [url]);

  return (
    <Layout>
      <Header
        title="固定リンク追加"
        bordered
        back
        rightComponent={
          <button
            className={styles.share}
            onClick={() => {
              photosStore.setBottomLink(name, url);
              navigate(-1);
            }}
            disabled={
              !name.length || !url.length || !isURLValid || !isTagNameValid
            }
          >
            完了
          </button>
        }
      />
      <div className={styles.container}>
        <div className="d-flex">
          <div className={styles.info}>商品情報</div>
        </div>
        <TextInputField
          className={styles.input}
          label="タグ名"
          placeholder="タグ名を入力してください"
          value={name}
          onChange={(text) => setName(text)}
        />
        {!isTagNameValid && (
          <p className={styles.error}>タグ名は15文字以内で入力してください。</p>
        )}
        <TextInputField
          className={styles.input}
          label="商品URL"
          placeholder="商品ページのURLを入力してください"
          value={url}
          onChange={(text) => setUrl(text)}
        />
        {!isURLValid && <p className={styles.error}>無効なURLです。</p>}
        <div className={styles.note}>
          ここで入力した商品情報は投稿写真の下部に固定リンクとして設置されます
        </div>
      </div>
    </Layout>
  );
});

export default AddBottomLink;
