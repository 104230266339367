import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';

import { TComment } from 'types/post';
import ReplyItem from '../reply-item';
import { getCommentList } from 'services/post';
import images from 'assets/images';
import { getNextPageParam, transformData } from 'utils/pagination';

import styles from './styles.module.scss';
import UIStore from 'stores/ui';
import useStores from 'hooks/useStores';
import { dateConverter } from 'utils/time';
import SessionStore from 'stores/session';

interface IProps {
  comment: TComment;
  postId: number;
  onReplyHandler: (username: string, commentParentId: number) => void;
  needUpdated: number;
  setNeedUpdated: (arg: number) => void;
  onAvatarOrUserNameClickHandler: (event: any, userId: number) => void;
  onDeleteCommentHandler: (commentId: number, commentParentId?: number) => void;
  currentUserNickName: string;
}

const CommentItem = ({
  comment,
  postId,
  needUpdated,
  onReplyHandler,
  setNeedUpdated,
  onAvatarOrUserNameClickHandler,
  onDeleteCommentHandler,
  currentUserNickName,
}: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const [showReplies, setShowReplies] = useState<boolean>(false);
  const [openBefore, setOpenBefore] = useState<boolean>(false);
  const commentContent = comment.content.split(/(\s+)/);
  let isHolding = false;
  let timeout: any;

  const replyQuery = useInfiniteQuery<Array<TComment>>(
    ['comment-replies', postId, comment.id],
    ({ pageParam = 1 }) => getCommentList(pageParam, postId, comment.id),
    {
      enabled: false,
      select(data) {
        return transformData(data, 'comments');
      },
      getNextPageParam,
    }
  );

  useEffect(() => {
    if (replyQuery.hasNextPage) {
      replyQuery.fetchNextPage();
    }
  }, [replyQuery.hasNextPage]);

  const renderReplies = () => {
    return (
      <div>
        {replyQuery.data?.pages
          .flat()
          .map((reply: TComment, _index: number) => (
            <ReplyItem
              key={reply.id}
              reply={reply}
              commentId={comment.id}
              onReplyHandler={() =>
                onReplyHandler(comment.user.nick_name, comment.id)
              }
              onAvatarOrUserNameClickHandler={onAvatarOrUserNameClickHandler}
              onDeleteCommentHandler={onDeleteCommentHandler}
              currentUserNickName={currentUserNickName}
            />
          ))}
      </div>
    );
  };

  useEffect(() => {
    if (showReplies && openBefore === false) {
      replyQuery.refetch();
      setOpenBefore(true);
    }
  }, [showReplies]);

  useEffect(() => {
    if (needUpdated === comment.id) {
      replyQuery.refetch();
      setNeedUpdated(0);
    }
  }, [needUpdated]);

  const getIdByUsername = (section: string) => {
    const username = section.slice(1);
    const user = comment.tag_users.find((user) => user.nick_name === username);
    return user ? user.user_id : null;
  };

  const onTouchStartHandler = (_event: any) => {
    isHolding = true;
    timeout = setTimeout(() => {
      if (isHolding && comment.can_remove) {
        uiStore.showAlertBox({
          title: 'このコメントを削除しますか？',
          buttons: [
            {
              title: '削除', // delete
              isRed: true,
              onPress: () => onDeleteCommentHandler(comment.id),
            },
            {
              title: 'キャンセル',
            },
          ],
        });
      }
    }, 1000);
  };

  const onTouchEndHandler = (_event: any) => {
    isHolding = false;
    clearTimeout(timeout);
  };

  return (
    <div
      className={styles.container}
      onTouchStart={onTouchStartHandler}
      onTouchEnd={onTouchEndHandler}
    >
      <img
        className={styles.avatar}
        src={comment.user.avatar?.small || images.imgDefaultAvatar}
        alt=""
        loading="lazy"
        onClick={() => navigate(`/${comment.user.id}`)}
      />
      <div className={styles.content}>
        <div
          className={styles.userName}
          onClick={() => navigate(`/${comment.user.id}`)}
        >
          {comment.user.nick_name}
        </div>
        <div className={styles.comment}>
          {commentContent.map((section: string, index) => {
            const userId = getIdByUsername(section);
            return (
              <React.Fragment key={index}>
                {/^@[a-zA-Z0-9_]+$/.test(section) ? (
                  <span
                    className={styles.tag}
                    onClick={() => userId && navigate(`/${userId}`)}
                  >
                    {section}
                  </span>
                ) : (
                  <span>{section}</span>
                )}
              </React.Fragment>
            );
          })}
        </div>

        <div className={styles.metaData}>
          <span aria-label="date">{dateConverter(comment.created_at)}</span>
          {sessionStore.profile && (
            <span
              aria-label="reply"
              onClick={() => onReplyHandler(comment.user.nick_name, comment.id)}
            >
              返信する
            </span>
          )}
          {comment.total_sub_comment > 0 && (
            <div className={styles.showReply}>
              <div className={styles.verticalLine}></div>
              <div
                onClick={() => setShowReplies(!showReplies)}
                aria-label="reply-toggle"
              >
                {showReplies
                  ? 'コメントを非表示にする'
                  : `${comment.total_sub_comment}件の返信を見る`}
              </div>
            </div>
          )}
        </div>

        {showReplies && renderReplies()}
      </div>
    </div>
  );
};

export default CommentItem;
